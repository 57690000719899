import React from 'react';
import styles from './NotFound.module.scss';
import logo from 'assets/logo.png';
import { Button, Image } from 'antd';
import Logo from 'app/components/Logo';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return <div className={styles.wrapper}>
        <div className={styles.content}>
            <Logo size={100} />
            <h1>Page Not Found</h1>
            <h3>The page you are looking for does not exist.</h3>
            <Link to='/' className={styles.back_btn}>Go to Home</Link>
        </div>
    </div>
}

export default NotFound;