
import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";
import { PagingResponse } from "../../base/Paging";
import { VoiceWord } from "../../model/VoiceWord/VoiceWord";
import { VoiceWordListRequest } from "./Model/ClientListRequest";
import { VoiceWordAddResponse } from "./Model/VoiceWordAddResponse";

export default class AdminVoiceWordsApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/voice_words";

	async list(req: VoiceWordListRequest) {
		const request = await this.api.client.post<PagingResponse<VoiceWord>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<VoiceWord>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async create(words: VoiceWord[]) {
		if (!words) return null;
		const request = await this.api.client.post<NiceResponse<VoiceWordAddResponse>>(`${this.baseUrl}/create`, { words });
		return request.data;
	}

	async delete(x: VoiceWord) {
		const request = await this.api.client.delete<NiceResponse<VoiceWord>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

}