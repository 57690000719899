import { NiceResponse } from "api/base/NiceResponse";
import ApiClient from "../../../base/ApiClient";
import { PagingResponse } from "../../../base/Paging";
import { AppointmentFeedbackReviewTier } from "../../../model/Appointments/enums/AppointmentFeedbackReviewTier";
import { AppointmentFeedbackStatus } from "../../../model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentFeedbackAudio } from "../../../model/Audio/AppointmentFeedbackAudio";
import { AdminFeedbackDto } from "./DTO/AdminFeedbackDto";
import { AdminFeedbackHistoryDto } from "./DTO/AdminFeedbackHistoryDto";
import { AdminFeedbackMsgDto } from "./DTO/AdminFeedbackMsgDto";
import { AdminAppointmentFeedbackHistoryListRequest } from "./Model/AdminAppointmentFeedbackHistoryListRequest";
import { AdminAppointmentFeedbackListRequest } from "./Model/AdminAppointmentFeedbackListRequest";
import { LanguageEnum } from "api/model/User/Language";
import { OutputType } from "api/model/Appointments/AppointmentFeedback";

export default class AdminAppointmentFeedbackApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/feedback";

	async list(req: AdminAppointmentFeedbackListRequest) {
		const request = await this.api.client.post<PagingResponse<AdminFeedbackDto>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<NiceResponse<AdminFeedbackDto>>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.delete<AdminFeedbackDto>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async markupFeedback(fb: AdminFeedbackDto) {
		if (!fb.id) return null;
		const { appointment, ...rest } = fb;
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/markup`, { ...rest });
		return request.data;
	}

	async updateStatus(fb: AdminFeedbackDto, status: AppointmentFeedbackStatus) {
		if (!fb.id) return null;
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/update-status/${fb.id}`, { status: status });
		return request.data;
	}

	async sendToTier(fb: AdminFeedbackDto, tier: AppointmentFeedbackReviewTier) {
		if (!fb.id) return null;
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/review-tier/${fb.id}`, { tier: tier });
		return request.data;
	}

	async isEditing(fb: AdminFeedbackDto, isEditing: boolean) {
		if (!fb.id) return null;
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/isEditing/${fb.id}`, { isEditing: isEditing });
		return request.data;
	}

	async addedToPims(id: number, added: boolean) {
		if (!id) return null;
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/addedToPims/${id}`, { addedToPims: added });
		return request.data;
	}

	async history(fbId: number, req: AdminAppointmentFeedbackHistoryListRequest) {
		const request = await this.api.client.post<PagingResponse<AdminFeedbackHistoryDto>>(`${this.baseUrl}/history/${fbId}`, { ...req });
		return request.data;
	}

	//Audio
	async getAudioUrl(audio: AppointmentFeedbackAudio) {
		if (!audio.id) return null;
		const request = await this.api.client.get<string>(`${this.baseUrl}/audio-url/${audio.id}`);
		return request.data;
	}

	async audioRetranscribe(feedbackId: number, audioId: number) {
		if (!audioId) return null;
		const request = await this.api.client.post<string>(`${this.baseUrl}/audio-retranscribe/${feedbackId}`, { audioId });
		return request.data;
	}

	//message
	async sendMessage(text: string, feedbackId: number) {
		const request = await this.api.client.post<AdminFeedbackMsgDto>(`${this.baseUrl}/send-message`, { text, feedbackId });
		return request.data;
	}

	async getMessages(feedbackId: number) {
		const request = await this.api.client.get<AdminFeedbackMsgDto[]>(`${this.baseUrl}/messages/${feedbackId}`);
		return request.data;
	}

	async removeMessage(messageId: number) {
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/message-remove/${messageId}`);
		return request.data;
	}

	async closeChat(feedbackId: number) {
		const request = await this.api.client.post<boolean>(`${this.baseUrl}/close-chat/${feedbackId}`);
		return request.data;
	}


	async reProcess(feedbackId: number) {
		const request = await this.api.client.post<boolean>(`${this.baseUrl}/re-process-ambient/${feedbackId}`);
		return request.data;
	}

	async changeLanguage(id: number, language: LanguageEnum) {
		const request = await this.api.client.post<boolean>(`${this.baseUrl}/change-language/${id}`, { language });
		return request.data;
	}

	async useForTraining(id: number, useForTraining: boolean) {
		const request = await this.api.client.post<AdminFeedbackDto>(`${this.baseUrl}/use-for-training/${id}`, { useForTraining });
		return request.data;
	}

	async generateOutput(id: number, outputType: OutputType) {
		const request = await this.api.client.post<string>(`${this.baseUrl}/generate-output/${id}`, { outputType });
		return request.data;
	}

	async updateOutput(id: number, outputType: OutputType, content: string) {
		const request = await this.api.client.post<string>(`${this.baseUrl}/update-output/${id}`, { outputType, content });
		return request.data;
	}

	async sendClientEmail(id: number, emailAddress: string, subject?: string) {
		const request = await this.api.client.post<string>(`${this.baseUrl}/send-client-email/${id}`, { emailAddress, subject });
		return request.data;
	}
}