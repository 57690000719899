import { AppointmentFeedbackNote } from "../../../model/Appointments/AppointmentFeedbackNote";
import { AppointmentFeedbackStatus } from "../../../model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentFeedbackAudio } from "../../../model/Audio/AppointmentFeedbackAudio";
import { Template } from "../../../model/Template/Template";
import { AppointmentClientDto } from "./AppointmentClientDto";

export enum FeedbackSource {
	Mobile = 0,
	Desktop = 1
}
export interface AppointmentFeedbackClientDto {
	id: number,
	createdDate: Date,
	status: AppointmentFeedbackStatus | null;
	appointmentId: number;
	appointment: AppointmentClientDto;
	isSoap: boolean;
	soapSCC: string | null;
	soapSHPI: string | null;
	soapSMedications: string | null;
	soapSHistory: string | null;
	soapOVitalSigns: string | null;
	soapOGeneralObservations: string | null;
	soapOPhysicalExaminations: string | null;
	soapODiagnostic: string | null;
	soapADiagnosis: string | null;
	soapPDiagnostic: string | null;
	soapPTreatment: string | null;
	soapPEducation: string | null;
	soapPConsultation: string | null;
	soapPFollowUp: string | null;
	notes: AppointmentFeedbackNote[];
	editorId: number | null;
	editorName: string | null;
	rating: number | null;
	isEditing: boolean;
	appointmentFeedbackAudio?: AppointmentFeedbackAudio[],
	chatOpen: boolean;
	chatDoctorAttention: boolean;
	chatAgentAttention: boolean;
	addedToPims: boolean;
	ambientMode?: boolean;
	source?: FeedbackSource;

	//templates
	templateId?: number | null;
	template?: Template | null;
}