
export enum AppointmentFeedbackStatus {
	Draft = "Draft",
	Markup = "Markup",
	EditingInTheApp = "EditingInTheApp",
	MarkupDone = "MarkupDone",
	Completed = "Completed",
	ReadyToProcess = "ReadyToProcess",
	Processing = "Processing",
	All = "All",
	PrepocessingDone = "PrepocessingDone",
}

const AppointmentFeedbackStatusLabels = [
	{ value: AppointmentFeedbackStatus.Draft, label: "Draft", color: "#707070" },
	{ value: AppointmentFeedbackStatus.Markup, label: "Submitted", color: "#C28D4B" },
	{ value: AppointmentFeedbackStatus.MarkupDone, label: "Completed", color: "#C28D4B" },
	{ value: AppointmentFeedbackStatus.Completed, label: "Approved", color: "#4caf50" },
	{ value: AppointmentFeedbackStatus.Processing, label: "Processing", color: "#0958d9" },
	{ value: AppointmentFeedbackStatus.ReadyToProcess, label: "Ready to process", color: "#C28D4B" },
	{ value: AppointmentFeedbackStatus.EditingInTheApp, label: "Editing by you", color: "#814341" },
	{ value: AppointmentFeedbackStatus.PrepocessingDone, label: "Preprocessing done", color: "#707070" },
]

export const getAppointmentFeedbackStatusLabel = (status: AppointmentFeedbackStatus | null): { label: string, color: string } => {
	const val = AppointmentFeedbackStatusLabels.find(x => x.value === status) || {
		color: "#C28D4B",
		label: status || "",
	};
	return { label: val.label, color: val.color };
}


