import { Divider, Select } from "antd";
import useApi from "misc/hooks/useApi";
import React, { useEffect, useState } from "react"

const OfficeSelect: React.FC<{ value?: number, onChange: (x: any) => void }> = ({ value, onChange }) => {
	const api = useApi();
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		load()
	}, [search])

	const load = async () => {
		var res = await api.adminOffice.list({
			page: 1,
			pageSize: 20,
			search: search,
			sortField: "Name",
			sortDirection: "asc",
			isRemoved: false
		})
		setItems(res.data);
	}


	return <Select style={{ minWidth: 150 }} value={value} onChange={(x) => onChange(x)} onSearch={setSearch} allowClear showSearch placeholder="Office" filterOption={false}>
		{items.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
	</Select >
}
export default OfficeSelect;