import { UserAuthResponse } from "./DTO/UserAuthReponse";
import { User } from "../../model/User/User";
import UserAuthRequest from "./DTO/UserAuthRequest";
import { UserRegisterRequest } from "./DTO/UserRegisterRequest";
import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";

export default class UserApi {
	constructor(private api: ApiClient) { }


	async logout() {
		const reg = await this.api.client.get<NiceResponse<any>>("/auth/logout");
		return reg.data;
	}

	async refresh(token: string) {
		const reg = await this.api.client.post<string>("/auth/refresh", { refreshToken: token });
		return reg.data;
	}

	async signUpWithEmailAndPassword(req: UserRegisterRequest) {
		const reg = await this.api.client.post<UserAuthResponse>("/auth/register", { ...req });
		return reg.data;
	}

	async signInWithEmailAndPassword(req: UserAuthRequest) {
		const login = await this.api.client.post<UserAuthResponse>("/auth/login", { email: req.email, password: req.password });
		return login.data;
	}

	async restorePassword(email: string) {
		const restore = await this.api.client.post<NiceResponse<any>>("/auth/forgot", { email: email });
		return restore.data;
	}

	async restorePasswordCode(email: string, code: string) {
		const restore = await this.api.client.post<UserAuthResponse>("/auth/forgotCode", { email: email, code: code });
		return restore.data;
	}

	async newPassword(password: string, passwordConfirm: string) {
		const restore = await this.api.client.post<NiceResponse<any>>("/auth/newPassword", { password: password, passwordConfirm: passwordConfirm });
		return restore.data;
	}

	//
	async getCurrentUser() {
		const req = await this.api.client.get<User>("/profile/me");
		if (req.status === 204) {
			return null;
		}
		return req.data;
	}

	async acceptTerms() {
		const req = await this.api.client.get<boolean>("/profile/acceptTerms");
		return req.data;
	}

}