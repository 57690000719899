import React, { useContext, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { AppointmentFeedbackNote } from 'api/vs-recording/model/Appointments/AppointmentFeedbackNote';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import OneNote from './components/OneNote/OneNote';

const { Title } = Typography;

const SubmitFeedbackNotes: React.FC<{ feedback: AppointmentFeedbackClientDto }> = observer(({ feedback }) => {
    const appointmentContext = useContext(AppointmentStoreContext);

    const addNewNote = async () => {
        await appointmentContext!.addNewNote();
    }

    const saveNote = async (note: AppointmentFeedbackNote) => {
        if (!appointmentContext) return;
        await appointmentContext.saveNote(note)
    }

    const onDeleteAudio = async (audio: AppointmentFeedbackAudio, note: AppointmentFeedbackNote) => {
        await appointmentContext!.deleteAudioFromNote(audio, note)
    }

    const noteList = (notes: AppointmentFeedbackNote[], removable: boolean) => {
        return <div>
            {notes.map((note, index) => {
                const defaultName = "Note #" + (index + 1);
                const noteName = note.name && note.name.length > 0 ? note.name : note.templateFieldId ? null : defaultName;

                return <OneNote
                    currentNote={note}
                    type={note.type}
                    removable={removable}
                    name={noteName}
                    key={note.id + "|" + index + "|" + note.createdDate}
                    value={note.text || ""}
                    onChange={(t) => runInAction(() => {
                        note.needToSave = true;
                        note.text = t;
                    })}
                    onDelete={() => appointmentContext!.deleteNote(note)}
                    saveNote={() => saveNote(note)}
                    loading={appointmentContext!.savingNote}
                    audios={note.appointmentFeedbackAudio || []}
                    onDeleteAudio={(audio: AppointmentFeedbackAudio) => onDeleteAudio(audio, note)}
                />
            })}
        </div>;
    }

    const templateFieldNotes = useMemo(() =>
        feedback.notes
            .filter(x => x.templateFieldId != null)
            .sort((a, b) => (a.position || 0) - (b.position || 0))
        , [feedback.notes]);

    const customNotes = useMemo(() => feedback.notes
        .filter(x => x.templateFieldId == null && x.isAmbient == false)
        .sort((a, b) => a.createdDate == null || b.createdDate == null ? 1 :
            new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? 1 : -1), [feedback.notes]);


    return <div className='flex-1 mt-20'>
        {noteList(templateFieldNotes, false)}
        <div>
            <Title level={4} className='color-white'>CUSTOM NOTES</Title>
        </div>
        {noteList(customNotes, true)}
        <Button type="dashed" onClick={addNewNote} icon={<PlusOutlined />}>
            Add Note
        </Button>
    </div>;
});

export default SubmitFeedbackNotes;
