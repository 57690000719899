import React, { useState } from 'react';
import { Select } from 'antd';
import { isValidEmail } from 'misc/helpers/isValidEmail';

type EmailSelectProps = {
    value?: string[];
    onChange?: (value: string[]) => void;
};

const EmailToSelect: React.FC<EmailSelectProps> = ({ value = [], onChange }) => {
    const [emailOptions, setEmailOptions] = useState<string[]>(value);
    const [inputValue, setInputValue] = useState<string>('');

    const handleAddEmail = (input: string) => {
        const trimmedValue = input.trim();
        if (isValidEmail(trimmedValue)) {
            const updatedEmails = Array.from(new Set([...emailOptions, trimmedValue]));
            setEmailOptions(updatedEmails);
            if (onChange) {
                onChange(updatedEmails);
            }
        }
        setInputValue('');
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            handleAddEmail(inputValue);
        }
    };

    const handleBlur = () => {
        handleAddEmail(inputValue);
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    return <Select
        mode="tags"
        placeholder="Enter emails separated by commas"
        value={emailOptions}
        onChange={(selectedEmails) => {
            const validEmails = selectedEmails.filter((email) => isValidEmail(email.trim()));
            setEmailOptions(validEmails);
            if (onChange) {
                onChange(validEmails);
            }
        }}
        onInputKeyDown={handleInputKeyDown}
        onBlur={handleBlur}
        onSearch={handleInputChange}
        tokenSeparators={[',']}
        open={false}
        searchValue={inputValue}>
        {emailOptions.map((email) => (
            <Select.Option key={email} value={email}>
                {email}
            </Select.Option>
        ))}
    </Select>;
};

export default EmailToSelect;
