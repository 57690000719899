export enum Env {
	dev = 'Development',
	prod = 'Production'
}

export const env: Env = process.env.NODE_ENV == 'development' ? Env.dev : Env.prod;


export const AppSettings = {
	auth: {
		access: {
			name: process.env['AUTH_ACCESS_NAME'],
			durationDays: parseFloat(process.env['AUTH_ACCESS_DURATION_DAYS'])
		},
		refresh: {
			name: process.env['AUTH_REFRESH_NAME'],
			durationDays: parseFloat(process.env['AUTH_REFRESH_DURATION_DAYS'])
		}
	},
	api: {
		url: process.env['API_URL']
	},
	hub: {
		url: process.env['HUB_URL']
	},
	livechat: {
		license: process.env['LIVECHAT_LICENSE'], 
	}
}

