import { FeedbackOutputTabs } from "app/components/FeedbackOutputView/FeedbackOutput";
import { AppointmentFeedbackAudio } from "../Audio/AppointmentFeedbackAudio";
import { BasicEntity } from "../BasicEntity";
import { Appointment } from "./Appointment";
import { AppointmentFeedbackNote } from "./AppointmentFeedbackNote";
import { AppointmentFeedbackReviewTier } from "./enums/AppointmentFeedbackReviewTier";
import { AppointmentFeedbackStatus } from "./enums/AppointmentFeedbackStatus";

export interface AppointmentFeedback extends BasicEntity {
	duration: number | null;
	reason: string | null;
	type: string | null;
	status: AppointmentFeedbackStatus | null;
	appointmentId: number | null;
	appointment: Appointment | null;
	isSoap: boolean;
	soapSCC: string | null;
	soapSHPI: string | null;
	soapSMedications: string | null;
	soapSHistory: string | null;
	soapOVitalSigns: string | null;
	soapOGeneralObservations: string | null;
	soapOPhysicalExaminations: string | null;
	soapODiagnostic: string | null;
	soapADiagnosis: string | null;
	soapPDiagnostic: string | null;
	soapPTreatment: string | null;
	soapPEducation: string | null;
	soapPConsultation: string | null;
	soapPFollowUp: string | null;
	notes: AppointmentFeedbackNote[];
	appointmentFeedbackAudio?: AppointmentFeedbackAudio[],
	reviewStatus?: AppointmentFeedbackReviewTier,
	chatOpen: boolean;
	chatDoctorAttention: boolean;
	chatAgentAttention: boolean;
	chatLastMessageDate?: Date;
	feedbackOutput?: FeedbackOutputTabs;
	isClientEmailSent: boolean | null;
}

export enum OutputType {
	VisitSummary = 0,
	DischargeNote = 1,
	ClientEmail = 2,
}