import axios, { AxiosInstance } from "axios";
import UserProfileApi from "../routes/UserProfile/UserProfileApi";
import AdminAppointmentApi from "../routes/Appointments/Appointment/AdminAppointmentApi";
import AdminAppointmentFeedbackApi from "../routes/Appointments/Feedback/AdminFeedbackApi";
import AdminFeedbackCommentApi from "../routes/Appointments/Feedback/AdminFeedbackCommentApi";
import AdminBillingApi from "../routes/Billing/BillingApi";
import AdminClientApi from "../routes/Clients/Client/ClientApi";
import AdminPatientApi from "../routes/Clients/Patient/PatientApi";
import AdminDoctorApi from "../routes/Doctors/DoctorApi";
import AdminOfficeApi from "../routes/Offices/Office/OfficeApi";
import AdminPracticeApi from "../routes/Practice/PracticeApi";
import UserApi from "../routes/User/User";
import AdminUserApi from "../routes/UserAdmin/UserAdmin";
import UserNotificationApi from "../routes/UserNotifications/UserProfileApi";
import AdminVoiceWordsApi from "../routes/VoiceWords/VoiceWordStoreApi";
import AdminTemplateApi from "api/routes/Templates/TemplateApi";
import AdminAccountApi from "api/routes/Accounts/AccountApi";
import { TemplatesApi } from "api/vs-recording/routes/Templates/TemplatesApi";
import { AppointmentApi } from "api/vs-recording/routes/Appointment/AppointmentApi";
import AdminAnalyticsApi from "api/routes/Analytics/AnalyticsApi";



export type ApiClientProps = {
	baseUrl: string;
	auth: {
		access: {
			name: string,
			durationDays: number,
		},
		refresh: {
			name: string,
			durationDays: number,
		}
	},
	setCookies: (key: string, value: string, params: any) => Promise<void>,
	getCookies: (key: string) => Promise<string | undefined | null>,
	deleteCookies: (key: string) => Promise<void>
}

export default class ApiClient {
	client: AxiosInstance;
	baseUrl: string;
	private props: ApiClientProps;
	currentAccountId: number | null = null;

	constructor(props: ApiClientProps) {
		this.props = props;
		this.baseUrl = this.props.baseUrl;

		this.client = axios.create({
			baseURL: props.baseUrl,
		});

		this.setInterceptors();
	}

	async getAccessToken(): Promise<string | undefined | null> {
		return this.props.getCookies(this.props.auth.access.name) || null;
	}

	async setAccessToken(v: string | null) {
		if (v && v.length > 0) {
			await this.props.setCookies(this.props.auth.access.name, v, { expires: this.props.auth.access.durationDays, httpOnly: false });
		} else {
			await this.props.deleteCookies(this.props.auth.access.name);
		}
	}


	async getRefreshToken(): Promise<string | undefined | null> {
		return this.props.getCookies(this.props.auth.refresh.name) || null;
	}

	async setRefreshToken(v: string | null) {
		if (v && v.length > 0) {
			await this.props.setCookies(this.props.auth.refresh.name, v, { expires: this.props.auth.refresh.durationDays, httpOnly: false });
		} else {
			await this.props.deleteCookies(this.props.auth.refresh.name);
		}
	}

	public async isAuthorized() {
		return await this.getAccessToken() != null && await this.getRefreshToken() != null;
	}

	public async Unauthorize() {
		await this.setAccessToken(null);
		await this.setRefreshToken(null);
	}


	private async setInterceptors() {
		this.client.interceptors.request.use(
			async config => {
				config.headers["Accept"] = 'application/json';
				config.headers["Admin"] = 'true';

				var accessToken = await this.getAccessToken();
				var refreshToken = await this.getRefreshToken();

				if (accessToken)
					config.headers["Authorization"] = `Bearer ${accessToken}`;

				//we dont have access BUT we have refresh token
				if (!accessToken && refreshToken) {
					const result = await this.refreshAccessTokenAndSave();
					if (result) {
						config.headers["Authorization"] = `Bearer ${result}`;
						return config;
					}
				}

				if (this.currentAccountId && !config.url?.startsWith("/auth"))
					config.headers["account-id"] = this.currentAccountId;

				return config;
			}, error => {
				Promise.reject(error)
			});

		this.client.interceptors.response.use(
			(response) => response,
			async (error) => {
				const originalRequest = error.config;
				var refreshToken = await this.getRefreshToken();
				if (error?.response?.status === 401 && refreshToken && !originalRequest._retry) {
					const result = await this.refreshAccessTokenAndSave();
					originalRequest._retry = true;
					if (result) {
						return this.client(originalRequest);
					}
				}
				return Promise.reject({ success: false, error: error?.response?.data?.error, data: error, code: error?.response?.status });
			});
	}

	public async refreshAccessTokenAndSave(): Promise<string | null> {
		var refreshToken = await this.getRefreshToken();
		if (refreshToken) {
			try {
				const req = await axios.post<string>(`${this.props.baseUrl}/auth/refresh`, { refreshToken: refreshToken });
				//this cookies won't be avaiable till the next request
				await this.setAccessToken(req.data);
				return req.data || "";
			} catch (e) {
				await this.setAccessToken(null);
				await this.setRefreshToken(null);
				return null;
			}
		} else {
			return null;
		}
	}

	user: UserApi = new UserApi(this)
	userProfile: UserProfileApi = new UserProfileApi(this)
	notificationApi: UserNotificationApi = new UserNotificationApi(this);

	//admin
	adminUser: AdminUserApi = new AdminUserApi(this)
	adminClient: AdminClientApi = new AdminClientApi(this);
	adminDoctor: AdminDoctorApi = new AdminDoctorApi(this);
	adminAppointment: AdminAppointmentApi = new AdminAppointmentApi(this);
	adminFeedback: AdminAppointmentFeedbackApi = new AdminAppointmentFeedbackApi(this);
	adminFeedbackComment: AdminFeedbackCommentApi = new AdminFeedbackCommentApi(this);
	adminPatient: AdminPatientApi = new AdminPatientApi(this);
	adminOffice: AdminOfficeApi = new AdminOfficeApi(this);
	adminPractice: AdminPracticeApi = new AdminPracticeApi(this);
	adminVoiceWords: AdminVoiceWordsApi = new AdminVoiceWordsApi(this);
	adminBillingApi: AdminBillingApi = new AdminBillingApi(this);
	adminTemplates: AdminTemplateApi = new AdminTemplateApi(this);
	adminAccounts: AdminAccountApi = new AdminAccountApi(this);
	adminAnalytics: AdminAnalyticsApi = new AdminAnalyticsApi(this);

	//recording app
	vsRecording = {
		appointment: new AppointmentApi(this),
		template: new TemplatesApi(this)
	}
}
