import { AppointmentFeedbackAudio, AppointmentFeedbackAudioStatus, AppointmentFeedbackAudioStatusList } from "api/model/Audio/AppointmentFeedbackAudio";
import { Button, Drawer, notification, Popconfirm, Popover, Tag } from "antd";
import useApi from "misc/hooks/useApi";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { RedoOutlined } from '@ant-design/icons';
import useCurrentUser from "misc/hooks/useCurrentUser";

const FeedbackAudioPlayer: React.FC<{ audio: AppointmentFeedbackAudio, feedbackId: number }> = observer(({ audio, feedbackId }) => {
	const api = useApi();
	const [audioUrl, setAudioUrl] = useState<string>();
	const user = useCurrentUser();
	const [textIsShown, setTextIsShown] = useState(false);

	useEffect(() => {
		api.adminFeedback.getAudioUrl(audio).then(setAudioUrl);
	}, [])

	const toggleTextIsShown = () => {
		setTextIsShown(!textIsShown);
	}

	const retranscribe = async () => {
		notification.info({ message: "Retranscribing audio... refresh the page later." });
		try {
			await api.adminFeedback.audioRetranscribe(feedbackId, audio.id);
		} catch (e) {
			notification.error({ message: "Audio is already being processed. Please wait" });
		}
	}

	const status = useMemo(() => {
		return AppointmentFeedbackAudioStatusList.find(x => x.value == audio.status);
	}, [audio.status])


	if (!audioUrl) return null;

	return <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginTop: 10, gap: 5 }}>

		<div style={{ flexBasis: "100%", opacity: 0.7, fontWeight: "bold", marginBottom: 5 }}>
			Audio {moment(audio.createdDate).format("DD MMM YYYY HH:mm")} <Tag color={status.color}>{status?.label}</Tag>
		</div>

		<audio controls controlsList="nodownload" style={{ flex: 1, width: "100%" }} >
			<source src={audioUrl} type="audio/mp4" />
		</audio>

		{audio.text &&
			<>
				<Button type="primary" onClick={toggleTextIsShown}> Show Text</Button>
				<Drawer onClose={toggleTextIsShown}
					maskClosable={true}
					destroyOnClose={true}
					open={textIsShown}
					title={`Recording info`} width={750} style={{ maxWidth: '100%' }}>
					<audio controls controlsList="nodownload" style={{ flex: 1, width: "100%" }} >
						<source src={audioUrl} type="audio/mp4" />
					</audio>
					<h2>Exact Text</h2>
					<div style={{ whiteSpace: "pre-wrap" }}>{audio.text}</div>

					{audio.groomedText &&
						<>
							<h2>Corrected Text</h2>
							<div style={{ whiteSpace: "pre-wrap" }}>{audio.groomedText}</div>
						</>
					}
				</Drawer>
			</>
		}
		<Popconfirm onConfirm={retranscribe} title="Audio will be transcribed again and text will be added. Are you sure?" >
			<Button style={{ marginLeft: 5 }} type="primary" icon={<RedoOutlined />} />
		</Popconfirm>

	</div>
})

export default FeedbackAudioPlayer;


