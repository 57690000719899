import React, { } from "react";
import html from "./terms.html";

const Terms: React.FC = () => {
	return <div className='terms-wrapper'>
		<div dangerouslySetInnerHTML={{ __html: html }}></div>
	</div>
}

export default Terms;
