import { Divider, Select } from "antd";
import useApi from "misc/hooks/useApi";
import { set } from "mobx";
import React, { useEffect, useState } from "react"

const DoctorMultiSelect: React.FC<{ value?: number[], practiceId?: number, onChange: (x: any) => void, accountIds?: number[] }> =
	({ value, onChange, practiceId, accountIds }) => {
		const api = useApi();
		const [items, setItems] = useState([]);
		const [search, setSearch] = useState("");
		const [initialLoaded, setInitialLoaded] = useState(false);
		const [loading, setLoading] = useState(false);

		useEffect(() => {
			load()
		}, [search])

		const load = async () => {
			setLoading(true);
			var res = await api.adminDoctor.list({
				page: 1,
				pageSize: 20,
				search: search,
				isRemoved: false,
				sortField: "FirstName",
				sortDirection: "desc",
				practiceId: practiceId || null,
				accountIds: accountIds,
				includeIds: !initialLoaded ? value : []
			})
			setInitialLoaded(true);
			setItems(res.data);
			setLoading(false);
		}

		const onOpen = (open: boolean) => {
			if (!open) return;
			load();
		}

		return <Select loading={loading} onDropdownVisibleChange={onOpen} mode="multiple" placeholder="Doctor" style={{ width: "100%" }} allowClear value={value} onChange={(x) => onChange(x)} onSearch={setSearch} showSearch filterOption={false}>
			{items.map(x => <Select.Option key={x.id} value={x.id}>{x.lastName} {x.firstName}</Select.Option>)}
		</Select>
	}
export default DoctorMultiSelect;