import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Modal, Popconfirm, Typography } from 'antd';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { TemplateFieldType } from 'api/vs-recording/model/Template/TemplateField';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import AudioPlayer from '../../../AudioPlayer/AudioPlayer';
import AudioRecorder from '../../../AudioRecorder/AudioRecorder';
import styles from '../../../../Recording.module.scss';
import { AppointmentFeedbackNote } from 'api/vs-recording/model/Appointments/AppointmentFeedbackNote';

const { TextArea } = Input;
const { Title } = Typography;

const OneNote: React.FC<{
    currentNote: AppointmentFeedbackNote,
    type?: TemplateFieldType,
    // onRecordedAudio?: (uri: string, duration: number) => void,
    onDeleteAudio: (audio: AppointmentFeedbackAudio) => void,
    // onUpdateRecording?: (uri: string, duration: number) => void,
    onChange: (t: string) => void,
    removable: boolean,
    value: string,
    onDelete: () => void,
    loading?: boolean,
    saveNote: () => void,
    audios: AppointmentFeedbackAudio[],
    name: string | null
}> = observer(({ currentNote, removable, onChange, value, onDelete, saveNote, loading, audios, onDeleteAudio, name, type }) => {
    const inputRef = useRef<InputRef>(null)
    const [isOpen, setIsOpen] = useState(false);
    const appointmentContext = useContext(AppointmentStoreContext);
    const recorderRef = useRef<any>(null);

    const toggleOpen = async () => {
        setIsOpen(previousState => !previousState);
    };

    const modalInnerDisabled = useMemo(() => {
        return appointmentContext?.isRecording;
    }, []);

    const audiosScrollRef = useRef<HTMLDivElement>(null);

    const onRecordedAudio = async (uri: string, duration: number) => {
        await appointmentContext!.addOrUpdateAudioToNote(uri, duration, currentNote);
    }

    const onUpdateRecording = async (uri: string, duration: number) => {
        await appointmentContext!.addOrUpdateAudioToNote(uri, duration, currentNote, true);
    }

    const deleteNote = () => {
        toggleOpen();
        onDelete();
    };


    const save = async () => {
        await recorderRef.current?.stopRecording();
        saveNote();
        toggleOpen();
    };

    const title = (modal?: boolean) => {
        if (name == null) return null;
        return <Title level={5} style={{ color: modal ? '#000' : '#fff', margin: 0 }}>{name}</Title>
    };

    if (type == TemplateFieldType.Separator) {
        return <div>
            <Title level={4} className='color-white'>{name}</Title>
        </div>;
    };

    return <div>
        <div onClick={toggleOpen} className={styles.oneNote}>
            {title(true)}
            {value.length === 0
                ? <Typography.Text style={{ color: "#888", fontSize: 14 }}>Press to record notes</Typography.Text>
                : <Typography.Text style={{ color: "#000", fontSize: 14 }}>{value}</Typography.Text>
            }
            <div className='mt-10 flex flex-col gap-5'>
                {audios.map((x, i) => (
                    <div key={`${x.uri} | ${i}`}>
                        <AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} showDelete={true} />
                    </div>
                ))}
            </div>
        </div>

        <Modal open={isOpen} onCancel={toggleOpen}
            footer={null} width={700}
            title={title(true)}
            style={{ borderRadius: 25, paddingBottom: 0 }}>
            <div>
                <div ref={audiosScrollRef}>
                    <TextArea
                        ref={inputRef}
                        disabled={modalInnerDisabled}
                        style={{ marginBottom: 10 }}
                        rows={4}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Enter text here"
                        value={value}
                    />
                    {audios.filter(x => x && !x.isInProgress && !x.isSaving).map((x, i) => (
                        <div key={`${x.uri} | ${i}`} style={{ marginBottom: 5 }} >
                            <AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} showDelete={true} />
                        </div>
                    ))}
                </div>
                <AudioRecorder
                    noteId={currentNote.id}
                    feedbackId={appointmentContext?.appointment?.feedback?.id}
                    onRecorded={onRecordedAudio}
                    recorderRef={recorderRef}
                    onError={appointmentContext?.setError}
                    onUpdateRecording={onUpdateRecording}
                />
                <div className='flex align-center justify-between mt-10 w-100'>
                    {removable &&
                        <Popconfirm okType='default' disabled={modalInnerDisabled} title="Are you sure you want to delete this note?" onConfirm={deleteNote}>
                            <Button disabled={modalInnerDisabled} icon={<DeleteOutlined />} />
                        </Popconfirm>}
                    <Button disabled={modalInnerDisabled} type="primary" style={{ marginLeft: 'auto' }} loading={loading} onClick={save}>Save</Button>
                </div>
            </div>
        </Modal>
    </div>;
});

export default OneNote;
