import ApiClient from "../../base/ApiClient";
import { PagingResponse } from "../../base/Paging";
import { UserNotificationClientDto } from "./Model/UserNotificationClientDto";
import { UserNotificationListRequest } from "./Model/UserNotificationListRequest";

export default class UserNotificationApi {
    constructor(private api: ApiClient) { }
    private baseUrl: string = "/notifications";


    async list(req: UserNotificationListRequest) {
        const request = await this.api.client.post<PagingResponse<UserNotificationClientDto>>(`${this.baseUrl}/list`, { ...req });
        return request.data;
    }

    async read(id: number) {
        const request = await this.api.client.get<boolean>(`${this.baseUrl}/read/${id}`);
        return request.data;
    }

    async readAll() {
        const request = await this.api.client.get<boolean>(`${this.baseUrl}/read-all`);
        return request.data;
    }

}