import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Form, Input, Modal, notification, Popconfirm, Skeleton, Space, Tag } from 'antd';
import { AdminFeedbackDto, ProcessingStatus } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto';
import useApi from 'misc/hooks/useApi';
import { OutputType } from 'api/model/Appointments/AppointmentFeedback';
import { EditOutlined, UndoOutlined, MailOutlined, CheckOutlined } from '@ant-design/icons';
import VSEditor from 'app/components/VSEditor/VSEditor';
import useDrawer from 'misc/hooks/useDrawer';
import moment from 'moment';

const EmailTemplateTab: React.FC<{ feedback: AdminFeedbackDto }> = ({ feedback }) => {
    const outputRef = useRef<HTMLDivElement>(null);
    const api = useApi();
    const [isEditing, setIsEditing] = useState(false);
    const [editorContent, setEditorContent] = useState(feedback.feedbackOutput.clientEmail || '');
    const isGenerating = useMemo(() => feedback.feedbackOutput.clientEmailStatus == ProcessingStatus.Processing, [feedback.feedbackOutput.clientEmailStatus]);

    const emailModal = useDrawer();

    const generateClientEmail = useCallback(async () => {
        if (!feedback.id) return;
        try {
            await api.adminFeedback.generateOutput(feedback.id, OutputType.ClientEmail);
            notification.warning({ message: 'Email template is generating, wait please', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Failed to regenerate Email template', duration: 2 });
        }
    }, [feedback.id]);

    const updateClientEmail = useCallback((content: string) => {
        if (!feedback.id) return;
        try {
            api.adminFeedback.updateOutput(feedback.id, OutputType.ClientEmail, content);
            notification.success({ message: 'Email template updated successfully' });
        } catch (error) {
            notification.error({ message: 'Failed to update Email template' });
        } finally {
            setIsEditing(false);
        }
    }, [feedback.id]);

    useEffect(() => {
        if (feedback.feedbackOutput.clientEmail) return;
        if (feedback.feedbackOutput.clientEmailStatus == ProcessingStatus.Done) return;
        generateClientEmail();
    }, [feedback.feedbackOutput.clientEmail, generateClientEmail]);

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const textToCopy = tempDiv.innerText;

        await navigator.clipboard.writeText(textToCopy);
        notification.success({ message: 'Email template copied to clipboard as text' });
    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);

        notification.success({ message: 'Email template copied to clipboard with formatting' });
    }, []);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (!isEditing) setEditorContent(feedback.feedbackOutput.clientEmail || '');
    };

    if (feedback.feedbackOutput.clientEmailStatus == ProcessingStatus.Done && !feedback.feedbackOutput.clientEmail)
        return <div className='flex flex-col align-center gap-10'>
            <Alert message='Client email is empty, check the audios and fields and press "Regenerate" button' type='warning' />
            <Button type='primary' icon={<UndoOutlined />}
                disabled={isGenerating}
                loading={isGenerating}
                onClick={generateClientEmail}>
                Regenerate
            </Button>
        </div>;
    return <>
        {!isEditing && <div className='flex align-center justify-between mb-20'>
            <div className='flex align-center gap-10'>
                <Button onClick={() => copyHtmlToClipboard(feedback.feedbackOutput.clientEmail || '')} disabled={isGenerating}>Copy w/ formatting</Button>
                <Button onClick={() => copyTextToClipboard(feedback.feedbackOutput.clientEmail || '')} disabled={isGenerating}>Copy as text</Button>
                <Button onClick={() => emailModal.open()} disabled={isGenerating}><MailOutlined /></Button>
                {feedback.isClientEmailSent
                    && <Tag color='green'>
                        Email sent&nbsp;<CheckOutlined />
                        {feedback.clientEmailSentDate && <span>{moment(feedback.clientEmailSentDate).format('LLL')}</span>}
                    </Tag>}
            </div>
            <Space>
                <Button type='link' icon={<EditOutlined />}
                    onClick={toggleEditMode}
                    className='p-0'
                    disabled={isGenerating}
                    loading={isGenerating}>
                    Edit
                </Button>
                <Popconfirm
                    title="Are you sure to regenerate the email template? All edits will be lost."
                    onConfirm={generateClientEmail}
                    okText="Yes"
                    cancelText="No">
                    <Button type='link' icon={<UndoOutlined />}
                        disabled={isGenerating}
                        loading={isGenerating}>
                        Regenerate
                    </Button>
                </Popconfirm>
            </Space>
        </div>}
        {isEditing
            ? <VSEditor
                initialData={editorContent || '<p>No content available</p>'}
                onChange={setEditorContent}
                onSaved={updateClientEmail}
                onCanceled={toggleEditMode}
            />
            : feedback.feedbackOutput.clientEmailStatus == ProcessingStatus.Processing
                ? <Skeleton active />
                : <div ref={outputRef} dangerouslySetInnerHTML={{ __html: feedback.feedbackOutput.clientEmail || '' }} style={{ lineHeight: 1.6, marginTop: 10 }} />
        }

        <Modal title='Send Email'
            open={emailModal.visible}
            onCancel={emailModal.close}
            footer={null}
            maskClosable={false}
            destroyOnClose={true}>
            <SendEmailForm feedback={feedback} onSent={emailModal.close} />
        </Modal>
    </>;
};

export default EmailTemplateTab;

const SendEmailForm: React.FC<{ feedback: AdminFeedbackDto, onSent?: () => void }> = ({ feedback, onSent }) => {
    const api = useApi();
    const [form] = Form.useForm();
    const [email, setEmail] = useState(feedback.appointment?.clientEmail ?? '');
    const [subject, setSubject] = useState('');
    const [loading, setLoading] = useState(false);

    const sendEmail = useCallback(async () => {
        if (!email) return;

        setLoading(true);
        try {
            await api.adminFeedback.sendClientEmail(feedback.id, email, subject);
            notification.success({ message: 'Email sent successfully' });
            onSent?.();
        } catch (error) {
            notification.error({ message: 'Failed to send email' });
        } finally {
            setLoading(false);
        }
    }, [email, subject, feedback.id, onSent]);

    const defaultSubject = useMemo(() => `Appointment from ${moment(feedback.appointment?.date).format("LLL")} at ${feedback.appointment?.practiceName}`, [feedback.appointment?.date, feedback.appointment?.practiceName]);

    return <div>
        <Form layout='vertical' form={form} onFinish={sendEmail} initialValues={{ email: email }}>
            <Form.Item label='Email' name='email' required rules={[{ required: true, message: "Please enter Client's email address" }, { type: "email", message: 'Please enter valid email' }]}>
                <Input placeholder='Client email' value={email} onChange={e => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item label='Subject' name='subject'>
                <Input placeholder='Subject' value={subject} defaultValue={defaultSubject} onChange={e => setSubject(e.target.value)} />
            </Form.Item>
        </Form>
        <div className='flex justify-end'>
            <Popconfirm title="Are you sure to send this email?"
                onConfirm={() => form.submit()}
                okText="Yes"
                cancelText="No">
                <Button type='primary' loading={loading}>Send Email</Button>
            </Popconfirm>
        </div>
    </div>;
};
