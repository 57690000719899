import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styles from './PasswordInput.module.scss';
import { FormItemProps } from 'antd/lib/form';
import combineClasses from 'misc/helpers/combineClasses';

interface PasswordInputProps extends FormItemProps {
    placeholder?: string;
    autoComplete?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
    const [password, setPassword] = useState("");
    const [passwordCorrect, setPasswordCorrect] = useState(false);
    const [showChecker, setShowChecker] = useState(false);

    const checkPassword = (password: string) => {
        const conditions = {
            hasUpperCase: /\p{Lu}/u.test(password),
            hasLowerCase: /\p{Ll}/u.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            hasSixChars: /.{6,}/.test(password),
        };
        return conditions;
    };

    const passwordConditions = checkPassword(password);

    useEffect(() => {
        setPasswordCorrect(Object.values(passwordConditions).every(Boolean));
    }, [password]);

    return <>
        <Form.Item {...props}
            name={props.name}
            rules={[
                {
                    required: true,
                    message: 'Please enter your password',
                },
                {
                    validator: (_, value) => {
                        if (!value || passwordCorrect) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Password does not meet all the requirements'));
                    }
                }
            ]}
            className={combineClasses(props.className, `${password ? 'mb-0' : ''}`)}>
            <Input.Password
                placeholder={props.placeholder || "Password"}
                autoComplete={props.autoComplete || "new-password"}
                onChange={e => setPassword(e.target.value)}
                onFocus={() => setShowChecker(true)}
            />
        </Form.Item>

        <div className={`${styles.password_checker} ${showChecker && password ? styles.show : ''}`}>
            <span className={`${styles.password_checker_title} ${passwordCorrect ? styles.correct : ''}`}>
                {passwordCorrect ? 'The password meets all requirements!' : 'Password must follow the rules below:'}
            </span>
            <div className={`${styles.password_checker_items} ${!passwordCorrect ? styles.items_shown : ''}`}>
                <span className={`${styles.password_checker_item} ${passwordConditions.hasUpperCase ? styles.correct : ''}`}>
                    {passwordConditions.hasUpperCase ? <CheckOutlined /> : <CloseOutlined />}&nbsp;Uppercase letter
                </span>
                <span className={`${styles.password_checker_item} ${passwordConditions.hasLowerCase ? styles.correct : ''}`}>
                    {passwordConditions.hasLowerCase ? <CheckOutlined /> : <CloseOutlined />}&nbsp;Lowercase letter
                </span>
                <span className={`${styles.password_checker_item} ${passwordConditions.hasNumber ? styles.correct : ''}`}>
                    {passwordConditions.hasNumber ? <CheckOutlined /> : <CloseOutlined />}&nbsp;Number
                </span>
                <span className={`${styles.password_checker_item} ${passwordConditions.hasSpecialChar ? styles.correct : ''}`}>
                    {passwordConditions.hasSpecialChar ? <CheckOutlined /> : <CloseOutlined />}&nbsp;Special character
                </span>
                <span className={`${styles.password_checker_item} ${passwordConditions.hasSixChars ? styles.correct : ''}`}>
                    {passwordConditions.hasSixChars ? <CheckOutlined /> : <CloseOutlined />}&nbsp;Six characters
                </span>
            </div>
        </div>
    </>;
};

export default PasswordInput;
