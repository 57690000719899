import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { Template } from "api/vs-recording/model/Template/Template";
import useApi from "misc/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import styles from '../Recording.module.scss';

const TemplateList: React.FC<{ selectedId: number, onTemplateSelected: (template: Template) => void }> = ({ selectedId, onTemplateSelected }) => {
    const api = useApi();
    const [templates, setTemplates] = useState<Template[]>();

    const fetchTemplates = useCallback(async () => {
        const templates = await api.vsRecording.template.getAllTemplates();
        setTemplates(templates);
    }, []);

    const handleFavorite = useCallback(async (id: number, isFavorite: boolean) => {
        const updatedTemplates = await api.vsRecording.template.setFavorite(id);
        setTemplates(updatedTemplates);
    }, []);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const onTemplateChange = useCallback(async (template: Template) => {
        if (!template || template.id == selectedId) return;

        if (selectedId)
            Modal.confirm({
                title: <div className="flex flex-col">
                    <span>Are you sure you want to select this template?</span>
                    <span>This will overwrite any existing template notes</span>
                </div>,
                onOk: () => onTemplateSelected(template)
            });
        else
            onTemplateSelected(template);
    }, [templates, onTemplateSelected]);

    if (templates?.length == 0) return <Typography.Title level={3} className="color-white text-center">No templates are added to the account. <br /> You can add new from Templates tab.</Typography.Title>;
    return <div className='mt-30'>
        <Typography.Title level={3} className="color-white">Select Template</Typography.Title>
        {templates?.map((template) => {
            return <div key={template.id} onClick={() => onTemplateChange(template)}>
                <OneTemplate template={template} isCurrent={template.id == selectedId} onSetFav={() => handleFavorite(template.id, template.isFavorite)} />
            </div>
        })}
    </div>;

};

const OneTemplate: React.FC<{ template: Template, isCurrent: boolean, onSetFav: () => void }> = ({ template, isCurrent, onSetFav }) => {


    return <div className={styles.template_wrapper} data-selected={isCurrent}>
        <div className={styles.template_inner}>
            <div className="flex flex-col justify-center">
                <span className="fw-500 fs-20">{template.name}</span>
                {template.description && <span className="fs-14">{template.description}</span>}
            </div>
            <Button icon={!template.isFavorite ? <HeartOutlined /> : <HeartFilled />} onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onSetFav();
            }} />
        </div>
    </div>
}

export default TemplateList;