import { User } from "api/model/User/User";
import { Skeleton, Alert, Row, Typography, Button, Breadcrumb, Divider, Drawer, Col, Card, Tabs, Tag } from "antd";
import Details from "app/components/Details/Details";
import { tryParseInt } from "misc/helpers/intExtensions";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserEdit from "./UserEdit";


const UserView: React.FC = () => {
    const { id: userId } = useParams();
    const api = useApi();

    const [userState, setUserState] =
        useState<{ isLoading: boolean, error: string | null, user: User | null }>
            ({ isLoading: true, error: null, user: null });


    useEffect(() => {
        loadUser();
    }, [userId])

    const loadUser = async () => {
        setUserState({ ...userState, isLoading: true, error: null })
        try {
            var res = await api.adminUser.get(tryParseInt(userId));
            setUserState({ ...userState, user: res, isLoading: false });
        } catch (e: any) {
            setUserState({ ...userState, error: e.error, isLoading: false })
        }
    }

    const userCardContent = () => {

        if (userState.isLoading) {
            return <Skeleton active />
        }

        if (userState.error) {
            return <Alert style={{ marginTop: 10 }} message={userState.error} />
        }

        if (userState.user)
            return <Tabs style={{ marginTop: 20 }} type="card" >
                <Tabs.TabPane tab="Info" key="user">
                    <ProfileInfo user={userState.user} updateUser={loadUser} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Schedule" key="Schedule">
                    //Additional stuff
                </Tabs.TabPane> */}
            </Tabs>
    }

    return <>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/" >Admin</Link></Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/users" >Users</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                {userState.user?.email} <Divider type="vertical" /> {userState.user?.firstName || ""} {userState.user?.lastName || ""}
            </Breadcrumb.Item>
        </Breadcrumb>


        {userCardContent()}
    </>
}


export default UserView;



const ProfileInfo: React.FC<{ user: User, updateUser: () => {} }> = ({ user, updateUser }) => {
    const [state, setState] = useState<{ isEditing: boolean }>({ isEditing: false });
    return <Card title={"Info"}>
        <Details
            layout="horizontal"
            labelWidth="200px"
            data={[
                {
                    label: "Email",
                    value: user.email
                },
                {
                    label: "Registration",
                    value: user.registrationDate ? <span>{moment(user.registrationDate).format("DD MMMM YYYY HH:mm:ss")}</span> : <span className="t-secondary">No data</span>
                },
                {
                    label: "First Name",
                    value: user.firstName
                },
                {
                    label: "Last Name",
                    value: user.lastName
                },
                {
                    label: "Phone",
                    value: user.phone
                },
                {
                    label: "Doctor",
                    value: () => {
                        if (user.doctors.length == 0) return <span className="t-secondary">Not selected</span>
                        return user.doctors.map(x => <Tag key={x.id}>{x.firstName} {x.lastName}</Tag>)
                    }
                }


            ]} />
        <Button type="primary" size="large" style={{ marginTop: 20 }} onClick={() => setState({ isEditing: true })}> {"Edit Profile"}</Button>

        <Drawer destroyOnClose={true} visible={state.isEditing} onClose={() => setState({ isEditing: false })} title={"Edit User"}>
            <UserEdit user={user} onCancel={() => setState({ isEditing: false })} onSuccess={() => updateUser() && setState({ isEditing: false })} />
        </Drawer>
    </Card>
}

