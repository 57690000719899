import { notification, Skeleton, Tabs } from "antd";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "misc/hooks/useApi";
import OutputTab from "./Tabs/OutputTab";
import DischargeNoteTab from "./Tabs/DischargeNoteTab";
import useVSHub from "misc/hooks/useVSHub";
import VisitSummaryTab from "./Tabs/VisitSummaryTab";
import EmailTemplateTab from "./Tabs/EmailTemplateTab";

export type FeedbackOutputTabs = 'output' | 'dischargeNote' | 'visitSummary' | 'emailTemplate';

const FeedbackOutput: React.FC<{ id: number, tab?: FeedbackOutputTabs }> = observer(({ id, tab }) => {
    const api = useApi();
    const hub = useVSHub();
    const [feedback, setFeedback] = useState<AdminFeedbackDto | null>(null);

    const fetchFeedback = useCallback(async () => {
        const res = await api.adminFeedback.get(id);
        if (res) setFeedback(res.data);
        else notification.error({ message: 'Failed to fetch feedback' });
    }, [id]);

    useEffect(() => {
        fetchFeedback();
    }, []);

    useEffect(() => {
        if (!hub.connected || !feedback) return;

        hub.subscribe(`admin-feedback-updates-${id}`);
        hub.on<string>(`update-feedback-${id}`, updateFeedback)


        return () => {
            hub.unsubscribe(`admin-feedback-updates-${id}`);
            hub.off<string>(`update-feedback-${id}`)
        }
    }, [hub.connected, feedback]);

    const updateFeedback = async (fb: string) => {
        var res = JSON.parse(fb) as AdminFeedbackDto;

        if (feedback?.id == res.id)
            setFeedback(res);
    }

    if (!feedback) return <Skeleton active />;

    return <Tabs defaultActiveKey={tab ?? 'output'}>
        <Tabs.TabPane tab="Output" key="output">
            <OutputTab feedback={feedback} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Visit Summary" key="visitSummary">
            <VisitSummaryTab feedback={feedback} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Discharge Note" key="dischargeNote">
            <DischargeNoteTab feedback={feedback} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Client Email" key="emailTemplate">
            <EmailTemplateTab feedback={feedback} />
        </Tabs.TabPane>
    </Tabs>;
});

export default FeedbackOutput;

