export enum AppointmentFeedbackField {
	soapSCC = 1,
	soapSHPI = 2,
	soapSMedications = 3,
	soapSHistory = 4,
	soapOVitalSigns = 5,
	soapOGeneralObservations = 6,
	soapOPhysicalExaminations = 7,
	soapODiagnostic = 8,
	soapADiagnosis = 9,
	soapPDiagnostic = 10,
	soapPTreatment = 11,
	soapPEducation = 12,
	soapPConsultation = 13,
	soapPFollowUp = 14,
}


export const AppointmentFeedbackFieldList = [
	{ value: AppointmentFeedbackField.soapSCC, label: "soapSCC" },
	{ value: AppointmentFeedbackField.soapSHPI, label: "soapSHPI" },
	{ value: AppointmentFeedbackField.soapSMedications, label: "soapSMedications" },
	{ value: AppointmentFeedbackField.soapSHistory, label: "soapSHistory" },
	{ value: AppointmentFeedbackField.soapOVitalSigns, label: "soapOVitalSigns" },
	{ value: AppointmentFeedbackField.soapOGeneralObservations, label: "soapOGeneralObservations" },
	{ value: AppointmentFeedbackField.soapOPhysicalExaminations, label: "soapOPhysicalExaminations" },
	{ value: AppointmentFeedbackField.soapODiagnostic, label: "soapODiagnostic" },
	{ value: AppointmentFeedbackField.soapADiagnosis, label: "soapADiagnosis" },
	{ value: AppointmentFeedbackField.soapPDiagnostic, label: "soapPDiagnostic" },
	{ value: AppointmentFeedbackField.soapPTreatment, label: "soapPTreatment" },
	{ value: AppointmentFeedbackField.soapPEducation, label: "soapPEducation" },
	{ value: AppointmentFeedbackField.soapPConsultation, label: "soapPConsultation" },
	{ value: AppointmentFeedbackField.soapPFollowUp, label: "soapPFollowUp" },
]