import { UserUpdateRequest } from "api/routes/UserAdmin/Model/UserUpdateRequest";
import { User } from "api/model/User/User";
import { SAASUserRolesList, UserRoleEnum, UserRoleNoSaaSList, UserRolesList } from "api/model/User/UserRole";
import { Alert, Input, Button, Form, Divider, notification, Row, Select, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import DoctorMultiSelect from "app/components/DoctorMultiSelect";
import PracticeMultiSelect from "app/components/PracticeMultiSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useEffect, useMemo, useState } from "react";
import { LanguageEnumList } from "api/model/User/Language";
import { InfoCircleOutlined } from "@ant-design/icons";
import PhoneInput from "app/components/PhoneInput/PhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import AccountSelect from "app/components/AccountSelect";


const UserEdit: React.FC<{ user: User, onSuccess: () => void, onCancel: () => void }> = ({ user, onSuccess, onCancel }) => {
	const api = useApi();
	const currentUser = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });
	const [selectedRoles, setSelectedRoles] = useState<UserRoleEnum[]>(user?.roles || []);

	const submit = async (values: UserUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = user.id;

			if (currentUser.isSuperAdmin)
				values.accountId = form.getFieldValue("accountId") ?? null;
			
			try {
				const res = await api.adminUser.updateCreate(values);
				if (res.success == true) {
					notification.success({ message: "Saved" })
					setState({ ...state, error: null, loading: false })
					if (onSuccess) onSuccess()
				}
			} catch (e) {
				setState({ ...state, loading: false, error: e.error || "Error occured" })
				notification.error({ message: e.error || "Error occured" })
			}
		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	const showAccesiblePractices = useMemo(() => {
		if (selectedRoles.includes(UserRoleEnum.Scribe) || selectedRoles.includes(UserRoleEnum.VetTech) || selectedRoles.includes(UserRoleEnum.AccountVetTech))
			return true;

	}, [selectedRoles]);

	if (user == null) return null;
	return <Form form={form} onFinish={submit} layout="vertical"
		initialValues={user
			? {
				...user,
				accountId: user?.accounts?.[0]?.id!,
			}
			: {}}>
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		{user.accounts && user.accounts.length > 0 &&
			<div style={{ marginBottom: 10 }}>
				Account <br /> <b style={{ fontSize: 20 }}>{user.accounts.map(x => x.name).join(", ")}</b>
			</div>
		}


		<Form.Item label={"Email"} name="email" required rules={[{ required: true, type: 'email', message: 'Enter valid email' }]}>
			<Input type="new-email" />
		</Form.Item>

		<Form.Item label={"First Name"} name="firstName">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Last Name"} name="lastName">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Phone Number"} name="phone" rules={[
			{ required: false },
			{
				validator: async (_, value) => {
					if (value && !isValidPhoneNumber(value)) {
						return Promise.reject(new Error('Enter valid phone number'));
					}
					return Promise.resolve();
				}
			}
		]}>
			<PhoneInput country={'us'} />
		</Form.Item>

		<Form.Item label={"Doctor"} name="doctorIds" tooltip={"User will see in the app all appointments for selected doctors"}>
			<DoctorMultiSelect accountIds={user.accounts?.map(x => x.id)} value={form.getFieldValue("doctorIds") || []} onChange={(x: any) => form.setFieldsValue({ doctorIds: x })} />
		</Form.Item>

		{currentUser.isSuperAdmin && <Form.Item label={"Account"} name="accountId">
			<AccountSelect value={form.getFieldValue("accountId")} onChange={(x: number) => form.setFieldsValue({ accountId: x })} style={{ width: '100%' }} />
		</Form.Item>}

		<Divider>{"Password"}</Divider>
		<p style={{ textAlign: "center" }}>{"Enter new password to change"}</p>

		<Form.Item label={"Password"} name="password" rules={[
			{
				validator: (_, value) => {
					const conditions = {
						hasUpperCase: /\p{Lu}/u.test(value),
						hasLowerCase: /\p{Ll}/u.test(value),
						hasNumber: /\d/.test(value),
						hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
						hasSixChars: /.{6,}/.test(value),
					};

					const passwordCorrect = Object.values(conditions).every(x => x);

					if (value && !passwordCorrect) {
						return Promise.reject(new Error('Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and six characters'));
					}
					return Promise.resolve();
				}
			}
		]}>
			<Input.Password autoComplete="new-password" />
		</Form.Item>

		<Form.Item label={"Confirm Password"} name="passwordConfirm" dependencies={['password']}
			rules={[
				({ getFieldValue }) => ({
					validator(_, value) {
						if (!value || getFieldValue('password') === value) {
							return Promise.resolve();
						}
						return Promise.reject(new Error('Passwords don’t match'));
					},
				})
			]}>
			<Input.Password autoComplete="new-password" />
		</Form.Item>



		<Divider>Misc</Divider>

		<Form.Item label="Language" name="language" tooltip="This language will be chosen for dictation transcript">
			<Select placeholder="Select Roles" style={{ width: '100%' }} >
				{LanguageEnumList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
			</Select>
		</Form.Item>

		{
			!currentUser.isSAASUser
				? <Form.Item label="Admin Roles" name="roles">
					<Select
						disabled={!currentUser?.isInRole([UserRoleEnum.SuperAdmin])}
						mode="tags"
						placeholder="Select Roles"
						onChange={x => setSelectedRoles(x)}
						style={{ width: '100%' }}
					>
						{
							user.accounts && user.accounts.length > 0
								? UserRolesList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)
								: UserRoleNoSaaSList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)
						}
					</Select>
				</Form.Item>
				: <Form.Item label="Admin Roles" name="roles" tooltip={"If user have any admin roles, he can access admin area not only the app"}>
					<Select
						disabled={!currentUser?.isInRole([UserRoleEnum.AccountManager, UserRoleEnum.AccountOwner])}
						mode="tags"
						placeholder="Select Roles"
						onChange={x => setSelectedRoles(x)}
						style={{ width: '100%' }}
					>
						{SAASUserRolesList.map(x => <Select.Option key={x.value} value={x.value}>
							{x.label}
							{x.description && <Tooltip title={x.description}>&nbsp;<InfoCircleOutlined className="fs-12" /></Tooltip>}
						</Select.Option>)}
					</Select>
				</Form.Item>
		}

		{(!currentUser.isSAASUser && showAccesiblePractices) && <>
			<Divider>{"Scribes or VetTech access"}</Divider>

			<Form.Item label={"Accessible Practices"} name="practiceIds" help="Needed for SCRIBES AND VET TECH ONLY to limit access. Not selected means ALL">
				<PracticeMultiSelect value={form.getFieldValue("practiceIds") || []} onChange={(x: any) => form.setFieldsValue({ practiceIds: x })} />
			</Form.Item>
		</>}

		<Divider />

		<Row justify="space-between">
			<Button type="default" onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form >
}

export default UserEdit;
