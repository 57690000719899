export enum SyncVetPracticeStatus {
	Enabled,
	InProgress,
	Disabled,
	Error
}

export const SyncVetPracticeStatusList = [
	{ value: SyncVetPracticeStatus.Disabled, label: "Disabled" },
	{ value: SyncVetPracticeStatus.Enabled, label: "Enabled" },
	{ value: SyncVetPracticeStatus.Error, label: "Error" },
	{ value: SyncVetPracticeStatus.InProgress, label: "In Progress" }
]