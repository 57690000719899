import { Doctor } from "api/model/Offices/Doctor";
import { Alert, Input, Button, Form, Divider, notification, Row, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import OfficeSelect from "app/components/OfficeSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";
import { DoctorUpdateRequest } from "api/routes/Doctors/Model/DoctorUpdateRequest";
import PracticeSelect from "app/components/PracticeSelect";
import { UserRoleEnum } from "api/model/User/UserRole";


const DoctorEdit: React.FC<{ doctor: Doctor, onSuccess: () => void, onCancel: () => void }> = ({ doctor, onSuccess, onCancel }) => {
	const api = useApi();
	const user = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });


	const submit = async (values: DoctorUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = doctor.id;
			const res = await api.adminDoctor.updateCreate(values);
			if (res.success == true) {
				notification.success({ message: "Saved" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occured" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	if (!doctor) return null;

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={doctor || {}}  >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"First Name"} name="firstName" rules={[{ required: true, message: 'Please enter first name' }]}>
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Last Name"} name="lastName">
			<Input type="text" />
		</Form.Item>

		{/* <Form.Item label={"Office"} name="officeId">
			<OfficeSelect value={doctor.officeId ? doctor.officeId : null} onChange={(x: any) => form.setFieldsValue({ officeId: x })} />
		</Form.Item> */}

		<Form.Item label="Hospital" name={"practiceId"} rules={[{ required: true, message: 'You must select Hospital' }]}>
			<PracticeSelect
				forceSelectOne={!user.isSuperAdmin}
				onChange={(x: any) => {
					form.setFieldsValue({ practiceId: x })
				}} />
		</Form.Item>

		<Divider />

		<Row justify="space-between">
			<Button type="ghost" onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form>
}

export default DoctorEdit;
