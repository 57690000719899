import { Alert, Button, Divider, Form, Input, Row, Space, Switch, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { Template } from "api/model/Template/Template";
import { TemplateField, TemplateFieldType } from "api/model/Template/TemplateField";
import { TemplateCreateDto } from "api/routes/Templates/Model/TemplateCreateDto";
import AccountSelect from "app/components/AccountSelect";
import DoctorMultiSelect from "app/components/DoctorMultiSelect";
import PracticeSelect from "app/components/PracticeSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import TemplateFieldEdit from "./field/TemplateFieldEdit";
import { UserRoleEnum } from "api/model/User/UserRole";


const TemplateEdit: React.FC<{ template: Template, onSuccess: () => void, onCancel: () => void }> =
	({ template, onSuccess, onCancel }) => {
		const api = useApi();
		const user = useCurrentUser();
		const [form] = useForm<TemplateCreateDto>();
		const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });
		const [fields, setFields] = useState<TemplateField[]>(template?.fields || []);
		const [selectedPracticeId, setSelectedPracticeId] = useState<number | null>(null);
		const [selectedDoctorIds, setSelectedDoctors] = useState<number[] | []>([]);

		const reorderFields = (list: TemplateField[], startIndex: number, endIndex: number) => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);

			return result;
		}

		const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
			if (!result.destination) {
				return;
			}

			const items = reorderFields(
				fields,
				result.source.index,
				result.destination.index
			);

			setFields(items);
		}

		const addField = (type: TemplateFieldType) => {
			const field: TemplateField = {
				id: new Date().getTime(),
				label: "",
				defaultValue: "",
				position: fields.length + 1,
				idTemplate: template.id,
				isNew: true,
				type: type,
				isBold: false,
				isUppercase: false
			}

			setFields(prev => [...prev, field]);
		}

		const deleteField = (id: number) => {
			setFields(prev => prev.filter(x => x.id !== id));
		}

		const updateField = (field: TemplateField) => {
			setFields(prev => prev.map(x => x.id === field.id ? { ...x, ...field } : x));
		}

		const submit = async (values: TemplateCreateDto) => {
			setState({ ...state, loading: true, error: "" })

			try {
				values.id = template.id;

				values.fields = fields.map((x, i) => ({
					id: x.isNew ? 0 : x.id,
					label: x.label,
					defaultValue: x.defaultValue,
					position: i + 1,
					type: x.type,
					isBold: x.isBold,
					isUppercase: x.isUppercase
				}));
				const res = await api.adminTemplates.updateCreate(values);
				if (res.success == true) {
					notification.success({ message: "Saved" })
					setState({ ...state, error: null, loading: false })
					if (onSuccess) onSuccess()
				} else {
					notification.error({ message: res.error || "Error occurred" })
					setState({ ...state, loading: false, error: res.error || "Error occurred" })
				}

			} catch (e: any) {
				setState({ ...state, error: e.message, loading: false })
			}
		}

		const onPracticeChange = (id?: number) => {
			form.setFieldsValue({ idPractice: id });
			setSelectedPracticeId(id || null);
		}

		const onDoctorIdsChange = (ids: number[]) => {
			form.setFieldsValue({ idsDoctor: ids });
			setSelectedDoctors(ids);
		}

		if (!template) return null;

		return <Form form={form} onFinish={submit} layout="vertical" initialValues={template || {}}  >
			{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

			<Form.Item label="Name" name="name" rules={[
				{
					required: true,
					message: 'Please enter the name'
				},
				{
					min: 3,
					message: 'Name must be at least 3 characters long'
				},
				{
					max: 50,
					message: 'Name must be at most 50 characters long'
				}
			]}>
				<Input type="text" />
			</Form.Item>

			<Form.Item label="Description" name="description" >
				<TextArea autoSize rows={3} />
			</Form.Item>

			<Form.Item label="Hospital" name={"idPractice"} tooltip="You can limit this template to selected hospital">
				<PracticeSelect forceSelectOne={!user.isSuperAdmin} onChange={onPracticeChange} />
			</Form.Item>

			{form.getFieldValue("idPractice") > 0 &&
				<Form.Item label="Doctor" name={"idsDoctor"} tooltip="Only selected doctors will see this template in the app">
					<DoctorMultiSelect practiceId={form.getFieldValue("idPractice")} onChange={onDoctorIdsChange} />
				</Form.Item>
			}

			<Form.Item label="Is Published" name="isPublished" tooltip="Is visible in the APP" valuePropName="checked" >
				<Switch />
			</Form.Item>
			{!user.isSAASUser && !user.isInRole([UserRoleEnum.PracticeSuperUser]) &&
				<Form.Item label={"Account"} name="idAccount">
					<AccountSelect onChange={(x: number) => form.setFieldsValue({ idAccount: x })} />
				</Form.Item>
			}

			<div className="template-fields-wrapper">
				<div>Fields</div>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{fields?.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id.toString()} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<TemplateFieldEdit templateField={item} onUpdate={updateField} onDeleteField={() => deleteField(item.id)} />
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<Space>
					<Button type="primary" onClick={() => addField(TemplateFieldType.Simple)}>Add Field</Button>
					<Button type="primary" onClick={() => addField(TemplateFieldType.Separator)}>Add Separator</Button>
				</Space>

			</div>

			<Divider />

			<Row justify="space-between">
				<Button type="default" onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
				<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
			</Row>
		</Form>
	}

export default observer(TemplateEdit);
