import React from "react";
import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Row, Select, Space, Table, TablePaginationConfig, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { FeedbackHistoryStore } from "./FeedbackHistoryStore";
import { AdminFeedbackHistoryDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackHistoryDto";


const FeedbackHistory: React.FC<{ feedbackId: number }> = observer(({ feedbackId }) => {
	const api = useApi();
	const store = useLocalObservable<FeedbackHistoryStore>(() => new FeedbackHistoryStore(api, feedbackId));

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<AdminFeedbackHistoryDto> | SorterResult<AdminFeedbackHistoryDto>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};


	const columns: ColumnsType<AdminFeedbackHistoryDto> = [
		{
			title: 'CreatedDate',
			key: 'CreatedDate',
			sorter: true,
			width: "175px",
			ellipsis: false,
			defaultSortOrder: "descend",
			render: (x: AdminFeedbackHistoryDto) => <>
				<div>{moment(x.createdDate).format("LLL")}</div>
				<div>{x.editorName}</div>
			</>
		},
		{
			title: 'Changes',
			key: 'Changes',
			ellipsis: false,
			render: (x: AdminFeedbackHistoryDto) => <>
				{x.changes.map((change, index) => {
					let fieldName = change.field ? change.field.toUpperCase() : "";
					switch (fieldName) {
						case "soapSCC".toUpperCase():
							fieldName = "[S] Chief Complaint";
							break;
						case "soapSMedications".toUpperCase():
							fieldName = "[S] Current Medications";
							break;
						case "soapSHPI".toUpperCase():
							fieldName = "[S] History of Present Illness";
							break;
						case "soapSHistory".toUpperCase():
							fieldName = "[S] Social History / Habits";
							break;

						case "soapOVitalSigns".toUpperCase():
							fieldName = "[O] Vital Signs";
							break;
						case "soapOGeneralObservations".toUpperCase():
							fieldName = "[O] General Observations";
							break;
						case "soapOPhysicalExaminations".toUpperCase():
							fieldName = "[O] Physical Examination";
							break;
						case "soapODiagnostic".toUpperCase():
							fieldName = "[O] Diagnostic";
							break;

						case "soapADiagnosis".toUpperCase():
							fieldName = "[A] Diagnosis";
							break;

						case "soapPDiagnostic".toUpperCase():
							fieldName = "[P] Diagnostic";
							break;
						case "soapPTreatment".toUpperCase():
							fieldName = "[P] Treatment";
							break;
						case "soapPEducation".toUpperCase():
							fieldName = "[P] Education";
							break;
						case "soapPConsultation".toUpperCase():
							fieldName = "[P] Consultation";
							break;
					}

					return <div key={change.id}>
						<div>
							{change.field ?
								<b>{fieldName}</b>
								:
								<b>Changes</b>
							}
						</div>
						<div>{change.value}</div>
					</div>
				})}
			</>
		}
	];

	return <>
		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.createdDate ? new Date(x.createdDate).getTime() : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}
	</>
})

export default FeedbackHistory