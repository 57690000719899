import ApiClient from "../../../base/ApiClient";
import { NiceResponse } from "../../../base/NiceResponse";
import { PagingResponse } from "../../../base/Paging";
import { Patient } from "../../../model/Clients/Patient";
import { PatientListRequest } from "./Model/PatientListRequest";
import { PatientUpdateRequest } from "./Model/PatientUpdateRequest";


export default class AdminPatientApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/patient";

	async list(req: PatientListRequest) {
		const request = await this.api.client.post<PagingResponse<Patient>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<Patient>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(x: Patient) {
		const request = await this.api.client.delete<NiceResponse<Patient>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

	async updateCreate(req: PatientUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<Patient>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async restore(x: Patient) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

}