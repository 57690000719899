import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { NotificationInstance } from "antd/es/notification/interface";
import { UserReport } from "api/model/Analytics/UserReport";
import { UserReportListRequest } from "api/routes/Analytics/Model/UserReportListRequest";
import moment from "moment";
import { TotalsReportsRequest } from "api/routes/Analytics/Model/TotalsReportRequest";
import { TotalsReport } from "api/model/Analytics/TotalsReport";

export class TotalsReportStore {
	loading: boolean = false;
	error: string | null = null;

	filter: TotalsReportsRequest = {
		from: moment().startOf('month').toDate(),
		to:  moment().endOf('month').toDate(),
	}

	data: TotalsReport | null = null;
	notificationApi?: NotificationInstance;

	constructor(private api: ApiClient, p?: Partial<TotalsReportStore>, autoLoad: boolean = true) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		if(autoLoad) this.load();
	}

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminAnalytics.totals(this.filter);
			this.setState({
				filter: {
					...this.filter,
				},
				data: r
			});
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	};


	setState = (p: Partial<TotalsReportStore>) => {
		Object.assign(this, p);
	};

	setFilter = (p: Partial<TotalsReportsRequest>) => {
		Object.assign(this.filter, p);
		this.load();
	};

}
