import { BasicEntity } from "../BasicEntity";
import { UserSimple } from "../User/UserSimple";
import { AppointmentFeedbackField } from "./enums/AppointmentFeedBackFields";

export interface FeedbackComment extends BasicEntity {
	text: string | null;
	appointmentFeedBackField?: AppointmentFeedbackField | null;
	appointmentFeedbackId?: number | null;
	appointmentFeedbackNoteId?: number | null;
	status: FeedbackCommentStatus;
	createdById: number | null;
	createdBy: UserSimple | null;
	completedById: number | null;
	completedBy: UserSimple | null;
}

export enum FeedbackCommentStatus {
	Created,
	Completed
}