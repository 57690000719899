import ApiClient from "../../../base/ApiClient";
import { NiceResponse } from "../../../base/NiceResponse";
import { FeedbackComment } from "../../../model/Appointments/FeedbackComment";
import { ReviewNoteCreateRequest } from "./DTO/ReviewNoteCreateRequest";

export default class AdminFeedbackCommentApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/feedback-comment";

	async create(note: ReviewNoteCreateRequest) {
		const request = await this.api.client.post<NiceResponse<FeedbackComment>>(`${this.baseUrl}/create`, note);
		return request.data;
	}

	async delete(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.delete<NiceResponse<boolean>>(`${this.baseUrl}/delete/${id}`);
		return request.data;
	}

	async complete(id: number | null, complete: boolean) {
		if (!id) return null;
		const request = await this.api.client.post<NiceResponse<FeedbackComment>>(`${this.baseUrl}/complete`, { id: id, complete: complete });
		return request.data;
	}

}