import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, notification, Popconfirm, Skeleton, Space } from 'antd';
import { AdminFeedbackDto, ProcessingStatus } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto';
import useApi from 'misc/hooks/useApi';
import { OutputType } from 'api/model/Appointments/AppointmentFeedback';
import { EditOutlined, UndoOutlined } from '@ant-design/icons';
import VSEditor from 'app/components/VSEditor/VSEditor';
import PrintButton from 'app/components/PrintButton/PrintButton';

const DischargeNoteTab: React.FC<{ feedback: AdminFeedbackDto }> = ({ feedback }) => {
    const outputRef = useRef<HTMLDivElement>(null);
    const api = useApi();
    const [isEditing, setIsEditing] = useState(false);
    const [editorContent, setEditorContent] = useState(feedback.feedbackOutput.dischargeNote || '');
    const isGenerating = useMemo(() => feedback.feedbackOutput.dischargeNoteStatus == ProcessingStatus.Processing, [feedback.feedbackOutput.dischargeNoteStatus]);

    const generateDischargeNote = useCallback(async () => {
        if (!feedback.id) return;
        try {
            await api.adminFeedback.generateOutput(feedback.id, OutputType.DischargeNote);
            notification.warning({ message: 'Discharge note is generating, wait please', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Failed to regenerate discharge note', duration: 2 });
        }
    }, [feedback.id]);

    const updateOutput = useCallback((content: string) => {
        if (!feedback.id) return;
        try {
            api.adminFeedback.updateOutput(feedback.id, OutputType.DischargeNote, content);
            notification.success({ message: 'Discharge note updated' });
        } catch (error) {
            notification.error({ message: 'Failed to update discharge note' });
        } finally {
            setIsEditing(false);
        }
    }, []);

    useEffect(() => {
        if (feedback.feedbackOutput.dischargeNote) return;
        if (feedback.feedbackOutput.dischargeNoteStatus == ProcessingStatus.Done) return;
        generateDischargeNote();
    }, [feedback.feedbackOutput.dischargeNote]);

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const textToCopy = tempDiv.innerText;

        await navigator.clipboard.writeText(textToCopy);
        notification.success({ message: 'Discharge note copied to clipboard as text' });
    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);

        notification.success({ message: 'Discharge note copied to clipboard with formatting' });
    }, []);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (!isEditing) setEditorContent(feedback.feedbackOutput.dischargeNote || '');
    };

    const printTitle = useMemo(() => feedback
        ? `Discharge Note - ${feedback.appointment?.clientName} | ${feedback.appointment?.date.toString()}`
        : 'Unknown', [feedback.appointment?.clientName, feedback.appointment?.date]);


    if (feedback.feedbackOutput.dischargeNoteStatus == ProcessingStatus.Done && !feedback.feedbackOutput.dischargeNote)
        return <div className='flex flex-col align-center gap-10'>
            <Alert message='Discharge note is empty, check the audios and fields and press "Regenerate" button' type='warning' />
            <Button type='primary' icon={<UndoOutlined />}
                disabled={isGenerating}
                loading={isGenerating}
                onClick={generateDischargeNote}>
                Regenerate
            </Button>
        </div>;

    return <div>
        {!isEditing && <div className='flex align-center justify-between mb-20'>
            <div className='flex align-center gap-10'>
                <Button onClick={() => copyHtmlToClipboard(feedback.feedbackOutput.dischargeNote || '')} disabled={isGenerating}>Copy w/ formatting</Button>
                <Button onClick={() => copyTextToClipboard(feedback.feedbackOutput.dischargeNote || '')} disabled={isGenerating}>Copy as text</Button>
                <PrintButton printTitle={printTitle} bodyContent={feedback.feedbackOutput.dischargeNote || ''} disabled={isGenerating} />
            </div>
            <Space>
                <Button type='link' icon={<EditOutlined />}
                    disabled={isGenerating}
                    loading={isGenerating}
                    onClick={toggleEditMode} className='p-0'>
                    Edit
                </Button>
                <Popconfirm title="Are you sure to regenerate discharge note? All edites will be lost."
                    onConfirm={generateDischargeNote} okText="Yes" cancelText="No">
                    <Button type='link' icon={<UndoOutlined />}
                        disabled={isGenerating}
                        loading={isGenerating}>
                        Regenerate
                    </Button>
                </Popconfirm>
            </Space>
        </div>}
        {isEditing
            ? <VSEditor
                initialData={editorContent || '<p>No content available</p>'}
                onChange={setEditorContent}
                onSaved={content => updateOutput(content)}
                onCanceled={toggleEditMode}
            />
            : feedback.feedbackOutput.dischargeNoteStatus == ProcessingStatus.Processing
                ? <Skeleton active />
                : <div ref={outputRef} dangerouslySetInnerHTML={{ __html: feedback.feedbackOutput.dischargeNote || '' }} style={{ lineHeight: 1.6, marginTop: 10 }} />
        }
    </div>;
};

export default DischargeNoteTab;
