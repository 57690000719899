import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useState } from "react";
import AppointmentList from "./AppointmentList/AppointmentList";
import { AppointmentListStore } from "./AppointmentList/AppointmentListStore";
import DaysSelector from "./DaysSelector/DaysSelector";

type ScheduleProps = {
    store: AppointmentListStore;
    onAppointmentClick: (appointment: AppointmentClientDto) => void;
}

const Schedule: React.FC<ScheduleProps> = observer(({ store, onAppointmentClick }) => {
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());

    const handleDayChange = (selectedDate: moment.Moment) => {
        const dateToSet = moment(selectedDate).set({ hour: moment().hour(), minute: moment().minute() });
        setSelectedDate(dateToSet);
        const dateFrom = selectedDate.startOf('day').toDate();
        const dateTo = selectedDate.endOf('day').toDate();
        store.setDateFilter(dateFrom, dateTo);
    };

    const handleMonthChange = (direction: 'previous' | 'next') => {
        const newDate = direction === 'previous' ? selectedDate.clone().subtract(1, 'month') : selectedDate.clone().add(1, 'month');
        setSelectedDate(newDate);
        if (direction === 'next')
            store.monthForward();
        else
            store.monthBack();
    };

    return <div>
        <DaysSelector selectedDate={selectedDate} onMonthChange={x => handleMonthChange(x)} onDayChange={x => handleDayChange(x)} />
        <AppointmentList store={store}
            loading={false}
            currentDate={selectedDate}
            onAppointmentClick={onAppointmentClick} />
    </div>;
});


export default Schedule;