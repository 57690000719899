import { ReviewNoteCreateRequest } from "api/routes/Appointments/Feedback/DTO/ReviewNoteCreateRequest";
import { AppointmentFeedbackField } from "api/model/Appointments/enums/AppointmentFeedBackFields";
import { UserRoleEnum } from "api/model/User/UserRole";
import { Button, Input, InputRef, Popconfirm, Popover, Space, Tag, notification } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { observer } from "mobx-react";
import moment from "moment";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { DeleteOutlined, CheckOutlined, RollbackOutlined } from "@ant-design/icons"
import { FeedbackComment, FeedbackCommentStatus } from "api/model/Appointments/FeedbackComment";

export type FeedbackCommentsProps = {
	comments: FeedbackComment[],
	appointmentFeedBackField?: AppointmentFeedbackField | null;
	appointmentFeedbackId?: number | null;
	appointmentFeedbackNoteId?: number | null;
	view?: boolean;
}

const FeedbackComments: React.FC<{ props: FeedbackCommentsProps }> = observer(({ props }) => {
	const [notes, setNotes] = useState<FeedbackComment[]>(props.comments.sort((a, b) => moment(a.createdDate).diff(moment(b.createdDate))));
	const [creating, setCreating] = useState(false);

	const completeNote = (note: FeedbackComment) => {
		var notesList = [...notes];
		var _note = notesList.find(x => x.id == note.id);
		_note.status = note.status;
		setNotes(notesList);
	}

	const deleteNote = (note: FeedbackComment) => {
		setNotes(prev => prev.filter(x => x.id != note.id));
	}

	const onCreated = (note: FeedbackComment) => {
		const notesList = [...notes, note];
		const notesListSorted = notesList.sort((a, b) => moment(a.createdDate).diff(moment(b.createdDate)));
		setNotes(notesListSorted);
		setCreating(false);
	}

	const newNotes = notes.filter(x => x.status == FeedbackCommentStatus.Created);
	const completedNotes = notes.filter(x => x.status == FeedbackCommentStatus.Completed);

	return <div className="feedback-comment-wrapper">
		{newNotes.length > 0 &&
			<Popover trigger={"click"}
				title="New Comments"
				placement="bottomRight"
				content={<div style={{ width: 300 }}>
					{newNotes.map(x => <OneReviewNote onDeleted={deleteNote} onCompleted={completeNote} note={x} key={x.id} />)}
				</div>
				}>
				<Tag color={"orange"} > New {newNotes.length}</Tag>
			</Popover>
		}

		{completedNotes.length > 0 &&
			<Popover trigger={"click"}
				title="Completed Comments"
				placement="bottomRight"
				content={<div style={{ width: 300 }}>
					{completedNotes.map(x => <OneReviewNote onDeleted={deleteNote} onCompleted={completeNote} note={x} key={x.id} />)}
				</div>
				}>
				<Tag color={"green"} > Completed {completedNotes.length}</Tag>
			</Popover>
		}

		{!props.view && <a style={{ fontSize: 12 }} onClick={() => setCreating(true)}>Add Comment</a>}
		{creating &&
			<CreateReviewNoteForm
				onCreated={onCreated}
				onCancel={() => setCreating(false)}
				model={{
					appointmentFeedbackId: props.appointmentFeedbackId,
					appointmentFeedBackField: props.appointmentFeedBackField,
					appointmentFeedbackNoteId: props.appointmentFeedbackNoteId
				}} />
		}

	</div >
})

export default FeedbackComments;

const OneReviewNote: React.FC<{ note: FeedbackComment, onDeleted: (note: FeedbackComment) => void, onCompleted: (note: FeedbackComment) => void }> =
	observer(({ note, onDeleted, onCompleted }) => {
		const user = useCurrentUser();
		const api = useApi();
		const [state, setState] = useState<{ loading?: boolean, error?: string | null }>({});
		const powerRoles = [UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Vet];
		const canDelete = note.createdBy.id == user.id || user.isInRole(powerRoles)
		const canComplete = note.createdBy.id == user.id || user.isInRole(powerRoles)

		const remove = async () => {
			setState({ loading: true });
			const result = await api.adminFeedbackComment.delete(note.id);
			setState({ loading: false });
			if (result.success) {
				onDeleted(note);
			} else {
				notification.error({ message: "Error", description: result.error })
			}
		}

		const complete = async () => {
			setState({ loading: true });
			var newStatus = note.status != FeedbackCommentStatus.Completed;
			const result = await api.adminFeedbackComment.complete(note.id, newStatus);
			note.status = note.status == FeedbackCommentStatus.Completed ? FeedbackCommentStatus.Created : FeedbackCommentStatus.Completed;
			setState({ loading: false });
			if (result.success) {
				onCompleted(note);
			} else {
				notification.error({ message: "Error", description: result.error })
			}
		}

		const isCompleted = note.status == FeedbackCommentStatus.Completed;

		return <div className="feedback-comment">
			<div className="feedback-comment-info">
				<div className="feedback-comment-info-left">
					<div className="feedback-comment-info-user">
						{note.createdBy.firstName} {note.createdBy.lastName}
					</div>
					<div className="feedback-comment-info-date">
						{moment(note.createdDate).utc().format("LLL")}
					</div>
				</div>
				<div>
					<Popconfirm onConfirm={remove} title="Delete comment?" disabled={!canDelete}>
						<Button disabled={!canDelete} loading={state.loading} type={"link"} icon={<DeleteOutlined />} />
					</Popconfirm>


					<Button disabled={!canComplete} loading={state.loading}
						type={isCompleted ? "default" : "primary"} onClick={complete}
						icon={isCompleted ? <RollbackOutlined /> : <CheckOutlined />} />


				</div>
			</div>
			<div className="feedback-comment-text">{note.text}</div>
		</div>
	})

const CreateReviewNoteForm: React.FC<{ model: ReviewNoteCreateRequest, onCreated: (note: FeedbackComment) => void, onCancel: () => void }> =
	observer(({ model, onCreated, onCancel }) => {
		const [text, setText] = useState<string>("");
		const [state, setState] = useState<{ loading?: boolean, error?: string | null }>({});
		const api = useApi();
		const inputRef = useRef<InputRef>(null);

		useEffect(() => {
			inputRef.current.focus();
		}, [])

		const cancel = () => {
			onCancel();
		}

		const onKeyUpHandler = (e: KeyboardEvent) => {
			if (e.key == "Escape") {
				cancel();
			}
			if (e.key == "Enter" && (e.ctrlKey || e.metaKey)) {
				addNote();
			}
		}

		const addNote = async () => {
			setState({ loading: true });
			const result = await api.adminFeedbackComment.create({
				text: text,
				appointmentFeedbackId: model.appointmentFeedbackId,
				appointmentFeedbackNoteId: model.appointmentFeedbackNoteId,
				appointmentFeedBackField: model.appointmentFeedBackField
			});
			setState({ loading: false });
			if (result.success) {
				setText("");
				onCreated(result.data);
			} else {
				notification.error({ message: "Error", description: result.error })
			}
		}

		return <div className="review-note-create-form">
			<FormItem>
				<TextArea onKeyUp={onKeyUpHandler} autoSize ref={inputRef} placeholder="Enter your note here" value={text} onChange={(e) => setText(e.currentTarget.value)} />
			</FormItem>

			<Space>
				<Button loading={state.loading} onClick={addNote} type="primary">Add (Ctrl + Enter)</Button>
				<Button loading={state.loading} type={"ghost"} onClick={cancel}>Cancel (Esc)</Button>
			</Space>

		</div>
	})