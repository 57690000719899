import React, { useState } from "react";
import { Alert, Breadcrumb, Button, Divider, Drawer, Input, notification, Popconfirm, Result, Space, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { Appointment } from "api/model/Appointments/Appointment";
import { PracticeStore } from "./PracticeStore";
import { Practice } from "api/model/SyncVet/Practice";
import AddPractice from "./AddPractice";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { AppstoreAddOutlined } from '@ant-design/icons';



const PracticeListSimple: React.FC = observer(() => {
	const api = useApi();
	const user = useCurrentUser();
	const store = useLocalObservable<PracticeStore>(() => new PracticeStore(api));
	const [creatingPractice, setCreatingPractice] = React.useState(false);
	const [searchParams] = useSearchParams();
	const [isQuickStart, setIsQuickStart] = useState(searchParams.get("qs"));

	const startAdd = () => {
		setCreatingPractice(true);
	}

	const finishAdd = () => {
		setCreatingPractice(false);
	}

	const onAdded = () => {
		finishAdd();
		store.load();
		if (isQuickStart) {
			setIsQuickStart(null);
		}
	}

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Appointment> | SorterResult<Appointment>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<Practice> = [
		{
			title: 'CreatedDate',
			key: 'CreatedDate',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Practice) => <>
				{moment(x.createdDate).format("LLL")}
			</>
		},
		{
			title: 'Name',
			key: 'name',
			ellipsis: true,
			render: (x: Practice) => <>
				<span style={{ fontSize: 16 }}>
					{x.name}
				</span>
			</>
		},

		{
			title: 'Actions',
			render: (x: Practice) => {
				return <Space>
					{!x.softDeleted ?
						<Popconfirm onConfirm={() => store.delete(x)} title="Delete this practice?">
							<Button icon={<DeleteOutlined />} />
						</Popconfirm>
						:
						<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
					}
				</Space>
			}
		}
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Practices
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>
			<Button onClick={startAdd}>Add new Hospital</Button>
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				store.pagedData?.data?.length == 0 && isQuickStart ?
					<Result
						icon={<AppstoreAddOutlined />}
						status={404}
						title={<p style={{ fontSize: 18, maxWidth: 550, margin: '0 auto' }}>
							You haven't added any Hospital yet.
						</p>
						}
						extra={< Button size="large" type='primary' onClick={startAdd} > Add new Hospital</Button >}
					/>
					:
					<Table
						onChange={handleTableChange}
						loading={store.loading}
						rowKey={x => x.id ? x.id : ""}
						columns={columns}
						dataSource={store.pagedData?.data}
						pagination={{
							hideOnSinglePage: true,
							current: store.pagedData?.page,
							total: store.pagedData?.total,
							pageSize: store.pagedData?.pageSize,
							showSizeChanger: true,
							showTotal: (total: number) => `Total: ${total}`
						}}
					/>
		}

		<Drawer open={creatingPractice} onClose={finishAdd} title="Create new Hospital">
			<AddPractice onSuccess={onAdded} onCancel={finishAdd} />
		</Drawer>
	</>
})

export default PracticeListSimple;
