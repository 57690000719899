import { ConfigProvider, Tabs, ThemeConfig, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Schedule from "./components/Schedule/Schedule";
import Recording from "./components/Recording/Recording";
import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { AppointmentListStore } from "./components/Schedule/AppointmentList/AppointmentListStore";
import { observer, useLocalObservable } from "mobx-react";
import useApi from "misc/hooks/useApi";
import styles from "./VSRecording.module.scss";
import { COLORS } from "./styles/Colors";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { UserRoleEnum } from "api/model/User/UserRole";

type VSRecordingProps = {}

const VSRecording: React.FC<VSRecordingProps> = ({ }) => {
    const api = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const [appointment, setAppointment] = useState<AppointmentClientDto | null>(null);
    const store = useLocalObservable<AppointmentListStore>(() => new AppointmentListStore(api));
    const appWrapperRef = useRef<HTMLDivElement>();
    const user = useCurrentUser();

    const theme = useMemo(() => ({
        components: {
            Typography: {
                colorTextDisabled: COLORS.disabledBG
            },
            Button: {
                defaultBg: COLORS.buttonBg,
                colorText: COLORS.white,
                colorBorder: COLORS.buttonBg,
                defaultHoverBorderColor: COLORS.buttonBgHover,
                defaultHoverBg: COLORS.buttonBgHover,
                defaultHoverColor: COLORS.white,
                colorTextDisabled: COLORS.lightGrey,
                borderColorDisabled: COLORS.lightGrey,
                controlItemBgActiveDisabled: COLORS.lightGrey,
                colorLink: COLORS.orange,
                colorLinkHover: COLORS.buttonBgHover,

            },
            Radio: {
                buttonSolidCheckedBg: COLORS.buttonBg,
                buttonSolidCheckedHoverBg: COLORS.buttonBgHover,
                buttonSolidCheckedColor: COLORS.white,
                buttonBg: COLORS.grey,
                buttonColor: COLORS.white,
                colorBorder: COLORS.grey,
                colorPrimaryActive: COLORS.buttonBg,
                colorInfoTextHover: COLORS.white,
                colorPrimary: COLORS.white,
                colorTextDisabled: COLORS.lightGrey,
                buttonCheckedColorDisabled: COLORS.lightGrey,
                buttonCheckedBgDisabled: COLORS.grey,
            },
            Select: {
                colorTextDisabled: COLORS.lightGrey,
            },
            Progress: {
                circleIconFontSize: '12px',
                defaultColor: COLORS.buttonBg,
                colorSuccess: COLORS.buttonBg
            }
        }
    } as ThemeConfig), []);

    const goToRecording = useCallback((a: AppointmentClientDto) => {
        navigate(`/rec/appointment/${a?.id}`);
        setAppointment(a);
    }, []);

    const activeTab = useMemo(() => {
        if (location.pathname.endsWith('schedule')) return 'schedule';
        if (location.pathname.includes('appointment')) return 'recording';
        return 'schedule'; // Default to schedule if path is unknown
    }, [location.pathname]);

    useEffect(() => {
        if (activeTab === 'schedule') {
            setAppointment(null);
        }
    }, [activeTab]);

    const handleTabChange = useCallback((key: string) => {
        navigate(`/rec/${key}`);
    }, []);

    const showApp = useMemo(() => {
        if (user.isInRole([UserRoleEnum.VetTech]) || user.isInRole([UserRoleEnum.AccountVetTech])) {
            if (user.isSAASUser)
                return true;
            else
                return user.practiceIds.length > 0 || user.doctorIds?.length > 0;
        } else
            return user.doctorIds?.length > 0;
    }, [user, user.doctorIds, user.practiceIds]);

    return <ConfigProvider theme={theme} getPopupContainer={() => appWrapperRef.current}>
        <div className={styles.app_wrapper} ref={appWrapperRef}>
            {!showApp
                ? <Typography.Title level={3} className="color-white text-center">
                    {!user.isSAASUser && user.practiceIds.length === 0
                        ? "There are no practices assigned to your account. Please contact your administrator."
                        : "You don't have any doctor assigned to your account. Please contact support"}
                </Typography.Title>
                : <Tabs activeKey={activeTab} tabBarStyle={{ display: 'none' }} defaultActiveKey='schedule' centered animated onTabClick={handleTabChange}>
                    <Tabs.TabPane key='schedule' tab='Schedule'>
                        <Schedule store={store} onAppointmentClick={x => goToRecording(x)} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='recording' tab='Recording' disabled={activeTab !== 'recording'}>
                        <Recording key={id} appointmentId={Number(id)}
                            listStore={store}
                            onDeleteOrBack={async () => {
                                navigate('/rec/schedule');
                                // hack to rerender list properly
                                setTimeout(async () => {
                                    await store.load();
                                }, 100);
                            }} />
                    </Tabs.TabPane>
                </Tabs>}

        </div>
    </ConfigProvider>;
}

export default observer(VSRecording);
