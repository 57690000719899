import React, { useCallback, useContext, useEffect, useState } from 'react';
import { List, Button, Drawer, Tag } from 'antd';
import styles from './AppointmentList.module.scss';
import moment from 'moment';
import { PlusCircleOutlined, UndoOutlined } from '@ant-design/icons';
import useDrawer from 'misc/hooks/useDrawer';
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import { AppointmentCreateDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto';
import { AppointmentClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentClientDto';
import { AppointmentListStore } from './AppointmentListStore';
import { observer } from 'mobx-react';
import { getAppointmentFeedbackStatusLabel } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import LoadingOverlay from 'app/components/LoadingOverlay/LoadingOverlay';
import { MainContext } from 'misc/context/MainContext';

type AppointmentListProps = {
    loading: boolean;
    store: AppointmentListStore;
    currentDate: moment.Moment;
    onAppointmentClick: (appointment: AppointmentClientDto) => void;
}

const AppointmentList: React.FC<AppointmentListProps> = observer(({ store, loading, currentDate, onAppointmentClick }) => {
    const appointmentDrawer = useDrawer<AppointmentCreateDto>();
    const [appointments, setAppointments] = useState<AppointmentClientDto[]>([]);

    const ctx = useContext(MainContext);

    useEffect(() => {
        ctx.currentUser.init(ctx.api);
    }, [appointments]);

    useEffect(() => {
        setAppointments(store.pagedData?.data);
    }, [store.pagedData?.data]);

    const createAppointment = useCallback(async (a: AppointmentCreateDto) => {
        const res = await store.createAppointment(a);
        appointmentDrawer.close();
        onAppointmentClick(res as AppointmentClientDto);
    }, []);

    const refresh = useCallback(async () => {
        await store.load()
    }, []);

    return <>
        <div className={styles.appointmentList}>
            <div className='flex align-center justify-between'>
                {appointments?.length != 0 && <Button onClick={(() => appointmentDrawer.open())} disabled={store.loading} className={styles.refreshButton} icon={<PlusCircleOutlined />}>New Appointment</Button>}
                <Button onClick={refresh} loading={store.loading} className={styles.refreshButton} icon={<UndoOutlined />} style={{ marginLeft: 'auto' }}>Refresh</Button>
            </div>
            <LoadingOverlay spinning={store.loading}>
                {appointments?.length > 0
                    ? <>
                        <List
                            dataSource={appointments}
                            renderItem={appointment => (
                                <List.Item key={appointment.id}>
                                    <OneAppointment appointment={appointment} onAppointmentClick={() => onAppointmentClick(appointment)} />
                                </List.Item>
                            )}
                        />
                    </>
                    : <div className='flex flex-col align-center justify-center fs-22 fw-500 mt-30 color-white'>
                        <span>No appointments found</span>
                        <span className='fs-16'>To start recording you need to have an appointment.</span>
                    </div>}
                {appointments?.length == 0 && <div>
                    <Button onClick={(() => appointmentDrawer.open())} className={styles.createBtn_large} icon={<PlusCircleOutlined />}>New Appointment</Button>
                </div>}
            </LoadingOverlay>
        </div>

        <Drawer open={appointmentDrawer.visible}
            destroyOnClose
            title='New Appointment'
            onClose={appointmentDrawer.close}
            width={600}>
            <AppointmentForm appointment={appointmentDrawer.data}
                currentDate={currentDate}
                onCancel={appointmentDrawer.close}
                onDone={(a) => createAppointment(a)} />
        </Drawer>
    </>;
});


type OneAppointmentProps = {
    appointment: AppointmentClientDto;
    onAppointmentClick: () => void;
    onDone?: () => void;
}

const OneAppointment: React.FC<OneAppointmentProps> = ({ appointment, onAppointmentClick, onDone }) => {

    const statusData = getAppointmentFeedbackStatusLabel(appointment.feedback?.status);

    return <div className={styles.appointment_wrapper} onClick={() => onAppointmentClick()}>
        <div className='flex flex-col fw-500'>
            <span className={styles.appointment_patientName}>
                {appointment.patientName}
            </span>
            <span className={styles.appointment_clientName}>
                {appointment.clientName}
            </span>
        </div>
        <span className='fs-20 fw-500'>
            <Tag color={statusData.color}>{statusData.label}</Tag>
            <span>{moment(appointment.date).format('hh:mmA')}</span>
        </span>
    </div>;
};

export default AppointmentList;