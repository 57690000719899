import ApiClient from "../../base/ApiClient";
import { UserUpdateProfileRequest } from "./Model/UserUpdateProfileRequest";

export default class UserProfileApi {
    constructor(private api: ApiClient) { }
    private baseUrl: string = "/profile";

    async updateProfile(req: UserUpdateProfileRequest) {
        const register = await this.api.client.post<boolean>(`${this.baseUrl}/update`, { ...req });
        return register.data;
    }

}