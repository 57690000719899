import { Alert, Button, notification, Popconfirm, Skeleton } from "antd";
import { AdminFeedbackDto, ProcessingStatus } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { observer } from "mobx-react";
import React, { useCallback, useMemo, useRef } from "react";
import useApi from "misc/hooks/useApi";
import { extractTextWithLineBreaks } from "misc/helpers/extractText";
import { OutputType } from "api/model/Appointments/AppointmentFeedback";
import { UndoOutlined } from "@ant-design/icons";

const VisitSummaryTab: React.FC<{ feedback: AdminFeedbackDto }> = observer(({ feedback }) => {
    const outputRef = useRef<HTMLDivElement>(null);
    const api = useApi();
    const isGenerating = useMemo(() => feedback.feedbackOutput.summaryStatus == ProcessingStatus.Processing
        || feedback.feedbackOutput.summaryStatus == ProcessingStatus.Pending, [feedback.feedbackOutput.summaryStatus]);

    const generateSummary = useCallback(async () => {
        if (!feedback.id) return;
        try {
            await api.adminFeedback.generateOutput(feedback.id, OutputType.VisitSummary);
            notification.warning({ message: 'Visit summary is generating, wait please', duration: 4 });
        } catch (error) {
            notification.error({ message: 'Failed to regenerate visit summary', duration: 2 });
        }

    }, [feedback.id]);

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const textToCopy = extractTextWithLineBreaks(tempDiv);

        await navigator.clipboard.writeText(textToCopy);
        notification.success({ message: 'Output is copied to clipboard' });

    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);

        notification.success({ message: 'Output is copied to clipboard' });
    }, [outputRef.current]);

    const applySimpleMarkdown = (text: string) => {
        // Split by newlines to create paragraphs
        if (!text) text = 'Summary is not available';
        const paragraphs = text.split(/\n+/).filter(paragraph => paragraph.length > 0);

        return paragraphs.map((paragraph, index) => {
            // Split by markdown syntax within each paragraph
            const parts = paragraph.split(/(\*\*.*?\*\*|__.*?__)/g);
            const paragraphContent = parts.map((part, partIndex) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return <b key={partIndex} style={{ marginTop: '0.5em' }}>{part.slice(2, -2)}</b>;
                }
                if (part.startsWith('__') && part.endsWith('__')) {
                    return <u key={partIndex}>{part.slice(2, -2)}</u>;
                }
                return part;
            });

            // Wrap each paragraph in a <div> tag without margin or padding
            return <div key={index}>{paragraphContent}</div>;
        });
    };


    if (feedback.feedbackOutput.summaryStatus == ProcessingStatus.Done && !feedback.feedbackOutput.visitSummary)
        return <div className='flex flex-col align-center gap-10'>
            <Alert message='Visit Summary is empty, check the audios and fields and press "Regenerate" button' type='warning' />
            <Button type='primary' icon={<UndoOutlined />}
                disabled={isGenerating}
                loading={isGenerating}
                onClick={generateSummary}>
                Regenerate
            </Button>
        </div>;

    return <>
        <div className='flex align-center justify-between mb-20'>
            <div className='flex align-center gap-10'>
                <Button onClick={() => copyHtmlToClipboard(feedback.feedbackOutput.visitSummary || '')} disabled={isGenerating}>Copy w/ formatting</Button>
                <Button onClick={() => copyTextToClipboard(feedback.feedbackOutput.visitSummary || '')} disabled={isGenerating}>Copy as text</Button>
            </div>
            <Popconfirm
                title="Are you sure to regenerate the email template? All edits will be lost."
                onConfirm={generateSummary}
                okText="Yes"
                cancelText="No">
                <Button type='link' icon={<UndoOutlined />}
                    disabled={isGenerating}
                    loading={isGenerating}>
                    Regenerate
                </Button>
            </Popconfirm>
        </div>

        <div ref={outputRef}>
            {isGenerating
                ? <Skeleton active />
                : applySimpleMarkdown(feedback.feedbackOutput.visitSummary)}
        </div>
    </>;
});

export default VisitSummaryTab;
