import ApiClient from "api/base/ApiClient";
import { Template } from "../../model/Template/Template";

export class TemplatesApi {
    constructor(private api: ApiClient) { }
    private baseUrl: string = "/template";

    async getAllTemplates() {
        const req = await this.api.client.get<Template[]>(`${this.baseUrl}/all`);
        return req.data;
    }

    async loadTemplate(id: number) {
        const req = await this.api.client.get<Template>(`${this.baseUrl}/${id}`);
        return req.data;
    }

    async setFavorite(id: number) {
        const req = await this.api.client.get<Template[]>(`${this.baseUrl}/fav/${id}`);
        return req.data;
    }

}