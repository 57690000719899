import { Alert, Button, Form, Popconfirm, notification, Input, Select, Checkbox } from "antd";
import useApi from "misc/hooks/useApi";
import useDevice from "misc/hooks/useDevice";
import { observer, useLocalObservable } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PracticeStore } from "routes/practices/PracticeStore";
import styles from './QuickStart.module.scss';
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { DoctorStore } from "routes/doctor/DoctorStore";
import { Doctor } from "api/model/Offices/Doctor";
import useCurrentUser from "misc/hooks/useCurrentUser";
import combineClasses from "misc/helpers/combineClasses";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LanguageEnum, LanguageEnumList } from "api/model/User/Language";
import PhoneInput from "app/components/PhoneInput/PhoneInput";
import { DoctorUpdateRequest } from "api/routes/Doctors/Model/DoctorUpdateRequest";

const DoctorsStep: React.FC<{ onBack: () => void; onNext: () => void }> = observer(({ onBack, onNext }) => {
    const api = useApi();
    const store = useLocalObservable<DoctorStore>(() => new DoctorStore(api, null, false));
    const [doctors, setDoctors] = useState<Partial<Doctor>[]>([]);
    const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });
    const [form] = Form.useForm();
    const { device } = useDevice();
    const user = useCurrentUser();
    const practiceStore = useLocalObservable<PracticeStore>(() => new PracticeStore(api, null, false));
    const [isAccountOwner, setIsAccountOwner] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        initialLoad();
    }, []);

    const initialLoad = async () => {
        practiceStore.setFilter({
            isRemoved: false,
            sortDirection: 'asc'
        })
        store.setFilter({
            isRemoved: false,
            sortDirection: 'asc'
        });

        await practiceStore.load();
        await store.load();
        setLoaded(true);
    }

    useEffect(() => {
        if (!store.pagedData) return;
        setDoctors(store.pagedData.data);
    }, [store.pagedData?.data]);

    const alreadyHaveOwner = useMemo(() => doctors.some(x => x.isAccountOwner == true), [doctors]);

    // set isOwner value to false if owner exists
    useEffect(() => {
        if (alreadyHaveOwner) {
            setIsAccountOwner(false);
            form.setFieldsValue({ isAccountOwner: false });
        }
    }, [alreadyHaveOwner]);

    const submit = useCallback(async (values: Partial<DoctorUpdateRequest>) => {
        setState({ ...state, loading: true, error: "" })
        if (isAccountOwner == true) {
            values.email = user.email;
            values.firstName = user.firstName;
            values.lastName = user.lastName;
            values.isAccountOwner = true;
        }
        values.practiceId = practiceStore.pagedData?.data[0]?.id;

        try {
            const res = await api.adminDoctor.createDoctorWithUser(values as DoctorUpdateRequest);
            if (res.success == true) {
                notification.success({ message: "Created" });
                setState({ ...state, error: null, loading: false });
                store.load();
                form.resetFields();
            } else {
                setState({ ...state, loading: false, error: res.error || "Error occurred" })
            }
            return true;
        } catch (e: any) {
            notification.error({ message: e.error });
            setState({ ...state, error: e.error, loading: false });
            return false;
        }
    }, [isAccountOwner]);

    const checkIfFormNeedsValidation = useCallback((values: any) => {

        const allEmpty = Object.keys(values)
            .filter(x => x !== 'isAccountOwner' && x !== 'practiceId' && x !== 'language')
            .every(x => values[x] === undefined || values[x] === '');
        return !allEmpty;
    }, [form]);

    const onNextClick = useCallback(() => {
        const values = form.getFieldsValue();
        values.isAccountOwner = isAccountOwner;
        const needsValidation = checkIfFormNeedsValidation(values);
        const hasDoctors = doctors.length > 0;

        if (!hasDoctors && needsValidation) {
            notification.error({ message: 'Please add at least one doctor.' });
            return;
        }

        if (hasDoctors && !needsValidation) {
            onNext();
            return;
        }

        if (needsValidation || (!needsValidation && !hasDoctors)) {
            form.validateFields()
                .then(validatedValues => {
                    submit(validatedValues);
                    onNext();
                })
                .catch(errorInfo => {
                    console.log('Validation failed:', errorInfo);
                });
        } else {
            onNext();
        }
    }, [doctors, submit, onNext, checkIfFormNeedsValidation, isAccountOwner]);

    const deleteDoctor = useCallback(async (doc: Doctor) => {
        await store.delete(doc);
        store.load();
    }, []);

    if (!loaded) return null;

    return <div className={combineClasses(styles.initial_setup_form, "fadeInLeft")}>
        {state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}
        <div className='mb-20'>
            {doctors.map(x => <div key={x.id} className={styles.saved_item}>
                <div className='flex align-center gap-10'>
                    <span>{x.firstName} {x.lastName}</span>
                    {x.isAccountOwner && <span className="secondary-text">(Account Owner)</span>}
                </div>
                <Popconfirm title={'Are you sure you want to delete this doctor?'} placement={device.isMobile ? 'bottom' : 'right'} onConfirm={() => deleteDoctor(x as Doctor)}>
                    <Button icon={<DeleteOutlined />} />
                </Popconfirm>
            </div>)}
        </div>


        <Form size='large' form={form} layout={device.isMobile ? 'vertical' : 'horizontal'} onFinish={submit} labelCol={{ span: 6 }} initialValues={{
            language: LanguageEnum.English
        }}>
            {/* <Form.Item required name={'practiceId'} label={'Hospital'} rules={[{ required: true, message: 'Select hospital' }]}>
                <Select placeholder={'Select hospital'} open={false} showArrow={false} loading={practiceStore.loading} onDropdownVisibleChange={(x) => {
                    //todo: fix this hack
                    if (x && practiceStore.pagedData?.data.length == 0)
                        practiceStore.load();
                }} style={{ cursor: 'default' }}>
                    {practiceStore.pagedData?.data.map((x, i) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                </Select>
            </Form.Item> */}

            {!alreadyHaveOwner && <Form.Item name={'isAccountOwner'} valuePropName='checked' label={<>{/* dirty hack to align checkbox */}</>} colon={false}>
                <Checkbox checked={isAccountOwner} onChange={e => setIsAccountOwner(e.target.checked)} disabled={alreadyHaveOwner}>Check if you are a doctor using the service for this hospital</Checkbox>
            </Form.Item>}

            {(!isAccountOwner || alreadyHaveOwner) && <>
                <Form.Item required name={'firstName'} label={'First Name'} rules={[{ required: true, message: 'Enter first name' }]}>
                    <Input placeholder={'Enter first name'} />
                </Form.Item>
                <Form.Item required name={'lastName'} label={'Last Name'} rules={[{ required: true, message: 'Enter last name' }]}>
                    <Input placeholder={'Enter last name'} />
                </Form.Item>

                <Form.Item required name={'email'} label={'Email'} rules={[{ required: true, type: 'email', message: 'Enter valid email' }]}>
                    <Input placeholder={'Enter email'} type="new-email" />
                </Form.Item>
                <Form.Item required name={'password'} label={'Password'} rules={[{ required: true, message: 'Enter password' }]}>
                    <Input.Password autoComplete="new-password" placeholder={'Enter password'} />
                </Form.Item>
                <Form.Item required label={'Confirm password'}
                    name={'passwordConfirm'}
                    dependencies={['password']}
                    rules={[{ required: true, message: 'Please confirm your password' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords don’t match'));
                        },
                    })
                    ]}>
                    <Input.Password autoComplete="new-password" placeholder="Confirm password" />
                </Form.Item>

                <Form.Item name={'phone'} label={'Phone'} rules={[
                    { required: false },
                    {
                        validator: async (_, value) => {
                            if (value && !isValidPhoneNumber(value)) {
                                return Promise.reject(new Error('Enter valid phone number'));
                            }
                            return Promise.resolve();
                        }
                    }
                ]}>
                    <PhoneInput placeholder={'Enter phone'} country='us' size='large' />
                </Form.Item>
                <Form.Item name={'language'} label={'Language'}>
                    <Select placeholder="Select language" defaultValue={LanguageEnum.English}>
                        {LanguageEnumList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
                    </Select>
                </Form.Item>
            </>}

            {/* <footer className={styles.form_footer}> */}
            <footer className={'flex align-center justify-end'}>
                {/* <Button size='large' onClick={() => onBack()}>Back</Button> */}
                <div className='flex align-center gap-20'>
                    <Button size='large' icon={<PlusCircleOutlined />} loading={store.loading} htmlType={'submit'}>Add Doctor</Button>
                    <Button size='large' type='primary' onClick={() => onNextClick()}>Next</Button>
                </div>
            </footer>
        </Form>

    </div>
});

export default DoctorsStep;