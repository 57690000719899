import React from 'react';
import CurrentUser from '../../model/CurrentUser';
import GeneralState from './GeneralState';
import ApiClient from 'api/base/ApiClient';
import VSHubClient from 'misc/vshub/vshub';

export type MainContextType = {
	generalState: GeneralState,
	api: ApiClient,
	vsHub: VSHubClient,
	currentUser: CurrentUser
};

//@ts-ignore
export const MainContext = React.createContext<MainContextType>({});
