
export interface BillingFilter {
	practiceId: number | null;
	dateTo: Date | null;
	dateFrom: Date | null;
	period: BillingGroupingPeriod;
}

export enum BillingGroupingPeriod {
	Day,
	Week,
	Month,
	Year
}