import { Button, Input, notification, Space } from "antd";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import { useEffect } from "react";
import React from "react";
import { VoiceWordStore } from "./VoiceWordStore";
import { VoiceWord } from "api/model/VoiceWord/VoiceWord";
import Modal from "antd/lib/modal/Modal";
import FormItem from "antd/es/form/FormItem";
import useGeneralState from "misc/hooks/useGeneralState";
import { start } from "repl";


const VoiceWordAdd: React.FC<{ creating: boolean, onClose: () => void, store: VoiceWordStore }> =
	observer(({ creating, onClose, store }) => {
		const api = useApi();
		const [wordText, setWordText] = React.useState<string>("");

		useEffect(() => {
			setWordText("");
		}, [creating])

		const createWords = async () => {
			await store.add([{ word: wordText, id: 0 }]);
			onClose();
		}

		const changeWord = (text: string) => {
			setWordText(text);
		}

		return <Modal destroyOnClose zIndex={2000} style={{ zIndex: 1000 }} open={creating} title="Add Words" onCancel={onClose}
			okButtonProps={{ onClick: createWords, loading: store.loading }} okText="Create">
			<p>Add words that possible could be in recording. So AI will know it.</p>
			<FormItem label="Word">
				<Input value={wordText} onChange={(x) => changeWord(x.currentTarget.value)} placeholder="Possible word"></Input>
			</FormItem>
		</Modal>
	})


export default VoiceWordAdd;




