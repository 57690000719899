import { observer } from 'mobx-react-lite';
import useGeneralState from 'misc/hooks/useGeneralState';
import { Badge, Menu, MenuProps, Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import logo from "../../../assets/logo.png";
import { UserRoleEnum } from 'api/model/User/UserRole';
import { AudioOutlined, CalendarOutlined, FileOutlined, QuestionCircleOutlined, AudioFilled, BankOutlined, UserOutlined, TeamOutlined, LogoutOutlined, DatabaseOutlined, DollarOutlined, ClusterOutlined, MenuFoldOutlined, MenuUnfoldOutlined, DashboardOutlined } from '@ant-design/icons';
import { COLORS } from 'vs-recording/styles/Colors';
import UserReportList from 'routes/analytics/UserReportList';

type MenuItem = Required<MenuProps>['items'][number];

const AdminMenu: React.FC = () => {
	const gs = useGeneralState();
	const loc = useLocation();
	const user = useCurrentUser();
	const nav = useNavigate();
	const [collapsed, setCollapsed] = useState(false);

	const handleCollapse = useCallback(() => {
		setCollapsed(!collapsed);
		gs.toggleMenuCollapsed();
	}, [collapsed]);

	return <div className='admin-menu' data-collapsed={collapsed}>
		<div className='admin-menu-header'>
			<img src={logo} width={125} />
			<div className='admin-menu-collapse-btn color-white' onClick={handleCollapse}>
				{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
			</div>
		</div>

		<Menu selectedKeys={[loc.pathname]} inlineCollapsed={collapsed}>
			<Menu.Item key={"/rec"} className='menu-item stress flex align-center' icon={<AudioFilled style={{ color: COLORS.ligthRed }} />}>
				<Link to='/rec'>
					Start Recording
				</Link>
			</Menu.Item>




			<Menu.Item key={"/recordings"} className='menu-item flex align-center' icon={<AudioOutlined />}>
				<Link to='/recordings' className='flex align-center justify-between'>
					<span>Transcriptions</span>
					{/* <div className='flex ml-5'>
						{user.isInRole([UserRoleEnum.Supervisor]) && user.unreadChats.length > 0 &&
							<Tooltip title="Open Chats">
								<Badge count={user.unreadChats.length} />
							</Tooltip>
						}
						{user.recordingsTotal > 0 &&
							<Tooltip title="Total">
								<Badge count={user.recordingsTotal} />
							</Tooltip>
						}
						{user.recordingsVet > 0 &&
							<Tooltip title="Vet">
								<Badge count={user.recordingsVet} color={'#ff9800'} />
							</Tooltip>
						}
						{user.recordingsSupervisor > 0 &&
							<Tooltip title="Supervisor">
								<Badge count={user.recordingsSupervisor} color={'#d4380d'} />
							</Tooltip>
						}
					</div> */}
				</Link>
			</Menu.Item>

			{user.isInRole([UserRoleEnum.SuperAdmin]) &&
				<Menu.Item key={"/appointments"} className='menu-item' icon={<CalendarOutlined />}>
					<Link to='/appointments'>
						Appointments
					</Link>
				</Menu.Item>
			}

			{user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.PracticeSuperUser, UserRoleEnum.AccountOwner, UserRoleEnum.AccountManager]) &&
				<Menu.Item key={"/templates"} className='menu-item' icon={<FileOutlined />}>
					<Link to='/templates'>
						Templates
					</Link>
				</Menu.Item>
			}

			<Menu.Divider />

			{user.currentAccountId && user.isInRole([UserRoleEnum.AccountOwner]) &&
				<>
					<Menu.Item key={"/practices"} className='menu-item' icon={<BankOutlined />}>
						<Link to='/practices'>
							Hospitals
						</Link>
					</Menu.Item>
					<Menu.Item key={"/doctors"} className='menu-item' icon={<UserOutlined />}>
						<Link to='/doctors'>
							Doctors
						</Link>
					</Menu.Item>
					<Menu.Item key={"/users"} className='menu-item' icon={<TeamOutlined />}>
						<Link to='/users'>
							Users
						</Link>
					</Menu.Item>
					{/* <Menu.Item key={"/account-billing"} className='menu-item' icon={<DollarOutlined />}>
              <Link to='/account-billing'>
                Billing
              </Link>
            </Menu.Item> */}
				</>
			}

			{user.isInRole([UserRoleEnum.SuperAdmin]) &&
				<>
					<Menu.Divider title='Super Admin Area' />
					<Menu.Item key={"/users"} className='menu-item' icon={<TeamOutlined />}>
						<Link to='/users'>
							Users
						</Link>
					</Menu.Item>
					<Menu.Item key={"/practices"} className='menu-item' icon={<BankOutlined />}>
						<Link to='/practices'>
							Hospitals
						</Link>
					</Menu.Item>
					<Menu.Item key={"/offices"} className='menu-item' icon={<ClusterOutlined />}>
						<Link to='/offices'>
							Offices
						</Link>
					</Menu.Item>
					<Menu.Item key={"/doctors"} className='menu-item' icon={<UserOutlined />}>
						<Link to='/doctors'>
							Doctors
						</Link>
					</Menu.Item>
					<Menu.Item key={"/clients"} className='menu-item' icon={<TeamOutlined />}>
						<Link to='/clients'>
							Clients
						</Link>
					</Menu.Item>
					<Menu.Item key={"/patients"} className='menu-item' icon={<DatabaseOutlined />}>
						<Link to='/patients'>
							Patients
						</Link>
					</Menu.Item>
					<Menu.Item key={"/voice_words"} className='menu-item' icon={<AudioOutlined />}>
						<Link to='/voice_words'>
							Words Dict
						</Link>
					</Menu.Item>
					<Menu.Item key={"/billing"} className='menu-item' icon={<DollarOutlined />}>
						<Link to='/billing'>
							Billing
						</Link>
					</Menu.Item>

					<Menu.SubMenu key="/analytics" className='menu-item' icon={<DashboardOutlined />} title="Analytics">
						<Menu.Item key="/analytics/users">
							<Link to='/analytics/users'>Users</Link>
						</Menu.Item>
						<Menu.Item key="/analytics/totals">
							<Link to='/analytics/totals'>Totals</Link>
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.Item key={"/accounts"} className='menu-item' icon={<ClusterOutlined />}>
						<Link to='/accounts'>
							Accounts
						</Link>
					</Menu.Item>
				</>
			}
			<Menu.Item key={"/help-center"} className='menu-item' icon={<QuestionCircleOutlined />}>
				<Link to='/help-center'>
					Help Center
				</Link>
			</Menu.Item>

			<Menu.Divider />

			<Menu.Item key={"/auth/logout"} className='menu-item' icon={<LogoutOutlined />}>
				<Link to='/auth/logout'>
					Logout
				</Link>
			</Menu.Item>
		</Menu>
	</div>;
}

export default observer(AdminMenu);
