// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JJ2tsVDIX_qrS268R7Sn{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;height:100px;border-radius:12px;cursor:pointer;transition:background-color .3s}.JJ2tsVDIX_qrS268R7Sn[data-recording=true]{background-color:#f44336;color:#fff}.JJ2tsVDIX_qrS268R7Sn[data-recording=true]:hover{background-color:#c5342a !important}", "",{"version":3,"sources":["webpack://./src/vs-recording/components/Recording/components/AudioRecorder/AudioRecorder.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,+BAAA,CAEA,2CACI,wBAAA,CACA,UAAA,CAEA,iDACI,mCAAA","sourcesContent":[".recorder_button {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 100px;\r\n    border-radius: 12px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n\r\n    &[data-recording=\"true\"] {\r\n        background-color: #f44336;\r\n        color: #fff;\r\n\r\n        &:hover {\r\n            background-color: #c5342a !important;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recorder_button": "JJ2tsVDIX_qrS268R7Sn"
};
export default ___CSS_LOADER_EXPORT___;
