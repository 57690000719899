import { Select } from "antd";
import { PracticeSimple } from "api/model/SyncVet/PracticeSimple";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useEffect, useState } from "react"

const PracticeSelect: React.FC<{ value?: number, onChange: (x: any) => void, forceSelectOne?: boolean }> =
	({ value, onChange, forceSelectOne }) => {
		const api = useApi();
		const [items, setItems] = useState<PracticeSimple[]>([]);
		const [search, setSearch] = useState("");
		const [initialLoaded, setInitialLoaded] = useState(false);
		const [loading, setLoading] = useState(false);
		const [selected, setSelected] = useState<number>(value);

		const user = useCurrentUser();

		if (user.isRestrictedUser) return null;


		useEffect(() => {
			load()
		}, [search])

		useEffect(() => {
			if (forceSelectOne && !value && items.length > 0 && !initialLoaded) {
				setSelected(items[0].id);
				onChange(items[0].id);
			}
		}, [items])

		const handleChange = (value: number) => {
			setSelected(value);
			onChange(value);
		}

		const load = async () => {
			setLoading(true);
			var res = await api.adminPractice.listSimple({
				page: 1,
				pageSize: 20,
				search: search,
				isRemoved: false,
				sortField: "Name",
				sortDirection: "asc",
				includeIds: !initialLoaded && value ? [value] : []
			})
			setInitialLoaded(true);
			setItems(res.data);
			setLoading(false);
		}

		return <Select loading={loading} placeholder="Hospital" style={{ width: "225px" }} allowClear
			value={selected} onDropdownVisibleChange={(v) => v && load()} onChange={handleChange} onSearch={setSearch} showSearch filterOption={false}>
			{items.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
		</Select>
	}
export default PracticeSelect;