import { AppointmentFeedbackField } from "../Appointments/enums/AppointmentFeedBackFields";
import { BasicEntity } from "../BasicEntity";

export enum AppointmentFeedbackAudioStatus {
	Recorded = 0,
    Uploading = 1,
    ReadyToTranscribe = 2,
    Transcribing = 3,
    TranscribingError = 4,
    Transcribed = 5,
    Appended = 6,
    Uploaded = 7,
    Chunking = 8,
    Chunked = 9,
    ChunkingError = 10,
    GroomingError = 11,
}

export const AppointmentFeedbackAudioStatusList = [
	{ value: AppointmentFeedbackAudioStatus.Recorded, label: "Recorded", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.Uploading, label: "Uploading", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.ReadyToTranscribe, label: "Ready to Transcribe", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.Transcribing, label: "Transcribing", color: "orange" },
	{ value: AppointmentFeedbackAudioStatus.TranscribingError, label: "Transcribing Error", color: "red" },
	{ value: AppointmentFeedbackAudioStatus.Transcribed, label: "Transcribed", color: "green" },
	{ value: AppointmentFeedbackAudioStatus.Appended, label: "Appended", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.Uploaded, label: "Uploaded", color: "green" },
	{ value: AppointmentFeedbackAudioStatus.Chunking, label: "Chunking", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.Chunked, label: "Chunked", color: "grey" },
	{ value: AppointmentFeedbackAudioStatus.ChunkingError, label: "Chunking Error", color: "red" },
	{ value: AppointmentFeedbackAudioStatus.GroomingError, label: "Grooming Error", color: "red" },
]


export interface AppointmentFeedbackAudio extends BasicEntity {
	text?: string | null;
	uri: string | null;
	status?: AppointmentFeedbackAudioStatus | null;
	appointmentFeedBackField?: AppointmentFeedbackField | null;
	appointmentFeedBackId?: number | null;
	appointmentFeedBackNoteId?: number | null;
	duration?: number | null;
	isSaving?: boolean;
	uploaded?: boolean;
	uploadAttempt: number;
	isInProgress?: boolean;

	hasError?: boolean;
}