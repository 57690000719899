import { action, makeAutoObservable } from "mobx";
import React from "react";

export default class GeneralState {

	isDictionaryCreating = false;
	isMenuCollapsed = false;

	constructor() {
		makeAutoObservable(this);
	}

	@action
	startCreateDictionary() {
		this.isDictionaryCreating = true;
	}

	@action
	stopCreateDictionary() {
		this.isDictionaryCreating = false;
	}

	@action
	toggleMenuCollapsed() {
		this.isMenuCollapsed = !this.isMenuCollapsed;
	}

}