import { BasicEntity } from "../BasicEntity";
import { Template } from "./Template";

export interface TemplateField extends BasicEntity {
    idTemplate?: number;
    label: string;
    defaultValue?: string;
    position: number;
    isNew?: boolean;
    type: TemplateFieldType;
    isBold?: boolean;
    isUppercase?: boolean;
}

export enum TemplateFieldType {
    Simple = 0,
    Separator = 1
}