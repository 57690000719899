import { Button, Form, Input } from 'antd'
import { AdminFeedbackDto } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto'
import React, { useState } from 'react'

type ClientNameFormProps = {
    feedback: AdminFeedbackDto,
    onSubmit: (firstName: string, lastName?: string) => void;
    onCancel?: () => void;
}


const ClientNameForm: React.FC<ClientNameFormProps> = ({ feedback, onSubmit, onCancel }) => {
    const [firstName, setFirstName] = useState(feedback.appointment?.clientName?.split(" ")[0] ?? '');
    const [lastName, setLastName] = useState(feedback.appointment?.clientName?.split(" ")[1] ?? '');
    const [form] = Form.useForm();

    return <>
        <Form form={form} layout='horizontal' initialValues={{ firstName, lastName }} onFinish={() => onSubmit(firstName, lastName)}>
            <Form.Item name='firstName' required rules={[{ required: true, message: 'At least first name is required' }]}>
                <Input placeholder='First name' value={firstName} onChange={e => setFirstName(e.target.value)} />
            </Form.Item>
            <Form.Item name='lastName'>
                <Input placeholder='Last name' value={lastName} onChange={e => setLastName(e.target.value)} />
            </Form.Item>
        </Form>
        <div className='flex gap-50'>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type='primary' onClick={() => form.submit()}>Save and regenerate</Button>
        </div>
    </>;
}

export default ClientNameForm;
