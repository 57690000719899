import { Divider, Select } from "antd";
import { Client } from "api/model/Clients/Client";
import useApi from "misc/hooks/useApi";
import React, { useEffect, useState } from "react"

const ClientSelect: React.FC<{ value?: number, onChange: (x: any) => void }> = ({ value, onChange }) => {
	const api = useApi();
	const [items, setItems] = useState<Client[]>([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		load()
	}, [search])

	const load = async () => {
		var res = await api.adminClient.list({
			page: 1,
			pageSize: 20,
			search: search,
			sortField: "lastName",
			sortDirection: "asc",
		})
		setItems(res.data);
	}

	return <Select style={{ minWidth: 150 }} value={value} onChange={(x) => onChange(x)} onSearch={setSearch} allowClear showSearch placeholder="Client" filterOption={false}>
		{items.map(x => <Select.Option key={x.id} value={x.id}>{x.firstName} {x.lastName}</Select.Option>)}
	</Select>
}
export default ClientSelect;