import { Divider, Select } from "antd";
import { Practice } from "api/model/SyncVet/Practice";
import useApi from "misc/hooks/useApi";
import React, { useEffect, useState } from "react"

const PracticeMultiSelect: React.FC<{ value?: number[], onChange: (x: any) => void }> = ({ value, onChange }) => {
	const api = useApi();
	const [items, setItems] = useState<Practice[]>([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		load()
	}, [search])

	const load = async () => {
		var res = await api.adminPractice.list({
			page: 1,
			pageSize: 20,
			search: search,
			isRemoved: false,
			sortField: "Name",
			sortDirection: "asc"
		})
		setItems(res.data);
	}


	return <Select mode={"multiple"} placeholder="Hospital" style={{ width: "100%" }} allowClear value={value} onChange={(x) => onChange(x)} onSearch={setSearch} showSearch filterOption={false}>
		{items.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
	</Select>
}
export default PracticeMultiSelect;