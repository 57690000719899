import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Result, Row, Space, Table, TablePaginationConfig, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { EyeTwoTone, DeleteTwoTone, ReloadOutlined } from "@ant-design/icons";
import React from "react";
import { User } from "api/model/User/User";
import OfficeEdit from "./DoctorEdit";
import { Office } from "api/model/Offices/Office";
import { DoctorStore } from "./DoctorStore";
import OfficeSelect from "app/components/OfficeSelect";
import DoctorEdit from "./DoctorEdit";
import { Doctor } from "api/model/Offices/Doctor";
import PracticeSelect from "app/components/PracticeSelect";


const DoctorList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<DoctorStore>(() => new DoctorStore(api));
	const [editing, setEditing] = React.useState<Doctor>(null);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const isQuickStart = searchParams.get("qs");

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<User> | SorterResult<User>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<Doctor> = [

		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Doctor) =>
				x.createdDate ?
					<span>{moment(x.createdDate).format("DD MMM yyyy HH:mm:ss")}</span>
					:
					<span className="secondary-text">No data</span>

		},
		{
			title: 'Last Name',
			key: 'LastName',
			ellipsis: true,
			render: (x: Doctor) => <>
				<a onClick={() => setEditing(x)}>
					{x.lastName} {x.firstName}
				</a>
				<div>SyncVetId: {x.syncVetId || <span className="secondary-text">No sync</span>}</div>
			</>
		},
		{
			title: 'Hospital',
			render: (x: Doctor) => {
				return <>
					{x.practice?.name || <span className="secondary-text">No Hospital</span>}
				</>
			}
		},
		// {
		// 	title: 'Data',
		// 	render: (x: Doctor) => {
		// 		return <>

		// 			<div>email: {x.email || <span className="secondary-text">No data</span>} </div>
		// 			<div>phone: {x.phone || <span className="secondary-text">No data</span>} </div>
		// 		</>
		// 	}
		// },
		{
			title: 'Actions',
			align: "right",
			render: (x: Doctor) => <Space key={x.id}>
				{x.softDeleted && <Tag color={"red"}>Removed</Tag>}

				<Button icon={<EyeTwoTone />} onClick={() => setEditing(x)} />

				{!x.softDeleted ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button icon={<DeleteTwoTone />} />
					</Popconfirm>
					:
					<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
				}
			</Space>
		},
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="/">Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Doctors
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />


		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<Checkbox
				indeterminate={store.filter.isRemoved == null}
				checked={store.filter.isRemoved === true} onClick={() => store.toggleIsRemoved()}
			>
				Removed
			</Checkbox>

			<PracticeSelect onChange={x => store.setFilter({ practiceId: x })}></PracticeSelect>

			<Button onClick={() => setEditing({ id: 0 })}>Add new</Button>

		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				store.pagedData?.data?.length == 0 && isQuickStart ?
					<Result
						status={404}
						title={<p style={{ fontSize: 18, maxWidth: 550, margin: '0 auto' }}>
							You have not yet added any doctors. Doctors must be connected to a users who utilize this app to dictate notes on appointments.
							A doctor can be associated with multiple users.
						</p>
						}
						extra={<Button type="primary" size="large" onClick={() => setEditing({ id: 0 })}>Add new Doctor</Button>}
					/>
					:
					<Table
						onChange={handleTableChange}
						loading={store.loading}
						rowKey={x => x.id ? x.id : ""}
						columns={columns}
						dataSource={store.pagedData?.data}
						pagination={{
							hideOnSinglePage: true,
							current: store.pagedData?.page,
							total: store.pagedData?.total,
							pageSize: store.pagedData?.pageSize,
							showSizeChanger: true,
							showTotal: (total: number) => `Total: ${total}`
						}}
					/>
		}

		<Drawer destroyOnClose={true} visible={editing != null} onClose={() => setEditing(null)} title={"Doctor Edit"}>
			<DoctorEdit doctor={editing} onCancel={() => setEditing(null)} onSuccess={() => store.load() && setEditing(null)} />
		</Drawer>
	</>
})


export default DoctorList;




