import React from 'react';
import PhoneInputComponent, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { SizeType } from 'antd/lib/config-provider/SizeContext';
import combineClasses from 'misc/helpers/combineClasses';
import isEmpty from 'misc/helpers/isEmpty';
import styles from './PhoneInput.module.scss';

type Props = PhoneInputProps & {
    size?: SizeType;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (phone: string, country: {} | CountryData, e: React.ChangeEvent<HTMLInputElement>, formatValue: string) => void;
    onMounted?: (phone: string, country: {} | CountryData, formatValue: string) => void;
    className?: string;
    addPlusSign?: boolean;
}

const PhoneInput: React.FC<Props> = ({ size, className, addPlusSign, disabled, onChange, onMounted, placeholder, value, ...phoneProps }) => {

    return <PhoneInputComponent
        country={phoneProps.country}
        value={value}
        disabled={disabled}
        onChange={(value, data, event, format) => {
            const resultValue = (!isEmpty(value) && addPlusSign === true)
                ? `+${value}`
                : value;
            onChange?.(resultValue, data, event, format);
        }}
        onMount={(value, data, format) => {
            const resultValue = (!isEmpty(value) && addPlusSign === true)
                ? `+${value}`
                : value;
            onMounted?.(resultValue, data, format);
        }}
        placeholder={placeholder}
        containerClass={combineClasses(styles.phone_input, className, `phone_input_${size}`)}
        data-size={size}
        {...phoneProps}
    />;
}

PhoneInput.defaultProps = {
    size: 'middle',
    addPlusSign: true,
    placeholder: ''
}

export default PhoneInput;