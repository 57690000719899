import { Alert, Button, Checkbox, Divider, Form, Input, Select, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { LanguageEnum, LanguageEnumList } from "api/model/User/Language";
import { UserRegisterRequest } from "api/routes/User/DTO/UserRegisterRequest";
import Logo from "app/components/Logo";
import { isValidPhoneNumber } from "libphonenumber-js";
import combineClasses from "misc/helpers/combineClasses";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useVSHub from "misc/hooks/useVSHub";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "app/components/PhoneInput/PhoneInput";
import styles from './Auth.module.scss';
import appStore from '../../assets/appstore.svg';
import googlePlay from '../../assets/googleplay.svg'
import scriptBook from './assets/scriptBook.png';
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import PasswordInput from "routes/components/PasswordInput/PasswordInput";

const Register: React.FC = () => {
    return <div className={combineClasses(styles.login_wrapper, "fadeInLeft")}>
        <div style={{ textAlign: "center" }}>
            <Logo size={200} />

            <Typography.Paragraph className='color-white fs-18'>Already registered? Go to <Link to="/auth/login" className='color-brown'>login</Link></Typography.Paragraph>
        </div>
        <RegisterForm />

        <div className={styles.apps_buttons}>
            <div onClick={() => window.location.href = 'https://apps.apple.com/us/app/vetskribe/id1634975470'}>
                <img src={appStore} alt="Download from App Store" />
            </div>
            <div onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.monarchy.vetscribe'}>
                <img src={googlePlay} alt="Download from Google Play" />
            </div>
        </div>
        <div className={styles.script_book_sign}>
            <img src={scriptBook} />
        </div>
    </div>
}

const RegisterForm = () => {
    const user = useCurrentUser();
    const api = useApi();
    const [form] = useForm();
    const [state, setState] = useState<{ error: string, loading: boolean }>({ error: "", loading: false });
    const navigate = useNavigate();
    const hub = useVSHub();

    const submit = async (values: UserRegisterRequest) => {
        setState({ ...state, loading: true, error: "" })

        if (values.acceptedTC == false) {
            return;
        }

        try {
            const result = await api.user.signUpWithEmailAndPassword(values);
            if (result.error) {
                setState({ ...state, loading: false, error: result.error })
                return;
            }

            if (result.accessToken && result.refreshToken) {
                await user.auth(result.accessToken, result.refreshToken);
                await hub.init();
            }
            setState({ ...state, loading: false })
            navigate("/initial-setup");
            return;
        } catch (e: any) {
            setState({ ...state, error: e.message })
        }
    }


    return <>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="false" initialValues={{
            language: LanguageEnum.English,
        }}>

            {state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

            <Form.Item className="body-form-item" name="firstName" rules={[{ required: true, message: 'Please enter your first name' }]}>
                <Input placeholder="First name" type="text" />
            </Form.Item>

            <Form.Item className="body-form-item" name="lastName" rules={[{ required: true, message: 'Please enter your last name' }]}>
                <Input placeholder="Last name" type="text" />
            </Form.Item>

            <Form.Item className="body-form-item" name="email" rules={[{ required: true, message: 'Please enter your email' }, { type: 'email' }]}>
                <Input placeholder="Email" type="new-email" />
            </Form.Item>

            <PasswordInput
                className="body-form-item"
                name="password"
                placeholder="Password"
            />

            <Form.Item className="body-form-item" name="passwordConfirm" dependencies={['password']} rules={[{ required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords don’t match'));
                },
            })
            ]}>
                <Input.Password autoComplete="new-password" placeholder="Confirm password" />
            </Form.Item>

            <Form.Item name={'phone'} rules={[
                { required: false },
                {
                    validator: async (_, value) => {
                        if (value && !isValidPhoneNumber(value)) {
                            return Promise.reject(new Error('Enter valid phone number'));
                        }
                        return Promise.resolve();
                    }
                }
            ]}>
                <PhoneInput placeholder={'Enter phone'} country='us' size='large'
                    containerStyle={{ height: 45 }}
                    inputClass='fs-18' />
            </Form.Item>
            <Divider />

            <Form.Item required className="body-form-item" name="accountName" rules={[{ required: true, message: 'Please enter hospital name or business name for groups' }]}>
                <Input placeholder="Hospital name or business name for groups" type="text" />
            </Form.Item>

            <Form.Item name={'language'}>
                <Select placeholder="Select language" defaultValue={LanguageEnum.English}>
                    {LanguageEnumList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item name="acceptedTC" valuePropName="checked" rules={[{ required: true, transform: x => x || undefined, type: "boolean", message: "You must accept terms in order to use VetSkribe" }]}>
                <Checkbox>
                    <span className="color-white fs-16">I have read and accept</span>&nbsp;
                    <Link target="_blank" to="/terms" className="color-brown fs-16">the terms and conditions</Link>
                </Checkbox>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
                <Button className='br-30 w-100' type="primary" size='large' htmlType="submit" loading={state.loading} style={{ height: 50 }}>Sign Up for free 30 day trial</Button>
            </Form.Item>
        </Form>
    </>
}

export default Register;