import { Select } from "antd";
import { AccountSimple } from "api/model/Account/AccountSimple";
import useApi from "misc/hooks/useApi";
import React, { useEffect, useState } from "react"

const AccountSelect: React.FC<{ value?: number, onChange: (x: number) => void, forceSelectOne?: boolean }> =
	({ value, onChange, forceSelectOne }) => {
		const api = useApi();
		const [items, setItems] = useState<AccountSimple[]>([]);
		const [search, setSearch] = useState("");
		const [initialLoaded, setInitialLoaded] = useState(false);
		const [loading, setLoading] = useState(false);
		const [selected, setSelected] = useState<number | null>(value);

		useEffect(() => {
			load()
		}, [search])

		useEffect(() => {
			if (forceSelectOne && !value && items.length > 0 && !initialLoaded) {
				setSelected(items[0].id);
				onChange(items[0].id);
			}
		}, [items])

		const handleChange = (value: number) => {
			setSelected(value);
			onChange(value);
		}

		const load = async () => {
			setLoading(true);
			var res = await api.adminAccounts.listSimple({
				page: 1,
				pageSize: 20,
				search: search,
				isRemoved: false,
				sortField: "Name",
				sortDirection: "asc",
				includeIds: !initialLoaded && value ? [value] : []
			})
			setInitialLoaded(true);
			setItems(res.data);
			setLoading(false);
		}

		return <Select loading={loading} placeholder="Account" style={{ width: "225px" }} allowClear
			value={selected} onChange={handleChange} onSearch={setSearch} showSearch filterOption={false}>
			{items.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
		</Select>
	}

export default AccountSelect;