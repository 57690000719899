import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { AppointmentFeedbackStatus } from "api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { AppointmentCreateDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto";
import { AppointmentListRequest } from "api/vs-recording/routes/Appointment/Model/AppointmentListRequest";
import { debounce } from "lodash";
import { makeAutoObservable } from "mobx";
import moment from "moment";

export class AppointmentListStore {
	constructor(private api: ApiClient, p?: Partial<AppointmentListStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	loading: boolean = false;
	error: string | null = null;
	date: Date = new Date();

	filter: AppointmentListRequest = {
		page: 1,
		pageSize: 25,
		officeId: undefined,
		doctorId: undefined,
		clientId: undefined,
		patientId: undefined,
		status: undefined,
		search: undefined,
		sortField: "Date",
		sortDirection: "asc",
		dateFrom: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0, 0),
		dateTo: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59, 0)
	}

	pagedData: PagingResponse<AppointmentClientDto> | null = null;

	//computeds
	get completedAppointments() {
		if (!this.pagedData) return 0;
		return this.pagedData.data.filter(x =>
			x.feedback?.status == AppointmentFeedbackStatus.Markup ||
			x.feedback?.status == AppointmentFeedbackStatus.Completed
		).length;
	}

	get totalAppointments() {
		if (!this.pagedData) return 0;
		return this.pagedData.data.length;
	}

	get selectedDate() {
		return this.filter.dateFrom;
	}

	createAppointment = async (a: AppointmentCreateDto) => {
		this.setState({ error: null, loading: true });
		try {
			const res = await this.api.vsRecording.appointment.createAppointment(a);
			this.load();
			return res.data;
		} catch (e: any) {
			this.setState({ error: e.message });
		}
	}

	monthBack() {
		this.filter.dateFrom = moment(this.filter.dateFrom).utc().subtract(1, 'month').toDate();
		this.filter.dateTo = moment(this.filter.dateTo).utc().subtract(1, 'month').toDate();
		this.load();
	}

	monthForward() {
		this.filter.dateFrom = moment(this.filter.dateFrom).utc().add(1, 'month').toDate();
		this.filter.dateTo = moment(this.filter.dateTo).utc().add(1, 'month').toDate();
		this.load();
	}

	setDateFilter = (dateFrom: Date, dateTo: Date) => {
		this.setFilter({
			dateFrom: dateFrom,
			dateTo: dateTo
		});
		this.load();
	};

	setState = (p: Partial<AppointmentListStore>) => {
		Object.assign(this, p);
	};

	load = async () => {
		this.setState({ error: null, loading: true });
		try {
			const r = await this.api.vsRecording.appointment.list(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter, sortField: r.sortField, sortDirection: r.sortDirection, page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.message });
		}
		this.setState({ loading: false });
	};


	setFilter = (p: Partial<AppointmentListRequest>) => {
		Object.assign(this.filter, p);
		this.load();
	};

	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: shouldResetPage ? 1 : page,
			pageSize: pageSize
		})
	}



	setSearch = debounce((search?: string) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: undefined })
	}
}
