import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Popconfirm, Progress, Tag } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import WaveSurfer from 'wavesurfer.js';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import AudioFileStorage from '../../../../stores/AudioFileStorage';
import useApi from 'misc/hooks/useApi';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { observer } from 'mobx-react';
import { COLORS } from 'vs-recording/styles/Colors';

type PlayState = 'playing' | 'paused' | 'stopped';
type AudioPlayerState = {
    playState: PlayState;
    currentSec: number;
    durationSec: number;
};

type AudioPlayerProps = {
    audio: AppointmentFeedbackAudio;
    onDelete?: () => void;
    showDelete: boolean;
    disabled?: boolean;
};

const AudioPlayer: React.FC<AudioPlayerProps> = observer(({ audio, onDelete, showDelete, disabled }) => {
    const api = useApi();
    const [state, setState] = useState<AudioPlayerState>({ playState: 'stopped', currentSec: 0, durationSec: 0 });
    const [error, setError] = useState<string | null>(null); // Новое состояние для ошибки
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurferRef = useRef<WaveSurfer | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0);
    const isSaved = audio.id && (audio.id > -1);
    const appointmentContext = useContext(AppointmentStoreContext);
    const isMounted = useRef(true); // fix error "container not found" when unmounting

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            cleanUpWaveSurfer();
        };
    }, []);

    useEffect(() => {
        if (appointmentContext?.audioUploadingUri) return;
        loadAudio();
    }, [appointmentContext?.audioUploading]);

    const cleanUpWaveSurfer = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.unAll();
            wavesurferRef.current.destroy();
            wavesurferRef.current = null;
        }
    };

    const loadAudio = useCallback(async () => {
        setIsLoading(true);
        setProgress(0);
        setError(null); // Сброс ошибки при новой попытке загрузки
        cleanUpWaveSurfer();

        let url: string = null;
        try {
            if (audio.id && audio.id > -1) {
                url = await api.vsRecording.appointment.getAudioUrl(audio);
            } else {
                const blob = await AudioFileStorage.getBlobByKey(audio.uri);
                url = URL.createObjectURL(blob);
            }
            if (waveformRef.current) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';

                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = (event.loaded / event.total) * 100;
                        if (isMounted.current) {
                            setProgress(percentComplete);
                        }
                    }
                };

                xhr.onload = () => {
                    if (xhr.status === 200 && isMounted.current) {
                        const blob = xhr.response;
                        const objectUrl = URL.createObjectURL(blob);

                        wavesurferRef.current = WaveSurfer.create({
                            container: waveformRef.current!,
                            waveColor: COLORS.grey,
                            progressColor: COLORS.orange,
                            cursorColor: COLORS.orange,
                            cursorWidth: 2,
                            barWidth: 2,
                            height: 40,
                            normalize: true,
                        });

                        wavesurferRef.current.load(objectUrl);

                        wavesurferRef.current.on('ready', () => {
                            if (isMounted.current) {
                                const duration = wavesurferRef.current?.getDuration() || 0;
                                setState((prevState) => ({ ...prevState, durationSec: Math.round(duration) }));
                                setTimeout(() => setIsLoading(false), 300); // TODO: remove if not needed
                            }
                        });

                        wavesurferRef.current.on('audioprocess', () => {
                            if (isMounted.current) {
                                const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
                                setState((prevState) => ({ ...prevState, currentSec: Math.round(currentTime) }));
                            }
                        });

                        wavesurferRef.current.on('finish', () => {
                            if (isMounted.current) {
                                setState((prevState) => ({ ...prevState, playState: 'stopped', currentSec: prevState.durationSec }));
                            }
                        });

                        URL.revokeObjectURL(url); // Revoke the original object URL
                    } else {
                        setError('Failed to load audio file');
                    }
                };

                xhr.onerror = () => {
                    if (isMounted.current) {
                        setError('Failed to load audio file');
                        setIsLoading(false);
                    }
                };

                xhr.send();
            }
        } catch (e) {
            setError('Error uploading audio. Try again');
            setIsLoading(false);
        }
    }, [audio.uri, audio.id]);

    const play = useCallback(() => {
        if (isMounted.current) {
            wavesurferRef.current?.play();
            setState((prevState) => ({ ...prevState, playState: 'playing' }));
        }
    }, []);

    const pause = useCallback(() => {
        wavesurferRef.current?.pause();
        setState((prevState) => ({ ...prevState, playState: 'paused' }));
    }, []);

    const deleteAudio = useCallback(() => {
        if (onDelete && isMounted.current) {
            onDelete();
        }
    }, [onDelete]);

    const getAudioTimeString = useCallback((seconds: number) => {
        if (seconds < 0) return '00:00:00';
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    }, []);

    return <div style={{ backgroundColor: COLORS.lightMainBg, borderRadius: '10px', padding: '10px' }}>
        <div className='flex align-center'>
            <>
                <Progress percent={progress} showInfo={false} style={{ flex: 1, display: !isLoading && 'none', height: 40 }} />
                <div ref={waveformRef} style={{ flex: 1, display: isLoading && 'none' }} onClick={e => e.stopPropagation()}></div>
            </>
            <div className='ml-10'>
                {state.playState === 'paused' || state.playState === 'stopped'
                    ? <Button type='text' disabled={disabled} shape="circle" icon={<PlayCircleOutlined className='fs-22' style={{ color: COLORS.mainBG }} />} onClick={e => {
                        e.stopPropagation();
                        play();
                    }} />
                    : <Button type='text' disabled={disabled} shape="circle" icon={<PauseCircleOutlined className='fs-22' style={{ color: COLORS.mainBG }} />} onClick={e => {
                        e.stopPropagation();
                        pause();
                    }} />}
            </div>
            <div className='ml-10'>
                {showDelete && (
                    <Popconfirm okType='default' disabled={disabled} title="Are you sure you want to delete this audio?" onConfirm={e => {
                        e.stopPropagation();
                        deleteAudio();
                    }}>
                        <Button disabled={disabled} shape="circle" icon={<DeleteOutlined />} onClick={e => e.stopPropagation()} />
                    </Popconfirm>
                )}
            </div>
        </div>
        <div className='flex justify-between mt-5'>
            <span>{getAudioTimeString(state.currentSec)}</span>
            <div className='flex align-center gap-10'>
                <Tag color={error ? COLORS.red : isSaved ? COLORS.green : COLORS.buttonBg}>
                    {error ? error
                        : isLoading
                            ? 'Loading' + (progress ? ` ${progress.toFixed(0)}%` : '')
                            : isSaved ? 'Uploaded' : 'Saved locally'}
                </Tag>
                <span>{getAudioTimeString(state.durationSec)}</span>
            </div>
        </div>
    </div>;
});

export default AudioPlayer;
