import { PatientUpdateRequest } from "api/routes/Clients/Patient/Model/PatientUpdateRequest";
import { Patient } from "api/model/Clients/Patient";
import { Alert, Input, Button, Form, Divider, notification, Row, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import ClientSelect from "app/components/ClientSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";


const PatientEdit: React.FC<{ patient: Patient, onSuccess: () => void, onCancel: () => void }> = ({ patient, onSuccess, onCancel }) => {
	const api = useApi();
	const currentUser = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });

	const submit = async (values: PatientUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = patient.id;
			const res = await api.adminPatient.updateCreate(values);
			if (res.success == true) {
				notification.success({ message: "Saved" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occured" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	if (!patient) return null;

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={patient || {}}  >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"Name"} name="name">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Client"} name="clientId">
			<ClientSelect value={patient.clientId ? patient.clientId.toString() : null} onChange={(x: any) => form.setFieldsValue({ clientId: x })} />
		</Form.Item>

		<Divider />

		<Form.Item label={"Breed Code"} name="breedCode">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Breed Description"} name="breedDescription">
			<Input type="text" />
		</Form.Item>

		<Divider />

		<Form.Item label={"Color Code"} name="colorCode">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Color Description"} name="colorDescription">
			<Input type="text" />
		</Form.Item>

		<Divider />

		<Form.Item label={"Gender Code"} name="genderCode">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Gender Description"} name="genderDescription">
			<Input type="text" />
		</Form.Item>

		<Row justify="space-between">
			<Button type="ghost" onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form>
}

export default PatientEdit;
