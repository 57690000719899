import { Divider, Select } from "antd";
import { Patient } from "api/model/Clients/Patient";
import useApi from "misc/hooks/useApi";
import React, { useEffect, useState } from "react"

const PatientSelect: React.FC<{ value?: number, onChange: (x: any) => void, clientId?: number }> = ({ value, onChange, clientId }) => {
	const api = useApi();
	const [items, setItems] = useState<Patient[]>([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		load()
	}, [search])

	const load = async () => {
		var res = await api.adminPatient.list({
			page: 1,
			pageSize: 20,
			search: search,
			sortField: "Name",
			sortDirection: "asc"
		})
		setItems(res.data);
	}

	return <Select
		style={{ minWidth: 300 }}
		value={value}
		onChange={(x) => onChange(x)}
		onSearch={setSearch}
		allowClear
		showSearch
		placeholder="Choose patient"
		filterOption={false}
		options={items.map(x => ({ value: x.id, label: x.name }))} />
}
export default PatientSelect;