import { Alert, Breadcrumb, Button, Checkbox, Divider, Input, Popconfirm, Space, Table, TablePaginationConfig, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import { Link } from "react-router-dom";
import React from "react";
import { AccountStore } from "./AccountStore";
import useGeneralState from "misc/hooks/useGeneralState";
import { Account } from "api/model/Account/Account";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { FilterValue, SorterResult } from "antd/es/table/interface";


const AccountList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<AccountStore>(() => new AccountStore(api));
	const generalState = useGeneralState();

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Account> | SorterResult<Account>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<Account> = [
		{
			title: 'Created Date',
			key: 'CreatedDate',
			width: 300,
			sorter: false,
			render: (x: Account) => <>
				{moment(x.createdDate).format("LLL")}
			</>
		},
		{
			title: 'Account',
			key: 'Account',
			width: 350,
			sorter: false,
			render: (x: Account) => <>
				<Tag>{x.id}</Tag> {x.name}
				{x.softDeleted && <>&nbsp;<Tag color="red" className="fs-12">Deleted</Tag></>}
			</>
		},
		{
			title: 'Trial',
			align: "right",
			render: (x: Account) => {
				var ended = moment(x.billingEndTrial).isBefore(moment());
				if (ended) {
					<Tag color={"green"}>Trial Ended</Tag>
				}
				return <Tooltip title={moment(x.billingEndTrial).format("LLL")}>
					{moment(x.billingEndTrial).fromNow()}
				</Tooltip>
			}
		},
		{
			title: 'Users Count',
			align: "right",
			render: (x: Account) => x.usersCount
		},
		{
			title: 'Hospitals Count',
			align: "right",
			render: (x: Account) => x.practicesCount
		},
		{
			title: 'Billing',
			align: "right",
			render: (x: Account) => <>
				at <b>{x.billingMonthDay}</b> each mo <br />
				<b>${x.billingPerDoctor}</b> per doctor
			</>
		},
		{
			title: 'Actions',
			align: "right",
			width: 100,
			render: (x: Account) => <Space key={x.id}>
				<Popconfirm title='Are you sure to delete this account?' onConfirm={() => store.delete(x)} disabled={x.softDeleted}>
					<Button danger icon={<DeleteOutlined />} disabled={x.softDeleted} loading={store.loading} />
				</Popconfirm>
			</Space>
		},
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Billing reports
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>
			<Checkbox
				checked={store.filter.softDeleted == null}
				onChange={e => store.setFilter({ softDeleted: e.target.checked ? null : false })}>
				Show deleted
			</Checkbox>
		</Space>


		{store.error
			? <Alert message={store.error} type="error" />
			: <Table
				onChange={handleTableChange}
				loading={store.loading}
				rowKey={x => x.id}
				columns={columns}
				dataSource={store.pagedData?.data}
				pagination={{
					hideOnSinglePage: true,
					current: store.pagedData?.page,
					total: store.pagedData?.total,
					pageSize: store.pagedData?.pageSize,
					showSizeChanger: true,
					showTotal: (total: number) => `Total: ${total}`
				}}
			/>}
	</>
})


export default AccountList;




