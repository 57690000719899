import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { NotificationInstance } from "antd/es/notification/interface";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { AdminAppointmentDto } from "api/routes/Appointments/Appointment/DTO/AdminAppointmentDto";
import { AdminAppointmentListRequest } from "api/routes/Appointments/Appointment/Model/AdminAppointmentListRequest";

export class AppointmentStore {
	constructor(private api: ApiClient, p?: Partial<AppointmentStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	loading: boolean = false;
	error: string | null = null;

	filter: AdminAppointmentListRequest = {
		page: 1,
		pageSize: 25,
		officeId: null,
		doctorId: null,
		clientId: null,
		patientId: null,
		status: null,
		search: null,
		sortField: "Date",
		sortDirection: "desc",
		dateTo: null,
		dateFrom: null,
		isRemoved: false,
		isCompleted: false,
		practiceId: null
	}

	pagedData: PagingResponse<AdminAppointmentDto> | null = null;
	notificationApi?: NotificationInstance;

	setState = (p: Partial<AppointmentStore>) => {
		Object.assign(this, p);
	};

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminAppointment.list(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter, sortField: r.sortField, sortDirection: r.sortDirection, page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.message });
		}
		this.setState({ loading: false });
	};


	setFilter = (p: Partial<AdminAppointmentListRequest>) => {
		Object.assign(this.filter, p);
		this.load();
	};

	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: shouldResetPage ? 1 : page,
			pageSize: pageSize
		})
	}

	setSearch = debounce((search: string | null) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: null })
	}

	delete = async (x: AdminAppointmentDto) => {
		try {
			const r = await this.api.adminAppointment.delete(x);
			if (r) {
				this.notificationApi?.success({ message: "Deleted" });
			}
			this.load();
		} catch (e: any) {
			this.notificationApi?.error({ message: e.message });
		}
	};

	restore = async (x: AdminAppointmentDto) => {
		try {
			const r = await this.api.adminAppointment.restore(x);
			if (r) {
				this.notificationApi?.success({ message: "Restored" });
			}
			this.load();
		} catch (e: any) {
			this.notificationApi?.error({ message: e.message });
		}
	};

	toggleIsRemoved = () => {
		if (this.filter.isRemoved == null) this.setFilter({ isRemoved: true })
		else if (this.filter.isRemoved == true) this.setFilter({ isRemoved: false })
		else if (this.filter.isRemoved == false) this.setFilter({ isRemoved: null })
	}

	toggleIsCompleted = () => {
		if (this.filter.isCompleted == null) this.setFilter({ isCompleted: true })
		else if (this.filter.isCompleted == true) this.setFilter({ isCompleted: false })
		else if (this.filter.isCompleted == false) this.setFilter({ isCompleted: null })
	}
}
