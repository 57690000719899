import { Alert, Input, Button, Form, Typography, Divider, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import Logo from "app/components/Logo";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useGeneralState from "misc/hooks/useGeneralState";
import React, { CSSProperties, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import useVSHub from "misc/hooks/useVSHub";
import { Env, env } from "appSettings";
import combineClasses from "misc/helpers/combineClasses";
import styles from './Auth.module.scss';

const Login: React.FC = () => {
	return <div className={combineClasses(styles.login_wrapper, "fadeInLeft")}>
		<div style={{ textAlign: "center" }}>
			<Logo size={200} />
			<Typography.Title>Sign in</Typography.Title>
			<Typography.Paragraph className='color-white fs-18'>If don't have account <Link to="/auth/register" className='color-brown'>Sign Up</Link> for free trial</Typography.Paragraph>
		</div>
		<LoginForm />
	</div>
}

const LoginForm = () => {
	const api = useApi();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: "", loading: false });
	const navigate = useNavigate();
	const user = useCurrentUser();
	const hub = useVSHub();
	const initialValues = {
		email: env == Env.dev ? 'admin@test.com' : "",
		password: env == Env.dev ? '123456' : ""
	}

	const submit = async (values: Store) => {
		setState({ ...state, loading: true, error: "" })
		try {
			const result = await api.user.signInWithEmailAndPassword({ email: values.email, password: values.password });
			if (result.error || !result.success) {
				setState({ ...state, loading: false, error: result.error || "" })
				return;
			}

			if (result.accessToken && result.refreshToken) {
				await user.auth(result.accessToken, result.refreshToken);
				await hub.init();
			}

			setState({ ...state, loading: false, error: null })
			navigate("/")
			return;
		} catch (e) {
			setState({ ...state, loading: false, error: null })
		}
	}

	return <Form form={form} initialValues={initialValues} onFinish={submit} layout={"vertical"}>
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item className="body-form-item" label={"E-mail"} name="email" rules={[{ required: true, message: 'Enter valid email' }, { type: 'email' }]}>
			<Input placeholder={"Enter your email"} type="email" />
		</Form.Item>

		<Form.Item className="body-form-item" label={"Password"} name="password" rules={[{ required: true, message: 'Please input your password' }]}>
			<Input.Password placeholder={"Enter password"} />
		</Form.Item>

		<Row justify="center" wrap={true}>
			<Form.Item style={{ textAlign: "center" }}>
				<Button className="bc-btn" type="primary" htmlType="submit" size="large" loading={state.loading}>{"Sign In"}</Button>
				<Button type="link"><Link to="/auth/forgot">{"Forgot password?"}</Link></Button>
			</Form.Item>
		</Row>
	</Form >
}

export default Login;