import { AdminAppointmentDto } from "api/routes/Appointments/Appointment/DTO/AdminAppointmentDto";
import { Button, Divider, Popconfirm, Select, Typography } from "antd";
import Details from "app/components/Details/Details";
import moment from "moment";
import React, { useMemo, useState } from "react";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { startsWith } from "lodash";
import { getLangLabel } from "misc/helpers/getLangLabel";
import { LanguageEnum, LanguageEnumList } from "api/model/User/Language";


const AppointmentView: React.FC<{ appointment: AdminAppointmentDto, onLangChange: (lang: LanguageEnum) => void }> = ({ appointment, onLangChange }) => {
	const user = useCurrentUser();
	const showPatientId = useMemo(() => appointment.patientSyncVetId && !startsWith(appointment.patientSyncVetId.toString(), 'pa_'), [appointment.patientSyncVetId]);
	const showClientId = useMemo(() => appointment.clientSyncVetId && !startsWith(appointment.clientSyncVetId.toString(), 'cl_'), [appointment.clientSyncVetId]);

	const [langToChange, setLangToChange] = useState<LanguageEnum | null>(null);

	if (!appointment) return null;
	return <>
		<Details layout="horizontal" labelWidth="200px"
			data={[
				{
					label: "Account",
					visible: !user.isSAASUser,
					value: appointment.accountName + " - " + appointment.accountId
				},
				{
					label: "System ID",
					value: appointment.id
				},
				{
					label: "SyncVet ID",
					visible: appointment.syncVetId != null,
					value: appointment.syncVetId ? <Typography.Text copyable>{appointment.syncVetId}</Typography.Text> : "no ID"
				},
				{
					label: "Date",
					value: moment(appointment.date).utc().format("DD MMM YYYY h:mm a")
				},
				{
					label: "Hospital",
					value: appointment.practiceName
				},
				{
					label: "Doctor",
					value: appointment.doctorId ?
						<Typography.Text copyable>{appointment.doctorName}</Typography.Text> :
						"no ID"
				},
				{
					label: "Language",
					value: <>
						{getLangLabel(appointment.language)}
						<Popconfirm destroyTooltipOnHide
							title={<div>
								<div>
									<span>All recordings will be re-processed.<br /><strong>Select new language for the appointment.</strong></span>
								</div>
								<span>New language: </span>
								<Select placeholder="Select language" onChange={value => setLangToChange(value)} value={langToChange} className="mt-10" style={{ width: 200 }}>
									{LanguageEnumList.filter(x => x.value != appointment.language).map(lang => <Select.Option
										key={lang.value}
										value={lang.value}>
										{lang.label}
									</Select.Option>)}
								</Select>
							</div>} okText="Change"
							okButtonProps={{ disabled: langToChange == null }}
							onOpenChange={open => !open && setLangToChange(null)}
							onConfirm={() => onLangChange(langToChange)}>
							<Button type="link" size="small">Change</Button>
						</Popconfirm>
					</>
				}
			]} />

		<Divider orientation="left" type="horizontal">Client & Patient</Divider>
		<Details layout="horizontal" labelWidth="200px"
			data={[
				{
					label: "Client",
					value: <>{appointment.clientName ?? <span className="secondary-text">No name</span>} {showClientId && <>- <Typography.Text copyable>{appointment.clientSyncVetId}</Typography.Text></>} </>
				},
				{
					label: "Patient",
					value: <>{appointment.patientName} {showPatientId && <>- <Typography.Text copyable>{appointment.patientSyncVetId}</Typography.Text></>}</>
				},
				{
					label: "Breed",
					visible: appointment.patientBreedCode != null,
					value: appointment.patientBreedCode
				},
				{
					label: "Species",
					visible: appointment.patientSpecies != null,
					value: appointment.patientSpecies
				}
			]} />
	</>
}


export default AppointmentView;


