export enum AppointmentFeedbackStatus {
	Draft = "Draft",
	Markup = "Markup",
	MarkupDone = "MarkupDone",
	Completed = "Completed",
	ReadyToProcess = "ReadyToProcess",
	Processing = "Processing",
	ErrorProcessing = "ErrorProcessing",
	EditingInTheApp = "EditingInTheApp",
	All = 100,
	PrepocessingDone = "PrepocessingDone",
}

export const AppointmentFeedbackStatusList = [
	{ value: AppointmentFeedbackStatus.Draft, label: "Draft", color: "default", tooltip: "Doctor is started or editing recording" },
	{ value: AppointmentFeedbackStatus.Markup, label: "Submitted", color: "purple", tooltip: "Recording is submitted and waiting to be edited or approved" },
	{ value: AppointmentFeedbackStatus.MarkupDone, label: "Editing Done", color: "blue", tooltip: "Editing is done and now ready for review by doctor" },
	{ value: AppointmentFeedbackStatus.Completed, label: "Completed", color: "green", tooltip: "Recording is completed and ready to be added to PIMS" },
	{ value: AppointmentFeedbackStatus.ReadyToProcess, label: "Ready to Process", color: "orange", tooltip: "Recording is ready to be processed" },
	{ value: AppointmentFeedbackStatus.Processing, label: "Processing", color: "orange", tooltip: "Recording is processing" },
	{ value: AppointmentFeedbackStatus.ErrorProcessing, label: "Error Processing", color: "red", tooltip: "Recording has error while processing" },
	{ value: AppointmentFeedbackStatus.EditingInTheApp, label: "Editing in the App", color: "blue", tooltip: "Recording is being edited in the app" },
	{ value: AppointmentFeedbackStatus.PrepocessingDone, label: "Prepocessing Done", color: "default", tooltip: "Pre-record is made by Vet Tech" }
]


