import { Spin, SpinProps } from "antd";
import React from "react";
import loadingLogo from '../../../assets/loading-logo.png';
import styles from './LoadingOverlay.module.scss';

type LoadingOverlayProps = SpinProps & {
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {

    return <Spin {...props} delay={300} indicator={<div className={styles.loadingIndicator}>
        <img src={loadingLogo} alt='Loading...' />
    </div>}>
    </Spin>

};

export default LoadingOverlay;