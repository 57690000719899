
import ApiClient from "../../base/ApiClient";
import { PagingResponse } from "../../base/Paging";
import { UserReport } from "api/model/Analytics/UserReport";
import { UserReportListRequest } from "./Model/UserReportListRequest";
import { TotalsReportsRequest } from "./Model/TotalsReportRequest";
import { TotalsReport } from "api/model/Analytics/TotalsReport";


export default class AdminAnalyticsApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/analytics";

	async userReport(req: UserReportListRequest) {
		const request = await this.api.client.post<PagingResponse<UserReport>>(`${this.baseUrl}/user`, { ...req });
		return request.data;
	}

	async totals(req: TotalsReportsRequest) {
		const request = await this.api.client.post<TotalsReport>(`${this.baseUrl}/totals`, { ...req });
		return request.data;
	}

}