export const tryParseInt = (string?: string[] | string | number | null, defaultValue?: number | null) => {
    try {
        var retValue = defaultValue;
        if (typeof (string) == "number") {
            return string;
        }
        if (typeof (string) == "string") {
            if (string.length > 0) {
                retValue = parseInt(string);
            }
        }
        return retValue || null;
    } catch (e) {
        return defaultValue || null
    }

}