import { AppointmentFeedbackStatus } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import EditFeedback from './components/EditFeedback';
import Processing from './components/Processing';
import Submitted from './components/Submitted';
import TemplateList from './components/TemplateList';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import SubmitFeedback from './components/SubmitFeedback/SubmitFeedback';
import { Button, Divider, Drawer, Skeleton, notification } from 'antd';
import AppointmentStore from 'vs-recording/stores/appointment/AppointmentStore';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import useApi from 'misc/hooks/useApi';
import { AppointmentClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentClientDto';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import moment from 'moment';
import AppointmentForm from '../Schedule/AppointmentForm/AppointmentForm';
import { AppointmentListStore } from '../Schedule/AppointmentList/AppointmentListStore';
import useDrawer from 'misc/hooks/useDrawer';
import { AppointmentCreateDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto';
import useVSHub from 'misc/hooks/useVSHub';
import LoadingOverlay from 'app/components/LoadingOverlay/LoadingOverlay';
import { LanguageEnumList } from 'api/model/User/Language';
import { getLangLabel } from 'misc/helpers/getLangLabel';
import styles from './Recording.module.scss';

type RecordingProps = {
    appointmentId: number;
    listStore: AppointmentListStore;
    onDeleteOrBack: () => void;
};

const Recording: React.FC<RecordingProps> = observer(({ appointmentId, listStore, onDeleteOrBack }) => {
    const api = useApi();
    const user = useCurrentUser();
    const [stage, setStage] = useState<'template' | 'draft' | 'submitted' | 'processing' | 'editing'>('template');
    const store = useLocalObservable<AppointmentStore>(() => new AppointmentStore(api, user));
    const [appointment, setAppointment] = useState<AppointmentClientDto>();
    const [feedback, setFeedback] = useState<AppointmentFeedbackClientDto>();
    const appointmentDrawer = useDrawer<AppointmentCreateDto>();
    const hub = useVSHub();

    useEffect(() => {
        if (!hub.connected || !feedback?.id) return;
        hub.subscribe(`admin-feedback-updates-${feedback?.id}`);
        hub.on<string>(`update-feedback-${feedback?.id}`, updateFeedbackFromHub)
        return () => {
            hub.unsubscribe(`admin-feedback-updates-${feedback?.id}`);
            hub.off<string>(`update-feedback-${feedback?.id}`)
        }
    }, [hub.connected, feedback?.id]);

    useEffect(() => {
        const originalTitle = document.title;
        if (appointment)
            document.title = `Recording | ${appointment.patientName}`;

        return () => {
            document.title = originalTitle;
        };
    }, [appointment]);

    const updateFeedbackFromHub = useCallback(async (data: string) => {
        var res = JSON.parse(data) as AppointmentFeedbackClientDto;
        if (feedback?.id == res.id)
            setFeedback(prevFb => ({ ...prevFb, ...res }));
    }, [feedback?.id]);

    useEffect(() => {
        load();
    }, [appointmentId]);

    useEffect(() => {
        store.error && notification.error({ message: store.error, duration: 2 });
    }, [store.error]);

    useEffect(() => {
        setAppointment(store.appointment);
        setFeedback(store.appointment?.feedback);
    }, [store.appointment, store.appointment?.feedback]);

    const load = useCallback(async () => {
        if (!appointmentId) return;
        await store.load(appointmentId);
        setAppointment(store.appointment);
        setFeedback(store.appointment?.feedback)
    }, [appointmentId]);

    useEffect(() => {
        if (!feedback) return;

        if (feedback.templateId == null) {
            setStage('template');
            return;
        }
        switch (feedback.status) {
            case AppointmentFeedbackStatus.Processing:
            case AppointmentFeedbackStatus.ReadyToProcess:
                setStage('processing');
                break;
            case AppointmentFeedbackStatus.MarkupDone:
            case AppointmentFeedbackStatus.Markup:
            case AppointmentFeedbackStatus.Completed:
                setStage('submitted');
                break;
            case AppointmentFeedbackStatus.EditingInTheApp:
                setStage('editing');
                break;
            case AppointmentFeedbackStatus.Draft:
            case AppointmentFeedbackStatus.PrepocessingDone:
            default:
                setStage('draft');
        }
    }, [feedback, feedback?.templateId, feedback?.status]);

    const changeTemplate = useCallback(() => {
        setStage('template');
    }, []);

    const setSelectedTemplate = useCallback(async (id: number) => {
        if (((id == appointment?.feedback?.templateId))) {
            setStage('draft');
        } else {
            await store.setTemplateId(id);
        }
    }, [store, appointment?.feedback?.templateId]);

    const editAppointment = useCallback(async (a: AppointmentCreateDto) => {
        try {
            await listStore.createAppointment(a);
            await load();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    }, []);

    return <AppointmentStoreContext.Provider value={store}>
        <header className='flex align-center justify-between mb-10'>
            <div className='flex align-center gap-5'>
                <Button
                    size='large'
                    onClick={() => onDeleteOrBack()}
                    disabled={store?.isRecording}
                    className='mr-10 flex flex-col fs-14' style={{ height: 'fit-content' }}>
                    <span className='lh-14'>Back</span>
                    <span className='lh-14'>to Schedule</span>
                </Button>
                <div className='flex flex-col'>
                    <div className='color-white fw-500 fs-14'>
                        {moment(appointment?.date).format('LLL')}
                        {!Number.isNaN(appointment?.language) && <span> &#183; {getLangLabel(appointment?.language)}</span>}
                    </div>
                    <div className='flex align-center'>
                        {feedback?.status == AppointmentFeedbackStatus.Draft
                            && <a className={styles.orangeLink} type='link' onClick={() => appointmentDrawer.open(appointment)}>Change</a>}
                    </div>

                </div>
            </div>
            <div className='flex flex-col'>
                {appointment?.patientName && <span className='color-white fs-16 fw-500'>{appointment.patientName}</span>}
                {appointment?.clientName && <span className='color-white fs-14 fw-500'>{appointment.clientName}</span>}
            </div>
        </header>
        <Divider style={{ background: '#ccc' }} />

        <LoadingOverlay spinning={store.loading}>
            <div>
                {(stage == 'template' || stage == 'draft') && feedback?.template != null
                    ? <div className='flex align-center justify-between'>
                        <div className='flex flex-col color-white'>
                            <span>Template used</span>
                            <span className='fw-500 fs-18'>{feedback.template?.name}</span>
                        </div>

                        {stage == 'template'
                            ? <Button disabled={store!.isRecording} size='small' onClick={() => setStage('draft')}>Back</Button>
                            : <Button disabled={store!.isRecording} size='small' onClick={changeTemplate}>Template</Button>
                        }
                    </div>
                    : null
                }

                {stage == 'template' &&
                    <TemplateList selectedId={feedback?.templateId} onTemplateSelected={x => {
                        setSelectedTemplate(x.id);
                    }} />
                }

                {stage == 'draft' &&
                    <SubmitFeedback onDelete={() => {
                        store.deleteAppointment();
                        onDeleteOrBack();
                    }} />
                }

                {stage == 'processing' &&
                    <Processing feedback={feedback} />
                }

                {stage == 'editing' &&
                    <EditFeedback feedback={feedback} onDoneOrCancel={() => {
                        setStage('submitted');
                        load();
                    }} />
                }

                {stage == 'submitted' &&
                    <Submitted feedback={feedback} onEdit={() => setStage('editing')} onBack={onDeleteOrBack} onResubmit={() => setStage('draft')} />
                }
            </div>
        </LoadingOverlay>

        <Drawer open={appointmentDrawer.visible}
            destroyOnClose
            title='New Appointment'
            onClose={appointmentDrawer.close}
            width={600}>
            <AppointmentForm appointment={appointmentDrawer.data}
                onCancel={appointmentDrawer.close}
                onDone={(a) => {
                    editAppointment(a);
                    appointmentDrawer.close();
                }} />
        </Drawer>
    </AppointmentStoreContext.Provider >;
});

export default Recording;
