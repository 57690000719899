import { Select } from "antd";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useEffect, useState } from "react"

const DoctorSelect: React.FC<{ value?: number, onChange: (x: any) => void }> = ({ value, onChange }) => {
	const api = useApi();
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState("");
	const user = useCurrentUser();

	if (user.isRestrictedUser) return null;

	useEffect(() => {
		load()
	}, [search])

	const load = async () => {
		var res = await api.adminDoctor.list({
			page: 1,
			pageSize: 20,
			search: search,
			isRemoved: false,
			sortField: "LastName",
			sortDirection: "asc"
		})
		setItems(res.data);
	}


	return <Select placeholder="Doctor" style={{ width: 150 }} allowClear value={value} onChange={(x) => onChange(x)} onSearch={setSearch} showSearch filterOption={false}>
		{items.map(x => <Select.Option key={x.id} value={x.id}>{x.lastName} {x.firstName}</Select.Option>)}
	</Select>
}
export default DoctorSelect;