import { CheckOutlined, UndoOutlined } from '@ant-design/icons'
import { Alert, Button, Popconfirm, Skeleton, Space, Tag, notification } from 'antd'
import { OutputType } from 'api/model/Appointments/AppointmentFeedback'
import { AdminFeedbackDto, ProcessingStatus } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto'
import useApi from 'misc/hooks/useApi'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import ClientNameForm from './components/ClientNameForm'
import EmailForm from './components/EmailForm'

const EmailTemplateTab: React.FC<{ feedback: AdminFeedbackDto, onClientNameChanged?: () => void }> = ({ feedback, onClientNameChanged }) => {
    const api = useApi();

    const isGenerating = useMemo(() => feedback.feedbackOutput.clientEmailStatus === ProcessingStatus.Processing
        || feedback.feedbackOutput.clientEmailStatus === ProcessingStatus.Pending, [feedback.feedbackOutput.clientEmailStatus]);

    const [editNameMode, setEditNameMode] = useState(false);

    const generateClientEmail = useCallback(async () => {
        if (!feedback.id) return
        try {
            await api.adminFeedback.generateOutput(feedback.id, OutputType.ClientEmail)
            notification.warning({ message: 'Email template is generating, wait please', duration: 4 })
        } catch (error) {
            notification.error({ message: 'Failed to regenerate Email template', duration: 2 })
        } finally {
            setEditNameMode(false);
        }
    }, [feedback.id])

    const sendEmail = useCallback(async (email: string, subject?: string, body?: string, ccEmails?: string[]) => {
        if (!email) {
            notification.error({ message: 'Client email is required' })
            return
        }
        try {
            await api.adminFeedback.sendClientEmail(feedback.id, email, subject, body, ccEmails);
            notification.success({ message: 'Email sent successfully' })
        } catch (error) {
            notification.error({ message: 'Failed to send email' })
        }
    }, [feedback.id])

    const appendClientName = useCallback(async (firstName: string, lastName?: string) => {
        if (!feedback.id) return
        try {
            await api.adminFeedback.appentClientName(feedback.id, firstName, lastName, true)
            notification.success({ message: 'Client name appended' })
            onClientNameChanged?.()
        } catch (error) {
            notification.error({ message: 'Failed to append client name' })
        } finally {
            setEditNameMode(false);
        }
    }, [feedback.id, generateClientEmail])

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = html
        const textToCopy = tempDiv.innerText

        await navigator.clipboard.writeText(textToCopy)
        notification.success({ message: 'Email template copied to clipboard as text' })
    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = html

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        })
        await navigator.clipboard.write([clipboardItem])

        notification.success({ message: 'Email template copied to clipboard with formatting' })
    }, []);

    if (isGenerating) return <div>
        <div className='flex align-center justify-between mb-20'>
            <div className='flex align-center gap-10'>
                <Button disabled>Copy w/ formatting</Button>
                <Button disabled>Copy as text</Button>
                {feedback.isClientEmailSent &&
                    <Tag color='green'>
                        Email sent&nbsp;<CheckOutlined />
                        {feedback.clientEmailSentDate && <span>{moment(feedback.clientEmailSentDate).format('LLL')}</span>}
                    </Tag>}
                <Space>
                    <Button disabled type='link' icon={<UndoOutlined />}>Regenerate</Button>
                </Space>
            </div>
        </div>
        <Skeleton active />
    </div>

    if (editNameMode)
        return <div className='flex flex-col align-center gap-10'>
            {!editNameMode && <Alert message='Client name is required to generate email template' type='warning' showIcon />}
            <ClientNameForm feedback={feedback}
                onSubmit={appendClientName}
                onCancel={() => setEditNameMode(false)} />
        </div>

    return <div>
        <div className='flex align-center justify-between mb-20'>
            <div className='flex align-center gap-10'>
                <Button onClick={() => copyHtmlToClipboard(feedback.feedbackOutput.clientEmail || '')}>Copy w/ formatting</Button>
                <Button onClick={() => copyTextToClipboard(feedback.feedbackOutput.clientEmail || '')}>Copy as text</Button>
                {feedback.isClientEmailSent &&
                    <Tag color='green'>
                        Email sent&nbsp;<CheckOutlined />
                        {feedback.clientEmailSentDate && <span>{moment(feedback.clientEmailSentDate).format('LLL')}</span>}
                    </Tag>}
            </div>
            <Popconfirm title="Are you sure to regenerate email template? All edits will be lost."
                onConfirm={generateClientEmail} okText="Yes" cancelText="No">
                <Button type='link' icon={<UndoOutlined />}>Regenerate</Button>
            </Popconfirm>
        </div>
        <EmailForm feedback={feedback} onSend={(email, subject, body, ccEmails) => sendEmail(email, subject, body, ccEmails)} onNameEdited={() => setEditNameMode(true)} />
    </div>
}

export default EmailTemplateTab;