import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { NotificationApi } from "antd/lib/notification";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AdminAppointmentFeedbackListRequest } from "api/routes/Appointments/Feedback/Model/AdminAppointmentFeedbackListRequest";
import { AppointmentFeedbackStatus } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { AdminAppointmentFeedbackHistoryListRequest } from "api/routes/Appointments/Feedback/Model/AdminAppointmentFeedbackHistoryListRequest";
import { AdminFeedbackHistoryDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackHistoryDto";

export class FeedbackHistoryStore {
	constructor(private api: ApiClient, private feedbackId: number, p?: Partial<FeedbackHistoryStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	loading: boolean = false;
	error: string | null = null;

	filter: AdminAppointmentFeedbackHistoryListRequest = {
		editorId: null,
		page: 1,
		pageSize: 25,
		sortField: "CreatedDate",
		sortDirection: "desc"
	}

	pagedData: PagingResponse<AdminFeedbackHistoryDto> | null = null;
	notificationApi?: NotificationApi;

	setState = (p: Partial<FeedbackHistoryStore>) => {
		Object.assign(this, p);
	};

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminFeedback.history(this.feedbackId, this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter, sortField: r.sortField, sortDirection: r.sortDirection, page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.message });
		}
		this.setState({ loading: false });
	};


	setFilter = (p: Partial<AdminAppointmentFeedbackListRequest>) => {
		Object.assign(this.filter, p);
		this.load();
	};

	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: shouldResetPage ? 1 : page,
			pageSize: pageSize
		})
	}


	setSearch = debounce((search: string | null) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: null })
	}
}
