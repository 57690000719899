// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pntipioUZchFXGVp_Bxg{color:#c28d4b}.J_3XmhtQSdagSMSOthJ1{background-color:#c28d4b}.rh1c7NIb180q63_E3cZv{padding:20px;margin:0 auto;width:800px;border-radius:25px;background:#3a302e}@media screen and (max-width: 840px){.rh1c7NIb180q63_E3cZv{width:500px}}@media screen and (max-width: 500px){.rh1c7NIb180q63_E3cZv{width:300px}}", "",{"version":3,"sources":["webpack://./src/vs-recording/styles/colors.scss","webpack://./src/vs-recording/VSRecording.module.scss"],"names":[],"mappings":"AAkCA,sBACC,aAfQ,CAkBT,sBACC,wBAnBQ,CClBT,sBACI,YAAA,CACA,aAAA,CACA,WAAA,CACA,kBAAA,CACA,kBDPK,CCUT,qCACI,sBACI,WAAA,CAAA,CAIR,qCACI,sBACI,WAAA,CAAA","sourcesContent":["$mainBG: #3a302e;\r\n$lightMainBg: #D0C7BC;\r\n$black: #000;\r\n$tintColorLight: #2f95dc;\r\n$tintColorDark: #fff;\r\n\r\n$white: #fff;\r\n$lightGrey: #ccc;\r\n$grey: #707070;\r\n$textWhite: #fff;\r\n$buttonBg: #C28D4B;\r\n$buttonBgHover: #9e733d;\r\n$red: #D55F4A;\r\n$redDarker: #AD4836;\r\n$blue: #0958d9;\r\n$lightBlue: #91caff;\r\n\r\n$purple: #814341;\r\n$dirtyGreen: #6A5E3E;\r\n$green: #4caf50;\r\n$orange: #C28D4B;\r\n$orangeDarker: #A38462;\r\n$greyGreen: #686B5B;\r\n\r\n$disabledBG: #CEC4B8;\r\n$secondaryText: #B28A75;\r\n\r\n// breed colors\r\n$patientDog: #D55F4A;\r\n$patientCat: #814341;\r\n$patientLizard: #6A5E3E;\r\n$patientBird: #C28D4B;\r\n$patientSnake: #686B5B;\r\n\r\n.text-color-orange {\r\n\tcolor: $orange\r\n}\r\n\r\n.bg-color-orange {\r\n\tbackground-color: $orange\r\n}","@import './styles/colors.scss';\r\n\r\n.app_wrapper {\r\n    padding: 20px;\r\n    margin: 0 auto;\r\n    width: 800px;\r\n    border-radius: 25px;\r\n    background: $mainBG;\r\n}\r\n\r\n@media screen and (max-width: 840px) {\r\n    .app_wrapper {\r\n        width: 500px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 500px) {\r\n    .app_wrapper {\r\n        width: 300px;\r\n    }\r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-color-orange": "pntipioUZchFXGVp_Bxg",
	"bg-color-orange": "J_3XmhtQSdagSMSOthJ1",
	"app_wrapper": "rh1c7NIb180q63_E3cZv"
};
export default ___CSS_LOADER_EXPORT___;
