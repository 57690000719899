import { useState, useEffect } from 'react';

function useDevice() {
  const [deviceInfo, setDeviceInfo] = useState({
    width: window.innerWidth,
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth >= 768 && window.innerWidth <= 1024,
    isDesktop: window.innerWidth > 1024,
    os: determineOS() // Вызываем функцию определения ОС сразу при инициализации
  });

  useEffect(() => {
    const handleResize = () => {
      setDeviceInfo({
        width: window.innerWidth,
        isMobile: window.innerWidth < 768,
        isTablet: window.innerWidth >= 768 && window.innerWidth <= 1024,
        isDesktop: window.innerWidth > 1024,
        os: deviceInfo.os // Сохраняем предыдущее значение ОС
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Функция определения операционной системы
  function determineOS() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    } else if (/win/i.test(userAgent)) {
      return 'windows';
    } else if (/mac/i.test(userAgent)) {
      return 'macOS';
    } else if (/linux/i.test(userAgent)) {
      return 'linux';
    }
    return 'unknown';
  }

  return { device: deviceInfo };
}

export default useDevice;
