import React, { useRef, useCallback, useState } from 'react';
import { Button, Tooltip, notification } from 'antd';
import { observer } from 'mobx-react';
import { CopyOutlined, FileWordOutlined } from '@ant-design/icons';

type CopiableDivProps = {
    children: React.ReactNode;
    className?: string;
    wrapperStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    mode?: 'block' | 'inline';
}

const CopiableDiv: React.FC<CopiableDivProps> = observer(({ children, className, wrapperStyle, buttonStyle, mode = 'block' }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [buttonsVisible, setButtonsVisible] = useState(false);

    const copyTextToClipboard = useCallback(async (text: string) => {
        await navigator.clipboard.writeText(text);
        notification.success({ message: 'Content is copied to clipboard' });
    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([html], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);
        notification.success({ message: 'Content is copied to clipboard' });
    }, []);

    const handleCopyText = useCallback(() => {
        if (contentRef.current) {
            copyTextToClipboard(contentRef.current.innerText.replace(/\n\n/g, '\n'));
        }
    }, [copyTextToClipboard]);

    const handleCopyHtml = useCallback(() => {
        if (contentRef.current) {
            copyHtmlToClipboard(contentRef.current.innerHTML);
        }
    }, [copyHtmlToClipboard]);

    if (mode == 'inline')
        return <span className={className}
            style={{ lineHeight: '1.2', position: 'relative', background: buttonsVisible ? 'rgba(194, 141, 75, 0.37)' : 'transparent', transition: 'background 0.2s', ...wrapperStyle }}
            onMouseEnter={() => setButtonsVisible(true)}
            onMouseLeave={() => setButtonsVisible(false)}>
            
            <span ref={contentRef}>
                {children}
            </span>

            {
                buttonsVisible && 
                <div style={{
                    opacity: 1,
                    padding: 10,
                    zIndex: 2,
                    top: 'calc(100% - 3px)',
                    position: 'absolute',
                    transition: 'opacity 0.2s',
                    display: 'flex',
                    gap: 10,
                    ...buttonStyle
                }}
                    className='copiable-button'>
                        <Button type='dashed' icon={<FileWordOutlined />}  title='Copy w/ format'  onClick={handleCopyHtml} >Copy w/ formatting</Button>
                        <Button type='dashed' icon={<CopyOutlined />} onClick={handleCopyText} > Copy as text </Button>
                </div>
            }
        </span>

    return <div
        className={className}
        style={{ background: buttonsVisible ? 'rgba(194, 141, 75, 0.37)' : 'transparent', transition: 'background 0.2s', position: 'relative', ...wrapperStyle }}
        onMouseEnter={() => setButtonsVisible(true)}
        onMouseLeave={() => setButtonsVisible(false)}>
        <span ref={contentRef}>{children}</span>
        <div
            className='flex align-center gap-5 copiable-button'
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                opacity: buttonsVisible ? 1 : 0,
                transition: 'opacity 0.2s',
                ...buttonStyle
            }}>
            <Button onClick={handleCopyHtml}>Copy w/ formatting</Button>
            <Button onClick={handleCopyText}>Copy as text</Button>
        </div>
    </div>;
});

export default CopiableDiv;
