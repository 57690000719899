import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { openDB, IDBPDatabase } from 'idb';

interface AudioDB {
	audios: {
		key: string;
		value: {
			audioData: AppointmentFeedbackAudio & { isInProgress: boolean };
			blob: Blob | null;
		};
	};
}

class AudioFileStorage {
	private static dbPromise: Promise<IDBPDatabase<AudioDB>>;

	private static getDB() {
		if (!this.dbPromise) {
			this.dbPromise = openDB<AudioDB>('VS-audio-file-storage', 1, {
				upgrade(db) {
					db.createObjectStore('vs_audios', { keyPath: 'key' });
				},
			});
		}
		return this.dbPromise;
	}

	public static feedbackNoteKey(noteId: number, timestamp: number): string {
		return `audio_${noteId}:${timestamp}`;
	}

	public static async getAllExistingStorage(): Promise<string[]> {
		const db = await this.getDB();
		const allKeys = await db.getAllKeys('vs_audios');
		return allKeys as string[];
	}

	public static async getAudios(noteId: number): Promise<AppointmentFeedbackAudio[]> {
		const db = await this.getDB();
		const audios = await db.getAll('vs_audios');
		return audios.filter((audio) => audio.audioData.appointmentFeedBackNoteId === noteId && !audio.audioData.isInProgress).map((audio) => audio.audioData);
	}

	public static async saveAudios(audios: { uri: string; blob: Blob; isInProgress: boolean; appointmentFeedBackNoteId?: number; appointmentFeedBackId?: number; appointmentFeedBackField?: number }[]): Promise<void> {
		const db = await this.getDB();
		const tx = db.transaction('vs_audios', 'readwrite');
		for (const audio of audios) {
			const key = audio.uri;
			await tx.store.put({ key, audioData: audio, blob: audio.blob });
		}
		await tx.done;
	}

	public static async checkAvailableSpace(): Promise<number | null> {
		return null;
	}

	public static async deleteAudios(audios: AppointmentFeedbackAudio[]): Promise<void> {
		const db = await this.getDB();
		const tx = db.transaction('vs_audios', 'readwrite');
		for (const audio of audios) {
			if (audio.uri) {
				await tx.store.delete(audio.uri);
			}
		}
		await tx.done;
	}

	public static async clearStorage() {
		const db = await this.getDB();
		await db.clear('vs_audios');
	}

	public static async fileExists(uri: string): Promise<boolean> {
		const db = await this.getDB();
		const file = await db.get('vs_audios', uri);
		return !!file;
	}

	public static async saveByKey(key: string, audioData: { blob: Blob | null; uri: string, isInProgress: boolean; appointmentFeedBackNoteId?: number, appointmentFeedBackId?: number }): Promise<void> {
		const db = await this.getDB();
		await db.put('vs_audios', { key, audioData, blob: audioData.blob });
	}

	public static async updateBlobByKey(key: string, blob: Blob): Promise<void> {
		const db = await this.getDB();
		const audio = await db.get('vs_audios', key);
		if (audio) {
			audio.blob = blob;
			await db.put('vs_audios', audio);
		}
	}

	public static async getBlobByKey(key: string): Promise<Blob | null> {
		const db = await this.getDB();
		const audio = await db.get('vs_audios', key);
		return audio ? audio.blob : null;
	}

	public static async getByKey(key: string): Promise<AppointmentFeedbackAudio | null> {
		const db = await this.getDB();
		const result = await db.get('vs_audios', key);
		return result ? result.audioData : null;
	}

	public static async getAudioBlob(uri: string): Promise<Blob | null> {
		const db = await this.getDB();
		const audio = await db.get('vs_audios', uri);
		return audio ? audio.blob : null;
	}

	public static async deleteByKey(key: string): Promise<void> {
		const db = await this.getDB();
		await db.delete('vs_audios', key);
	}

	public static async updateProgressByKey(key: string, isInProgress: boolean): Promise<void> {
		const db = await this.getDB();
		const audio = await db.get('vs_audios', key);
		if (audio) {
			audio.audioData.isInProgress = isInProgress;
			await db.put('vs_audios', audio);
		}
	}

	public static async deleteTemporaryBlobs(noteId: number) {
		const db = await this.getDB();
		const tx = db.transaction('vs_audios', 'readwrite');
		const keys = await tx.store.getAllKeys();
		for (const key of keys) {
			if (typeof key === 'string' && key.startsWith(`temp_${noteId}_`)) {
				await tx.store.delete(key);
			}
		}
		await tx.done;
	}
}

export default AudioFileStorage;
