import { Template } from "api/model/Template/Template";
import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";
import { PagingResponse } from "../../base/Paging";
import { TemplateListRequest } from "./Model/TemplateListRequest";
import { TemplateUpdateRequest } from "./Model/TemplateUpdateRequest";
import { TemplateCreateDto } from "./Model/TemplateCreateDto";

export default class AdminTemplateApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/template";

	async list(req: TemplateListRequest) {
		const request = await this.api.client.post<PagingResponse<Template>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<Template>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(x: Template) {
		const request = await this.api.client.delete<NiceResponse<Template>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

	async updateCreate(req: TemplateCreateDto) {
		const request = await this.api.client.post<NiceResponse<Template>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async restore(x: Template) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

	async duplicate(x: Template) {
		const request = await this.api.client.post<NiceResponse<Template>>(`${this.baseUrl}/duplicate/${x.id}`);
		return request.data;
	}

	async export(x: Template) {
		const request = await this.api.client.get<Template>(`${this.baseUrl}/export/${x.id}`);
		return request.data;
	}

	async import(x: Template) {
		const request = await this.api.client.post<Template>(`${this.baseUrl}/import`, {...x});
		return request.data;
	}

}