export enum LanguageEnum {
	Auto = 100,
	English = 0,
	French = 1,
	German = 2,
	Spanish = 3,
}


export enum LanguageEnumExtended {
	English = 0,
	French = 1,
	German = 2,
	Spanish = 3,
	Hindi = 4,
	Japanese = 5,
	Russian = 6,
	Ukrainian = 7,
	Swedish = 8,
	Chinese = 9,
	Portuguese = 10,
	Dutch = 11,
	Turkish = 12,
	Indonesian = 13,
	Korean = 14,
	Italian = 15,
	Auto = 100
}

export const LanguageEnumList = [
	{ value: LanguageEnum.Auto, label: 'Auto (experimental)' },
	{ value: LanguageEnum.English, label: 'English' },
	{ value: LanguageEnum.French, label: 'French' },
	{ value: LanguageEnum.German, label: 'German' },
	{ value: LanguageEnum.Spanish, label: 'Spanish' },
	{ value: LanguageEnumExtended.Swedish, label: 'Swedish' },
	{ value: LanguageEnumExtended.Dutch, label: 'Dutch' }
]

export const LanguageEnumExtendedList = [
	{ value: LanguageEnumExtended.Auto, label: 'Auto (experimental)' },
	{ value: LanguageEnumExtended.English, label: 'English' },
	{ value: LanguageEnumExtended.French, label: 'French' },
	{ value: LanguageEnumExtended.German, label: 'German' },
	{ value: LanguageEnumExtended.Spanish, label: 'Spanish' },
	{ value: LanguageEnumExtended.Hindi, label: 'Hindi' },
	{ value: LanguageEnumExtended.Japanese, label: 'Japanese' },
	{ value: LanguageEnumExtended.Russian, label: 'Russian' },
	{ value: LanguageEnumExtended.Ukrainian, label: 'Ukrainian' },
	{ value: LanguageEnumExtended.Swedish, label: 'Swedish' },
	{ value: LanguageEnumExtended.Chinese, label: 'Chinese' },
	{ value: LanguageEnumExtended.Portuguese, label: 'Portuguese' },
	{ value: LanguageEnumExtended.Dutch, label: 'Dutch' },
	{ value: LanguageEnumExtended.Turkish, label: 'Turkish' },
	{ value: LanguageEnumExtended.Indonesian, label: 'Indonesian' },
	{ value: LanguageEnumExtended.Korean, label: 'Korean' },
	{ value: LanguageEnumExtended.Italian, label: 'Italian' },
]