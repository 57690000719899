import { Alert, Button } from 'antd';
import { UserRoleEnum } from 'api/model/User/UserRole';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';



const Access: React.FC<{ roles: UserRoleEnum[] }> = ({ roles }) => {
	const user = useCurrentUser();
	const nav = useNavigate();

	if (!user.isAuthorized) return <Navigate to="/auth/login" />;
	if (user.isAuthorized && !user.quickStartCompleted) return <Navigate to='/initial-setup' />;
	if (!user.isInRole(roles)) return <div style={{ width: '100%', maxWidth: "500px", margin: "10px auto" }}>
		<div>
			<Alert message='You do not have access to this page' />
		</div>

		<Button style={{ margin: "0 auto" }} onClick={() => nav("/auth/logout")}>Log out</Button>
	</div>

	return <Outlet />
}

export default observer(Access);