import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { NotificationInstance } from "antd/es/notification/interface";
import { UserReport } from "api/model/Analytics/UserReport";
import { UserReportListRequest } from "api/routes/Analytics/Model/UserReportListRequest";
import moment from "moment";

export class UserReportStore {
	loading: boolean = false;
	error: string | null = null;

	filter: UserReportListRequest = {
		page: 1,
		pageSize: 100,
		search: null,
		sortField: "CreatedDate",
		sortDirection: "desc",
		userCreatedFrom: moment().startOf('month').toDate(),
		userCreatedTo:  moment().endOf('month').toDate(),
	}

	pagedData: PagingResponse<UserReport> | null = null;
	notificationApi?: NotificationInstance;

	constructor(private api: ApiClient, p?: Partial<UserReportStore>, autoLoad: boolean = true) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		if(autoLoad) this.load();
	}

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminAnalytics.userReport(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter,
					sortField: r.sortField,
					sortDirection: r.sortDirection,
					page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	};


	setState = (p: Partial<UserReportStore>) => {
		Object.assign(this, p);
	};

	setFilter = (p: Partial<UserReportListRequest>, shouldResetPage: boolean = true) => {
		Object.assign(this.filter, p);
		if (shouldResetPage)
			this.filter.page = 1;
		this.load();
	};

	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: page,
			pageSize: pageSize
		}, shouldResetPage)
	}

	setSearch = debounce((search: string | null) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: null })
	}
}
