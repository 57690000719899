import { ClientUpdateRequest } from "api/routes/Clients/Client/Model/ClientUpdateRequest";
import { Client } from "api/model/Clients/Client";
import { Alert, Input, Button, Form, Divider, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import OfficeSelect from "app/components/OfficeSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";


const DoctorEdit: React.FC<{ client: Client, onSuccess: () => void, onCancel: () => void }> = ({ client, onSuccess, onCancel }) => {
	const api = useApi();
	const currentUser = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });

	const submit = async (values: ClientUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = client.id;
			const res = await api.adminClient.updateCreate(values);
			if (res.success == true) {
				notification.success({ message: "Saved" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occured" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	if (!client) return null;

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={client || {}}  >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"First Name"} name="firstName">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Last Name"} name="lastName">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Office"} name="officeId">
			<OfficeSelect value={client.officeId ? client.officeId : null} onChange={(x: any) => form.setFieldsValue({ officeId: x })} />
		</Form.Item>

		<Divider />

		<Row justify="space-between">
			<Button type="ghost" onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form>
}

export default DoctorEdit;
