import { CloseOutlined, HolderOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Popconfirm, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { TemplateField, TemplateFieldType } from "api/model/Template/TemplateField";
import useApi from "misc/hooks/useApi";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";

const TemplateFieldEdit: React.FC<{
    templateField: TemplateField,
    onDeleteField: () => void,
    onUpdate: (f: TemplateField) => void
}> = ({ templateField, onDeleteField, onUpdate }) => {
        const api = useApi();
        const [form] = useForm();
        const [state, setState] = useState<TemplateField>(templateField);

        const updateField = useCallback((changes: Partial<TemplateField>) => {
            setState(prev => ({ ...prev, ...changes }));
            onUpdate({ ...state, ...changes });
        }, [state]);

        const deleteField = () => {
            onDeleteField();
        }

        return <div className="template-field">
            <HolderOutlined />
            <Space direction="vertical" style={{ width: '100%', flex: 1 }}>
                {templateField.type == TemplateFieldType.Separator && <div className="secondary-text">Separator</div>}

                <Input value={state.label} placeholder="Label" type="text" onChange={(e) => updateField({ label: e.currentTarget.value })} />

                {templateField.type == TemplateFieldType.Simple &&
                    <>
                        <Input value={state.defaultValue} placeholder="Default Content/Value" type="text" onChange={(e) => updateField({ defaultValue: e.currentTarget.value })} />
                        <div>
                            <span>Show abnormal in <b>bold</b>&nbsp;
                                <Checkbox checked={state.isBold} onChange={e => updateField({ isBold: e.target.checked })} />&nbsp;
                                UPPERCASE&nbsp;
                                <Checkbox checked={state.isUppercase} onChange={e => updateField({ isUppercase: e.target.checked })} />
                            </span>
                        </div>
                    </>
                }
            </Space>
            <Popconfirm title={"Are you sure want to delete field?"} onConfirm={deleteField}>
                <Button size="small" type={"default"}><CloseOutlined /></Button>
            </Popconfirm>
        </div>
    }

export default TemplateFieldEdit;
