import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";
import { PagingResponse } from "../../base/Paging";
import { Doctor } from "../../model/Offices/Doctor";
import { DoctorListRequest } from "./Model/DoctorListRequest";
import { DoctorUpdateRequest } from "./Model/DoctorUpdateRequest";



export default class AdminDoctorApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/doctor";

	async list(req: DoctorListRequest) {
		const request = await this.api.client.post<PagingResponse<Doctor>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<Doctor>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(x: Doctor) {
		const request = await this.api.client.delete<NiceResponse<Doctor>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

	async updateCreate(req: DoctorUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<Doctor>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async createDoctorWithUser(req: DoctorUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<Doctor>>(`${this.baseUrl}/create-user-doctor`, { ...req });
		return request.data;
	}

	async restore(x: Doctor) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

}