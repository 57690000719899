
const COLORS = {
	mainBG: "#3a302e",
	lightMainBg: "#D0C7BC",
	black: "#000",
	tintColorLight: '#2f95dc',
	tintColorDark: '#fff',

	white: '#fff',
	grey: '#707070',
	lightGrey: '#ccc',
	textWhite: "fff",
	buttonBg: "#C28D4B",
	buttonBgHover: "#9e733d",
	ligthRed: "#ff2600",
	red: "#D55F4A",
	redDarker: "#AD4836",
	blue: "#0958d9",
	lightBlue: "#91caff",

	purple: "#814341",
	dirtyGreen: "#6A5E3E",
	green: "#4caf50",
	orange: "#C28D4B",
	orangeDarker: "#A38462",
	greyGreen: "#686B5B",

	disabledBG: "#CEC4B8",
	secondaryText: "#B28A75",

	//breed colors
	patientDog: "#D55F4A",
	patientCat: "#814341",
	patientLizard: "#6A5E3E",
	patientBird: "#C28D4B",
	patientSnake: "#686B5B",
}

export { COLORS };