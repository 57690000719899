
import ApiClient from "../../../base/ApiClient";
import { NiceResponse } from "../../../base/NiceResponse";
import { PagingResponse } from "../../../base/Paging";
import { Client } from "../../../model/Clients/Client";
import { ClientListRequest } from "./Model/ClientListRequest";
import { ClientUpdateRequest } from "./Model/ClientUpdateRequest";


export default class AdminClientApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/client";

	async list(req: ClientListRequest) {
		const request = await this.api.client.post<PagingResponse<Client>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<Client>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(x: Client) {
		const request = await this.api.client.delete<NiceResponse<Client>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

	async updateCreate(req: ClientUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<Client>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async restore(x: Client) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

}