import { Alert, Breadcrumb, Button, Divider, Input, notification, Popconfirm, Space, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DeleteTwoTone } from "@ant-design/icons";
import React from "react";
import { User } from "api/model/User/User";
import { VoiceWordStore } from "./VoiceWordStore";
import { VoiceWord } from "api/model/VoiceWord/VoiceWord";
import useGeneralState from "misc/hooks/useGeneralState";
import VoiceWordAdd from "./VoiceWordAdd";


const VoiceWordList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<VoiceWordStore>(() => new VoiceWordStore(api));
	const generalState = useGeneralState();

	const [creatingNew, setCreatingNew] = React.useState(false);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<User> | SorterResult<User>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<VoiceWord> = [
		{
			title: 'Word',
			key: 'Word',
			ellipsis: true,
			sorter: true,
			render: (x: VoiceWord) => <>
				{x.word}
			</>
		},
		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: VoiceWord) =>
				x.createdDate ?
					<span>{moment(x.createdDate).format("DD MMM yyyy HH:mm:ss")}</span>
					:
					<span className="secondary-text">No data</span>

		},
		{
			title: 'Actions',
			align: "right",
			render: (x: VoiceWord) => <Space key={x.id}>
				<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
					<Button icon={<DeleteTwoTone />} />
				</Popconfirm>
			</Space>
		},
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Words for Speech recognition
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<Button onClick={() => setCreatingNew(true)}>Add new</Button>
			<VoiceWordAdd store={store} creating={creatingNew} onClose={() => setCreatingNew(false)} />
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}
	</>
})


export default VoiceWordList;




