// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".boxPc80tqVsuvmy1UfPI{z-index:1000;width:50px;height:50px}.boxPc80tqVsuvmy1UfPI img{width:100%}.ant-spin-dot{width:60px !important;height:60px !important;inset-inline-start:47.5% !important;animation:ekjKU18xQcRHLioZSN_1 1s infinite}.ant-spin-nested-loading{transition:fade-in-out .5s}@keyframes ekjKU18xQcRHLioZSN_1{0%{transform:scale(1);opacity:1}50%{transform:scale(1.2);opacity:.7}100%{transform:scale(1);opacity:1}}@keyframes cPiimFHbIDnm_kBe4pF9{0%{opacity:0}30%{opacity:1}100%{opacity:0}}", "",{"version":3,"sources":["webpack://./src/app/components/LoadingOverlay/LoadingOverlay.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,UAAA,CACA,WAAA,CAEA,0BACI,UAAA,CAKR,cACI,qBAAA,CACA,sBAAA,CACA,mCAAA,CACA,0CAAA,CAGJ,yBACI,0BAAA,CAGJ,gCACI,GACI,kBAAA,CACA,SAAA,CAGJ,IACI,oBAAA,CACA,UAAA,CAGJ,KACI,kBAAA,CACA,SAAA,CAAA,CAIR,gCACI,GACI,SAAA,CAGJ,IACI,SAAA,CAGJ,KACI,SAAA,CAAA","sourcesContent":[".loadingIndicator {\r\n    z-index: 1000;\r\n    width: 50px;\r\n    height: 50px;\r\n\r\n    img {\r\n        width: 100%;\r\n    }\r\n}\r\n\r\n\r\n:global(.ant-spin-dot) {\r\n    width: 60px !important;\r\n    height: 60px !important;\r\n    inset-inline-start: 47.5% !important;\r\n    animation: pulse 1s infinite;\r\n}\r\n\r\n:global(.ant-spin-nested-loading) {\r\n    transition: fade-in-out 0.5s;\r\n}\r\n\r\n@keyframes pulse {\r\n    0% {\r\n        transform: scale(1);\r\n        opacity: 1;\r\n    }\r\n\r\n    50% {\r\n        transform: scale(1.2);\r\n        opacity: 0.7;\r\n    }\r\n\r\n    100% {\r\n        transform: scale(1);\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n@keyframes fade-in-out {\r\n    0% {\r\n        opacity: 0;\r\n    }\r\n\r\n    30% {\r\n        opacity: 1;\r\n    }\r\n\r\n    100% {\r\n        opacity: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingIndicator": "boxPc80tqVsuvmy1UfPI",
	"pulse": "ekjKU18xQcRHLioZSN_1",
	"fade-in-out": "cPiimFHbIDnm_kBe4pF9"
};
export default ___CSS_LOADER_EXPORT___;
