
import WizardStatus from "api/model/Account/WizardStatus";
import ApiClient from "../../base/ApiClient";
import { PagingResponse } from "../../base/Paging";
import { AccountListRequest } from "./Model/AccountListRequest";
import { AccountSimple } from "api/model/Account/AccountSimple";
import { Account } from "api/model/Account/Account";


export default class AdminAccountApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/account";


	async allAccounts(req: AccountListRequest) {
		const request = await this.api.client.post<PagingResponse<Account>>(`${this.baseUrl}/all-accounts`, { ...req });
		return request.data;
	}

	async listSimple(req: AccountListRequest) {
		const request = await this.api.client.post<PagingResponse<AccountSimple>>(`${this.baseUrl}/listSimple`, { ...req });
		return request.data;
	}

	async quickStartStatus(id: number) {
		const request = await this.api.client.get<WizardStatus>(`${this.baseUrl}/quick-start/${id}`);
		return request.data;
	}

	async quickStartComplete(id: number) {
		const request = await this.api.client.get<WizardStatus>(`${this.baseUrl}/quick-start-complete/${id}`);
		return request.data;
	}

	async deleteAccount(id: number) {
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/delete/${id}`);
		return request.data;
	}

}