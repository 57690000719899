import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { makeAutoObservable } from "mobx";
import { BillingFilter, BillingGroupingPeriod } from "api/routes/Billing/Model/BillingFilter";
import moment from "moment";
import { BillingDto } from "api/routes/Billing/Model/BillingDto";

export class BillingStore {
	loading: boolean = false;
	error: string | null = null;

	filter: BillingFilter = {
		period: BillingGroupingPeriod.Week,
		dateFrom: moment().utc().startOf("month").toDate(),
		dateTo: moment().utc().endOf("month").toDate(),
		practiceId: null
	}

	pagedData: PagingResponse<BillingDto> | null = null;
	notificationApi?: Notification;

	constructor(private api: ApiClient, p?: Partial<BillingStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminBillingApi.report(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter,
				}
			});
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	};


	setState = (p: Partial<BillingStore>) => {
		Object.assign(this, p);
	};

	setFilter = (p: Partial<BillingFilter>, shouldResetPage: boolean = true) => {
		Object.assign(this.filter, p);
		this.load();
	};

}
