import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import React, { useRef, useState, useEffect } from "react";
import MessageListStore from "./MessageListStore";
import { SendOutlined } from "@ant-design/icons"
import { Button, Spin, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { OneMessage } from "./OneMessage";
import "./messages.scss"
import useVSHub from "misc/hooks/useVSHub";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { FeedbackMessageClientDto } from "api/routes/Appointments/Feedback/DTO/FeedbackMessageClientDto";
import { UserRoleEnum } from "api/model/User/UserRole";


const MessageList: React.FC<{ feedback: AdminFeedbackDto }> = observer(({ feedback }) => {
	const { id: feedbackId } = feedback;
	const api = useApi();
	const hub = useVSHub();
	const user = useCurrentUser();
	const store = useLocalObservable(() => new MessageListStore(api, feedbackId));
	const scrollRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)

	const [loading, setLoading] = useState(true);
	const [currentMessage, setCurrentMessage] = useState('');
	const [sendingMessage, setSendingMessage] = useState(false);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (scrollRef.current)
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
	}, [scrollRef.current]);

	useEffect(() => {
		if (!hub.connected) return;
		hub.chatIsRead(feedbackId)
		user.unreadChatRemove(feedbackId);
	}, [store.messages.length]);

	useEffect(() => {
		if (!hub.connected) return;

		hub.subscribe(`feedback-${feedbackId}`);
		hub.on<FeedbackMessageClientDto>(`chat-message-${feedbackId}`, (d: FeedbackMessageClientDto) => {
			store.addMessageToList(d);
			if (scrollRef.current)
				scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		})
		hub.on<number>(`chat-message-${feedbackId}-remove`, (d) => store.removeMessageFromList(d))

		return () => {
			hub.unsubscribe(`feedback-${feedbackId}`);
			hub.off<FeedbackMessageClientDto>(`chat-message-${feedbackId}`)
			hub.off<FeedbackMessageClientDto>(`chat-message-${feedbackId}-remove`)
		}
	}, [hub.connected]);


	const load = async () => {
		setLoading(true);
		await store.loadMessages();
		setLoading(false);
	}

	const sendMessage = async () => {
		if (currentMessage == "") return;
		setSendingMessage(true);
		await store.sendMessage(currentMessage);
		setCurrentMessage("");
		if (scrollRef.current)
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		setSendingMessage(false);
	}

	const removeMessage = async (id: number) => {
		try {
			await store.removeMessage(id);
		} catch (e) {
			notification.error(e)
		}
	}

	const canChat = user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor]);

	return <div >
		{loading ?
			<Spin /> :
			<div ref={scrollRef} style={{ width: "100%", flex: 1, maxHeight: 800, overflow: "auto" }}>
				{store.messages.map((message, i) =>
					<OneMessage message={message} key={message.id} even={i % 2 == 0} showDelete={i == store.messages.length - 1} onDelete={() => removeMessage(message.id)} />)}
			</div>
		}


		{canChat &&
			<div style={{ display: "flex", marginTop: 20 }}>
				<TextArea
					ref={inputRef}
					autoSize={true}
					onChange={(e) => setCurrentMessage(e.target.value)}
					placeholder="Enter message here"
					value={currentMessage}
				/>
				<Button icon={<SendOutlined />} onClick={sendMessage} disabled={sendingMessage} />
			</div>
		}


	</div>
})

export default MessageList;



