import { FeedbackComment } from "api/model/Appointments/FeedbackComment";
import { AppointmentFeedbackReviewTier } from "api/model/Appointments/enums/AppointmentFeedbackReviewTier";
import { AppointmentFeedbackStatus } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentFeedbackAudio } from "api/model/Audio/AppointmentFeedbackAudio";
import { AdminAppointmentDto } from "../../Appointment/DTO/AdminAppointmentDto";
import { AdminFeedbackNoteDto } from "./AdminFeedbackNoteDto";
import { Template } from "api/model/Template/Template";
import { AdminFeedbackOutputDto } from "./AdminFeedbackOutputDto";

export interface AdminFeedbackDto {
	id: number,
	createdDate: Date,
	status: AppointmentFeedbackStatus | null;
	appointmentId: number;
	appointment: AdminAppointmentDto;
	isSoap: boolean;
	soapSCC: string | null;
	soapSHPI: string | null;
	soapSMedications: string | null;
	soapSHistory: string | null;
	soapOVitalSigns: string | null;
	soapOGeneralObservations: string | null;
	soapOPhysicalExaminations: string | null;
	soapODiagnostic: string | null;
	soapADiagnosis: string | null;
	soapPDiagnostic: string | null;
	soapPTreatment: string | null;
	soapPEducation: string | null;
	soapPConsultation: string | null;
	soapPFollowUp: string | null;
	notes: AdminFeedbackNoteDto[];
	editorId: number | null;
	editorName: string | null;
	rating: number | null;
	isEditing: boolean;
	appointmentFeedbackAudio: AppointmentFeedbackAudio[];
	practiceName: string | null;
	practiceNumber: string | null;
	editors: string[],
	addedToPims: boolean;
	comments: FeedbackComment[],
	reviewTier: AppointmentFeedbackReviewTier,
	chatOpen: boolean;
	chatDoctorAttention: boolean;
	chatAgentAttention: boolean;
	chatLastMessageDate?: Date;
	submittedDate?: Date;
	templateId?: number | null;
	template?: Template
	ambientMode: boolean;
	useForTraining: boolean | null;
	feedbackOutput: AdminFeedbackOutputDto;
	isClientEmailSent: boolean;
	clientEmailSentDate?: Date;
}

export enum ProcessingStatus {
	Pending = 0,
	Processing = 1,
	Done = 2,
	ErrorProcessing = 3,
}
