import { Divider } from "antd";
import React from "react";
import styles from "./Details.module.scss";

export type DetailsProps = {
	data: {
		label?: string,
		value?: (() => React.ReactNode) | React.ReactNode | string,
		divider?: boolean,
		dividerText?: string,
		dividerPlacement?: "left" | "right" | "center",
		separator?: boolean,
		separatorText?: string,
		visible?: boolean
	}[]
	layout: "vertical" | "horizontal",
	labelWidth: number | string,
	style?: React.CSSProperties,
	styleItem?: React.CSSProperties,
}

const Details: React.FC<DetailsProps> = ({ data, layout, labelWidth, style, styleItem }) => {

	return <div style={style || {}} className={[styles.details, layout == "horizontal" ? styles.detailsH : styles.detailsV].join(" ")}>
		{data.map((x, i) => {
			if (x.visible == false) {
				return null;
			}
			if (x.divider) return <Divider orientation={x.dividerPlacement} key={i}>{x.dividerText}</Divider>
			if (x.separator) return <div className={styles.separator} key={i}>{x.separatorText}</div>
			return <div key={x.label + "" + i} className={styles.detailsItem} style={styleItem || {}}>
				<div className={styles.detailsTitle} style={{ width: labelWidth }}>{x.label}</div>
				<div className={styles.detailsValue}>{typeof x.value == "function" ? x.value() : x.value}</div>
			</div>
		})}
	</div >
}
export default Details;