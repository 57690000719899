import { Divider } from "antd"
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto"
import Details from "app/components/Details/Details"
import { observer } from "mobx-react"
import React from "react"
import { AppointmentFeedbackField } from "../../../../../mobile/api/model/Appointments/enums/AppointmentFeedBackFields"
import FeedbackAudioPlayer from "./FeedbackAudioPlayer"
import FeedbackComments from "./FeedbackComments"


const FeedbackSoapNoTemplate: React.FC<{ feedback: AdminFeedbackDto }> = observer(({ feedback }) => {
    return <>
        <Divider orientation="left" type="horizontal">SOAP</Divider>
        <Details layout="horizontal" labelWidth="200px"
            data={[
                {
                    label: '[S] Chief Complaint',
                    value: <>
                        {feedback.soapSCC || <span className='secondary-text'>No text</span>}

                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSCC),
                            appointmentFeedBackField: AppointmentFeedbackField.soapSCC,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />

                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSCC)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}


                    </>
                },
                {
                    label: '[S] History of Present Illness',
                    value: <>
                        {feedback.soapSHPI || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSHPI),
                            appointmentFeedBackField: AppointmentFeedbackField.soapSHPI,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSHPI)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}


                    </>
                },
                {
                    label: '[S] Current Medications',
                    value: <>
                        {feedback.soapSMedications || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSMedications),
                            appointmentFeedBackField: AppointmentFeedbackField.soapSMedications,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSMedications)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[S] Social History / Habits',
                    value: <>
                        {feedback.soapSHistory || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSHistory),
                            appointmentFeedBackField: AppointmentFeedbackField.soapSHistory,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapSHistory)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },

                {
                    label: '[O] Vital Signs',
                    value: <>
                        {feedback.soapOVitalSigns || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOVitalSigns),
                            appointmentFeedBackField: AppointmentFeedbackField.soapOVitalSigns,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOVitalSigns)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[O] General Observations',
                    value: <>
                        {feedback.soapOGeneralObservations || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOGeneralObservations),
                            appointmentFeedBackField: AppointmentFeedbackField.soapOGeneralObservations,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOGeneralObservations)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[O] Physical Examinations',
                    value: <>
                        {feedback.soapOPhysicalExaminations || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOPhysicalExaminations),
                            appointmentFeedBackField: AppointmentFeedbackField.soapOPhysicalExaminations,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapOPhysicalExaminations)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[O] Diagnostic',
                    value: <>
                        {feedback.soapODiagnostic || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapODiagnostic),
                            appointmentFeedBackField: AppointmentFeedbackField.soapODiagnostic,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapODiagnostic)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },

                {
                    label: '[A] Diagnosis',
                    value: <>
                        {feedback.soapADiagnosis || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapADiagnosis),
                            appointmentFeedBackField: AppointmentFeedbackField.soapADiagnosis,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapADiagnosis)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },

                {
                    label: '[P] Diagnostic',
                    value: <>
                        {feedback.soapPDiagnostic || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPDiagnostic),
                            appointmentFeedBackField: AppointmentFeedbackField.soapPDiagnostic,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPDiagnostic)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[P] Treatment',
                    value: <>
                        {feedback.soapPTreatment || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPTreatment),
                            appointmentFeedBackField: AppointmentFeedbackField.soapPTreatment,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPTreatment)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[P] Education',
                    value: <>
                        {feedback.soapPEducation || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPEducation),
                            appointmentFeedBackField: AppointmentFeedbackField.soapPEducation,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPEducation)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                },
                {
                    label: '[P] Consultation',
                    value: <>
                        {feedback.soapPConsultation || <span className='secondary-text'>No text</span>}
                        <FeedbackComments props={{
                            comments: feedback.comments.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPConsultation),
                            appointmentFeedBackField: AppointmentFeedbackField.soapPConsultation,
                            appointmentFeedbackId: feedback.id,
                            appointmentFeedbackNoteId: null,
                            view: true
                        }} />
                        {feedback.appointmentFeedbackAudio?.filter(x => x.appointmentFeedBackField == AppointmentFeedbackField.soapPConsultation)
                            .map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
                    </>
                }
            ]} />
    </>
})

export default FeedbackSoapNoTemplate;