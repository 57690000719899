// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gWGgWqJKSDgRNPQXRcUU{max-width:450px;margin:0 auto}.cXGG2icnureqyuJj4o_P{display:flex;justify-content:space-between;width:90%;margin:0 auto;margin-top:10%}.cXGG2icnureqyuJj4o_P div{width:47%;cursor:pointer}.cXGG2icnureqyuJj4o_P div img{width:100%}.iosNJ5FcwgM1VDduZggU{display:flex;justify-content:center;align-items:center;margin-top:40px}.iosNJ5FcwgM1VDduZggU img{width:100px}", "",{"version":3,"sources":["webpack://./src/routes/auth/Auth.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,aAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,SAAA,CACA,aAAA,CACA,cAAA,CAEA,0BACI,SAAA,CACA,cAAA,CAEA,8BACI,UAAA,CAMZ,sBACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CAEA,0BACI,WAAA","sourcesContent":[".login_wrapper {\r\n    max-width: 450px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.apps_buttons {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 90%;\r\n    margin: 0 auto;\r\n    margin-top: 10%;\r\n\r\n    div {\r\n        width: 47%;\r\n        cursor: pointer;\r\n\r\n        img {\r\n            width: 100%;\r\n        }\r\n    }\r\n\r\n}\r\n\r\n.script_book_sign {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-top: 40px;\r\n\r\n    img {\r\n        width: 100px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login_wrapper": "gWGgWqJKSDgRNPQXRcUU",
	"apps_buttons": "cXGG2icnureqyuJj4o_P",
	"script_book_sign": "iosNJ5FcwgM1VDduZggU"
};
export default ___CSS_LOADER_EXPORT___;
