import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AppointmentFeedbackField } from "api/model/Appointments/enums/AppointmentFeedBackFields";
import { Alert, Breadcrumb, Card, Col, Divider, Row } from "antd";
import Details from "app/components/Details/Details";
import useApi from "misc/hooks/useApi";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import FeedbackAudioPlayer from "./FeedbackAudioPlayer";
import FeedbackComments from "./FeedbackComments";
import { TemplateFieldType } from "api/model/Template/TemplateField";
import useCurrentUser from "misc/hooks/useCurrentUser";
import FeedbackSoapNoTemplate from "./FeedbackSoapNoTemplate";

const FeedbackRecordings: React.FC<{ feedback: AdminFeedbackDto }> = observer(({ feedback }) => {

	const templateNotes = useMemo(() => feedback.notes.filter(x => x.templateFieldId != null), [feedback.notes]);
	const customNotes = useMemo(() => feedback.notes.filter(x => x.templateFieldId == null && !x.isAmbient), [feedback.notes]);
	const ambientNote = useMemo(() => feedback.notes.find(x => x.isAmbient), [feedback.notes]);
	const user = useCurrentUser();

	return <>
		<Details layout="horizontal" labelWidth="200px"
			data={[
				{
					label: "Date",
					value: moment(feedback.createdDate).format("DD MMM YYYY hh:mm a")
				},
				{
					label: "Rating",
					visible: user.isSAASUser == false,
					value: feedback.rating ?? <span className="secondary-text">No data</span>
				},
			]} />

		{!feedback.templateId &&
			<FeedbackSoapNoTemplate feedback={feedback} />
		}

		{ambientNote &&
			<>
				<Divider orientation="left" type="horizontal">AMBIENT RECORDINGS</Divider>
				{ambientNote.appointmentFeedbackAudio.map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
			</>
		}

		{feedback.templateId &&
			<>
				<Divider orientation="left" type="horizontal">Template - {feedback.template.name}</Divider>
				{templateNotes.length > 0 ?
					<Details layout="horizontal" labelWidth="200px"
						data={templateNotes.filter(x => x.templateFieldId != null).map(x => {

							if (x.type == TemplateFieldType.Separator) {
								return {
									separator: true,
									separatorText: x.name,
								}
							}

							return {
								label: x.name,
								value:
									<>
										{x.text || <span className='secondary-text'>No text</span>}
										<FeedbackComments props={{
											comments: feedback.comments.filter(f => f.appointmentFeedbackNoteId == x.id),
											appointmentFeedBackField: null,
											appointmentFeedbackId: feedback.id,
											appointmentFeedbackNoteId: x.id,
											view: true
										}} />
										{x.appointmentFeedbackAudio.map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
									</>

							}
						})
						} />
					: <span className="secondary-text">No template notes</span>
				}
			</>

		}

		{customNotes.length > 0 &&
			<>
				<Divider orientation="left" type="horizontal">CUSTOM NOTES</Divider>
				{customNotes.filter(x => x.templateFieldId == null).length > 0 ?
					<Details layout="horizontal" labelWidth="200px"
						data={customNotes.filter(x => x.templateFieldId == null).map(x => {
							return {
								label: moment(feedback.createdDate).format("DD MMM YYYY hh:mma"),
								value:
									<>
										{x.text || <span className='secondary-text'>No text</span>}
										<FeedbackComments props={{
											comments: feedback.comments.filter(f => f.appointmentFeedbackNoteId == x.id),
											appointmentFeedBackField: null,
											appointmentFeedbackId: feedback.id,
											appointmentFeedbackNoteId: x.id,
											view: true
										}} />
										{x.appointmentFeedbackAudio.map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={feedback.id} />)}
									</>

							}
						})
						} />
					: <span className="secondary-text">No custom notes</span>
				}
			</>
		}
	</>
})


export default FeedbackRecordings;


