import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Button, Typography, Divider } from "antd";
import { AppointmentStoreContext } from "vs-recording/stores/appointment/AppointmentStoreContext";
import { AppointmentFeedbackClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto";

const { Text } = Typography;

type ProcessingProps = {
    feedback: AppointmentFeedbackClientDto;
}

const Processing: React.FC<ProcessingProps> = observer(({ feedback }) => {
    const appointmentContext = useContext(AppointmentStoreContext);

    return <div>
        <div className='mt-20 mb-20 text-center'>
            <Text className='color-white fs-20'>Your recording is being processed. Please wait. This recording will be refreshed automatically or you can use "Refresh" button.</Text>
        </div>
        <Divider style={{ background: '#ccc' }} />
        <div className='flex align-center justify-center'>
            <Button
                size='large'
                onClick={async () => await appointmentContext!.refresh()}
                loading={appointmentContext?.loading === true}
                disabled={feedback?.isEditing}>
                Refresh
            </Button>
        </div>
    </div>;
});

export default Processing;
