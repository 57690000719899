export enum AppointmentStatus {
	Scheduled = "Scheduled",
	Kept = "Kept",
	Cancelled = "Cancelled",
	NoShow = "NoShow",
	CheckedIn = "CheckedIn",
	CheckedOut = "CheckedOut",
	Completed = "Completed",
	Markup = "Markup",
	Rated = "Rated",
}

export const AppointmentStatusList = [
	{ value: AppointmentStatus.Scheduled, label: "Scheduled" },
	{ value: AppointmentStatus.Kept, label: "Kept" },
	{ value: AppointmentStatus.Cancelled, label: "Cancelled" },
	{ value: AppointmentStatus.NoShow, label: "No Show" },
	{ value: AppointmentStatus.CheckedIn, label: "Checked In" },
	{ value: AppointmentStatus.CheckedOut, label: "Checked Out" },
	{ value: AppointmentStatus.Completed, label: "Completed" },
	{ value: AppointmentStatus.Markup, label: "Markup" },
	{ value: AppointmentStatus.Rated, label: "Rated" },
]
