import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { NotificationInstance } from "antd/es/notification/interface";
import { TemplateListRequest } from "api/routes/Templates/Model/TemplateListRequest";
import { Template } from "api/model/Template/Template";

export class TemplateStore {
	loading: boolean = false;
	error: string | null = null;

	filter: TemplateListRequest = {
		page: 1,
		pageSize: 25,
		search: null,
		sortField: "CreatedDate",
		sortDirection: "desc",
		isRemoved: false
	}

	pagedData: PagingResponse<Template> | null = null;
	notificationApi?: NotificationInstance;

	constructor(private api: ApiClient, p?: Partial<TemplateStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminTemplates.list(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter,
					sortField: r.sortField,
					sortDirection: r.sortDirection,
					page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	};

	delete = async (x: Template) => {
		try {
			const r = await this.api.adminTemplates.delete(x);
			if (r.success) {
				this.notificationApi?.success({ message: "Deleted" });
			} else {
				this.notificationApi?.error({ message: r.error || "Error occurred" });
			}
			this.load();
		} catch (e: any) {
			this.notificationApi?.error({ message: e.message });
		}
	};

	restore = async (x: Template) => {
		try {
			const r = await this.api.adminTemplates.restore(x);
			if (r) {
				this.notificationApi?.success({ message: "Restored" });
			}
			this.load();
		} catch (e: any) {
			this.notificationApi?.error({ message: e.message });
		}
	};

	duplicate = async (x: Template) => {
		try {
			const r = await this.api.adminTemplates.duplicate(x);
			if (r.success) {
				this.notificationApi?.success({ message: "Successfully Duplicated" });
			} else {
				this.notificationApi?.error({ message: r.error || "Error occurred" });
			}
			this.load();
		} catch (e: any) {
			this.notificationApi?.error({ message: e.message });
		}
	}


	setState = (p: Partial<TemplateStore>) => {
		Object.assign(this, p);
	};

	setFilter = (p: Partial<TemplateListRequest>, shouldResetPage: boolean = true) => {
		Object.assign(this.filter, p);
		if (shouldResetPage)
			this.filter.page = 1;
		this.load();
	};


	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: page,
			pageSize: pageSize
		}, shouldResetPage)
	}

	setSearch = debounce((search: string | null) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: null })
	}
}
