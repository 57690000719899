import React from "react";
import { Alert, Breadcrumb, Button, Card, Col, Divider, Flex, Row, Space, Spin, Statistic, StatisticProps, Tooltip } from "antd";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "app/components/DatePicker";
import { TotalsReportStore } from "./TotalsReportStore";
import CountUp from "react-countup";
import { UserOutlined, BankOutlined, AudioOutlined, ClockCircleOutlined, SoundOutlined, FieldTimeOutlined } from '@ant-design/icons';


const TotalsReport: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<TotalsReportStore>(() => new TotalsReportStore(api));

	useEffect(() => {
	}, [])

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link>
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				Totals
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />


		<div style={{ marginBottom: 10, width: "100%", textAlign: 'center' }} >
			<h2>Totals for dates</h2>
			<DatePicker.RangePicker
				size="large"
				presets={[
					{ label: 'Today', value: [moment().startOf('day'), moment().endOf('day')] },
					{ label: 'Yesterday', value: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')] },
					{ label: 'This week', value: [moment().startOf('week'), moment().endOf('week')] },
					{ label: 'Last week', value: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] },
					{ label: 'This month', value: [moment().startOf('month'), moment().endOf('month')] },
					{ label: 'Last month', value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] },
					{ label: 'This quarter', value: [moment().startOf('quarter'), moment().endOf('quarter')] },
					{ label: 'Last quarter', value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')] }
				]}
				popupAlign={{ points: ['bl', 'tl'] }}
				value={[moment(store.filter.from), moment(store.filter.to)]}
				showTime={false}
				onChange={(dates) => {
					store.setFilter({ from: dates[0].toDate(), to: dates[1].toDate() });
				}}
			/>
		</div>

		<Flex style={{marginBottom: 20}} gap={10} justify="center">
			<Button onClick={() => store.setFilter({ from: moment().startOf('day').toDate(), to: moment().endOf('day').toDate() })}>Today</Button>
			<Button onClick={() => store.setFilter({ from: moment().subtract(1, 'day').startOf('day').toDate(), to: moment().subtract(1, 'day').endOf('day').toDate() })}>Yesterday</Button>
			<Button onClick={() => store.setFilter({ from: moment().startOf('week').toDate(), to: moment().endOf('week').toDate() })}>This week</Button>
			<Button onClick={() => store.setFilter({ from: moment().subtract(1, 'week').startOf('week').toDate(), to: moment().subtract(1, 'week').endOf('week').toDate() })}>Last week</Button>
			<Button onClick={() => store.setFilter({ from: moment().startOf('month').toDate(), to: moment().endOf('month').toDate() })}>This month</Button>
			<Button onClick={() => store.setFilter({ from: moment().subtract(1, 'month').startOf('month').toDate(), to: moment().subtract(1, 'month').endOf('month').toDate() })}>Last month</Button>
			<Button onClick={() => store.setFilter({ from: moment().startOf('quarter').toDate(), to: moment().endOf('quarter').toDate() })}>This quarter</Button>
			<Button onClick={() => store.setFilter({ from: moment().subtract(1, 'quarter').startOf('quarter').toDate(), to: moment().subtract(1, 'quarter').endOf('quarter').toDate() })}>Last quarter</Button>
		</Flex>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
							<Card>
								<Statistic
									title="Users"
									value={store.data?.usersCreated}
									formatter={formatter}
									prefix={<UserOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
							<Card>
								<Statistic
									title="Accounts"
									value={store.data?.accountsCreated}
									formatter={formatter}
									prefix={<BankOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
							<Card>
								<Statistic
									title="Recordings"
									value={store.data?.recordings}
									formatter={formatter}
									prefix={<AudioOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
							<Card>
								<Statistic
									title="Avg. Processing"
									value={store.data?.avgProcessingTime / 1000}
									formatter={formatter}
									prefix={<ClockCircleOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
									suffix="s"
								/>
							</Card>
						</Col>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
								<Card>
									<Statistic
										title="Audios"
										value={store.data?.audios}
										formatter={formatter}
										prefix={<SoundOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
									/>
								</Card>
						</Col>
						<Col xs={24} sm={12} md={4} lg={4} xl={4}>
							<Card>
								<Statistic
									title="Audio Total hrs"
									value={store.data?.totalDuration / 60 / 60}
									formatter={formatter}
									prefix={<FieldTimeOutlined style={{ fontSize: '24px', marginRight: '8px' }} />}
									suffix="hrs"
								/>
							</Card>
						</Col>
					</Row>

		}
	</>
})

const formatter: StatisticProps['formatter'] = (value) => (
	<CountUp duration={1} end={value as number} separator="," />
);

export default TotalsReport;
