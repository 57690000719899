export enum AppointmentFeedbackReviewTier {
	Scribe = 0,
	Supervisor = 1,
	Vet = 2,
	All = 100
}



export const AppointmentFeedbackReviewTierList = [
	{ value: AppointmentFeedbackReviewTier.All, label: "All", color: "grey" },
	{ value: AppointmentFeedbackReviewTier.Scribe, label: "Scribe", color: "green" },
	{ value: AppointmentFeedbackReviewTier.Supervisor, label: "Supervisor", color: "orange" },
	{ value: AppointmentFeedbackReviewTier.Vet, label: "Vet", color: "volcano" },
]
