
import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";
import { PagingResponse } from "../../base/Paging";
import { User } from "../../model/User/User";
import { UserSimple } from "../../model/User/UserSimple";
import { UserListRequest } from "./Model/UserListRequest";
import { UserUpdateRequest } from "./Model/UserUpdateRequest";


export default class AdminUserApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/user";

	async list(req: UserListRequest) {
		const request = await this.api.client.post<PagingResponse<User>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(userId: number | null) {
		if (!userId) return null;
		const request = await this.api.client.get<User>(`${this.baseUrl}/${userId}`);
		return request.data;
	}

	async delete(user: User) {
		const request = await this.api.client.delete<NiceResponse<User>>(`${this.baseUrl}/delete/${user.id}`);
		return request.data;
	}

	async updateCreate(req: UserUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<User>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async restore(user: User) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${user.id}`);
		return request.data;
	}

	async listSelect(req: UserListRequest) {
		const request = await this.api.client.post<PagingResponse<UserSimple>>(`${this.baseUrl}/listSelect`, { ...req });
		return request.data;
	}

}