import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const { Text } = Typography;

type SubmitFeedbackAmbientProps = {
	feedback: AppointmentFeedbackClientDto;
};

const SubmitFeedbackAmbient: React.FC<SubmitFeedbackAmbientProps> = observer(({ feedback }) => {
	const appointmentContext = useContext(AppointmentStoreContext);
	const ambientNote = feedback?.notes?.find(x => x.isAmbient);
	const audiosScrollRef = useRef<HTMLDivElement>(null);
	const audios = ambientNote?.appointmentFeedbackAudio || [];

	useEffect(() => {
		if (audiosScrollRef.current) {
			audiosScrollRef.current.scrollTop = audiosScrollRef.current.scrollHeight;
		}
	}, [audios.length]);

	if (ambientNote == null) {
		return <div>
			<Text className="title">Error occurred, try to refresh</Text>
		</div>
	}

	const onRecordedAudio = async (uri: string, duration: number) => {
		await appointmentContext!.addOrUpdateAudioToNote(uri, duration, ambientNote);
	}

	const onUpdateRecording = async (uri: string, duration: number) => {
		await appointmentContext!.addOrUpdateAudioToNote(uri, duration, ambientNote, true);
	}

	const onDeleteAudio = async (audio: AppointmentFeedbackAudio) => {
		await appointmentContext!.deleteAudioFromNote(audio, ambientNote);
	}

	const onRecordingStarted = () => {
		appointmentContext!.setIsRecording(true);
	}

	const onRecordingStop = () => {
		appointmentContext!.setIsRecording(false);
	}

	return <div className='mt-20'>
		<Text className='mt-10 color-white'>
			Make one or multiple recordings during or after the visit. We will combine all recordings and fill them out according to the selected template.
		</Text>

		<div
			ref={audiosScrollRef}
			className='max-h-300 mt-10'
		>
			{audios.filter(x => x && !x.isInProgress && !x.isSaving).map((x, i) => (
				<div key={`${x.uri} | ${i}`} style={{ marginBottom: '10px' }}>
					<AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} disabled={appointmentContext?.isRecording} showDelete />
				</div>
			))}
		</div>

		<div>
			<AudioRecorder
				noteId={ambientNote.id}
				feedbackId={feedback.id}
				onStartRecording={onRecordingStarted}
				onStopRecording={onRecordingStop}
				onRecorded={onRecordedAudio}
				onError={() => appointmentContext?.setIsRecording(false)}
				onUpdateRecording={onUpdateRecording}
			/>
		</div>
	</div>;
});

export default SubmitFeedbackAmbient;
