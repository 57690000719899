import { Alert, Button, Menu } from 'antd';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import React from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import AdminMenu from './AdminMenu';
import VoiceWordAdd from 'routes/dictionary/VoiceWordAdd';
import useGeneralState from 'misc/hooks/useGeneralState';
import { observer } from 'mobx-react-lite';
import { SAASUserRoles, UserRoleEnum } from 'api/model/User/UserRole';
import { LiveChatWidget } from '@livechat/widget-react';
import { AppSettings } from 'appSettings';

const AdminLayout: React.FC = observer(() => {
	const user = useCurrentUser();
	const generalState = useGeneralState();
	const nav = useNavigate();

	if (!user.isAuthorized) return <Navigate to="/auth/login" />;
	// if (!user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Admin, ...SAASUserRoles]))
	// 	return <div style={{ width: '100%', maxWidth: "500px", margin: "10px auto", textAlign: "center" }}>
	// 		<div>
	// 			<Alert message='You do not have access to this page' />
	// 		</div>
	// 		<Button type='primary' style={{ margin: "10px auto" }} onClick={() => nav("/auth/logout")}>Log out</Button>
	// 	</div>

	const classes = ["layout", "admin"];

	return <div className={classes.join(" ")}>
		<LiveChatWidget
			license={AppSettings.livechat.license}
			visibility="minimized"
			group='admin'
			customerName={user.firstName + ' ' + user.lastName}
			customerEmail={user.email}
			sessionVariables={{ account: user.accounts[0]?.name }}
		/>
		<AdminMenu />
		<div className={`content admin ${generalState.isMenuCollapsed ? 'collapsed' : ''}`}>
			<Outlet />
		</div>
	</div>
});

export default AdminLayout;