import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Typography, Divider } from 'antd';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { TemplateFieldType } from 'api/vs-recording/model/Template/TemplateField';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import styles from '../../Recording.module.scss';

const { Text } = Typography;

const Summary: React.FC<{ feedback: AppointmentFeedbackClientDto }> = observer(({ feedback }) => {
	const templateFieldNotes = useMemo(
		() =>
			feedback?.notes
				.filter(x => x.templateFieldId != null)
				.sort((a, b) => (a.position ? a.position - (b.position || 0) : -1)),
		[feedback?.notes]
	);

	const customNotes = useMemo(
		() =>
			feedback?.notes
				.filter(x => x.templateFieldId == null && x.isAmbient == false)
				.sort((a, b) =>
					a.createdDate == null || b.createdDate == null
						? 1
						: new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime()
							? 1
							: -1
				),
		[feedback?.notes]
	);

	const ambientNote = useMemo(() => {
		return feedback?.notes.find(x => x.templateFieldId == null && x.isAmbient == true);
	}, [feedback?.notes]);

	const templateNotesTitle = feedback?.template?.name || 'Notes';

	return (
		<div >
			{ambientNote && feedback?.ambientMode && (
				<>
					<Text className='color-white fs-16'>Ambient Recordings</Text>
					<div className='mt-10'>
						{(ambientNote.appointmentFeedbackAudio || []).map((x, i) => (
							<div key={`${x.uri} | ${i}`} className="mb-5">
								<AudioPlayer audio={x} showDelete={false} />
							</div>
						))}
					</div>
				</>
			)}

			{templateFieldNotes?.length > 0 && (
				<>
					<div className='flex flex-col mt-20 mb-20'>
						<Text className='color-white fs-14'>Used template for report</Text>
						<Text className='color-white fw-500 fs-22'>{templateNotesTitle}</Text>
						{feedback?.template?.description && <Text className='color-white fs-12 '>{feedback?.template.description}</Text>}
					</div>
					{templateFieldNotes.map((note, index) => {
						const prevNote = templateFieldNotes[index - 1];
						let noteName = note.name || 'Note #' + (index + 1);
						if (prevNote?.type == TemplateFieldType.Separator && !note.name) {
							noteName = '';
						}
						return <div key={note.id}>
							{note.type == TemplateFieldType.Separator
								? <Text className='color-white fs-16'>{noteName}</Text>
								: <div className='mt-5'>
									<OneText
										name={noteName}
										date={note.createdDate}
										value={note.text || ''}
										audios={note.appointmentFeedbackAudio || []}
									/>
								</div>
							}
						</div>;
					})}
				</>
			)
			}

			{customNotes?.length > 0 && <>
				<Text className='fs-16 color-white'>Custom Notes</Text>
				{customNotes.map((note, index) => (
					<div key={note.id + '|' + index} className='mt-5'>
						<OneText
							name={note.name || 'Note #' + (index + 1)}
							date={note.createdDate}
							value={note.text || ''}
							audios={note.appointmentFeedbackAudio || []}
						/>
					</div>
				))}
			</>}
		</div >
	);
});

export default Summary;

type OneTextProps = {
	name?: string | null;
	date?: string | Date | null;
	value: string;
	audios: AppointmentFeedbackAudio[];
};

const OneText: React.FC<OneTextProps> = observer(({ value, date, name, audios }) => {
	const hasLettersRE = new RegExp(/[a-zA-Z]/);
	const hasLetters = value.length != 0 || hasLettersRE.test(value);

	return <div className={styles.oneNote}>
		{name && <div className="flex justify-between mb-10 fw-700">
			<Text className="fs-14 opacity-60">{name}</Text>
		</div>}

		<div>
			{hasLetters
				? <Text className="color-black">{value}</Text>
				: <Text className="color-black opacity-30">No text</Text>
			}
		</div>

		{audios.length > 0 && <div className="mt-5">
			{audios.map((x, i) => <div key={`${x.uri} | ${i}`} className="mb-5">
				<AudioPlayer audio={x} showDelete={false} />
			</div>)}
		</div>}
	</div>;
});
