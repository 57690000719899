import { Button, Form, Input, Popconfirm } from 'antd'
import { AdminFeedbackDto } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto'
import EmailToSelect from 'app/components/EmailToSelect/EmailToSelect'
import VSEditor from 'app/components/VSEditor/VSEditor'
import useCurrentUser from 'misc/hooks/useCurrentUser'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

type EmailFormProps = {
    feedback: AdminFeedbackDto,
    onSend?: (email: string, subject?: string, body?: string, emailCC?: string[]) => void;
    onNameEdited?: () => void;
}

const EmailForm: React.FC<EmailFormProps> = ({ feedback, onSend, onNameEdited }) => {
    const user = useCurrentUser();
    const [email, setEmail] = useState(feedback.appointment?.clientEmail ?? '');
    const defaultSubject = useMemo(() =>
        `Appointment from ${moment(feedback.appointment?.date).format('LLL')} at ${feedback.appointment?.practiceName}`
        , [feedback.appointment?.date, feedback.appointment?.practiceName]);
    const [subject, setSubject] = useState(defaultSubject);
    const [body, setBody] = useState(feedback.feedbackOutput.clientEmail ?? '');
    const [form] = Form.useForm();
    const [ccEmails, setCcEmails] = useState<string[]>(user.email ? [user.email] : []);


    const sumbit = useCallback(() => {
        onSend?.(email, subject, body, ccEmails);
    }, [email, subject, body, ccEmails]);

    useEffect(() => {
        console.log('email changed', user.email);

    }, [user.email]);

    return <Form form={form} layout='vertical'
        onFinish={sumbit}
        initialValues={{
            email: email,
            clientName: feedback.appointment?.clientName
        }}>
        <Form.Item name='email'
            label='Client Email'
            required
            rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'Please enter valid email' }]}>
            <Input placeholder='Client email' value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item label='CC'>
            <EmailToSelect value={ccEmails} onChange={setCcEmails} />
        </Form.Item>

        <Form.Item name='clientName'
            label='Client Name'
            extra={<Popconfirm
                placement='right'
                title={<>
                    <div className='fw-500'>This action will close the email message form and you will lose all changes.</div>
                    <div>Email message will be regenerated after name changed.</div>
                    <div>You can add or edit client's name straight in the email body.</div>
                </>}
                onConfirm={onNameEdited}>
                <Button type='link' size='small' className='p-0'>{feedback.appointment?.clientName ? 'Edit name' : 'Add name'}</Button>
            </Popconfirm>}>
            <Input placeholder='Not specified' value={feedback.appointment?.clientName} disabled />
        </Form.Item>
        <Form.Item label='Subject'>
            <Input placeholder='Subject'
                defaultValue={defaultSubject}
                value={subject}
                onChange={e => setSubject(e.target.value)} />
        </Form.Item>
        <Form.Item label='Body'>
            <VSEditor initialData={body ?? "No content"} onChange={setBody} hideFooter />
        </Form.Item>
        <div className='flex justify-end'>
            <Button type='primary'
                style={{ backgroundColor: feedback.isClientEmailSent ? '#52c41a' : undefined }}
                onClick={form.submit}>
                {feedback.isClientEmailSent
                    ? `Sent on ${moment(feedback.clientEmailSentDate).format('LLL')} - Resend`
                    : 'Send'}
            </Button>
        </div>
    </Form>;
}

export default EmailForm;