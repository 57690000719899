import { CommentOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import combineClasses from "misc/helpers/combineClasses";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useDrawer from "misc/hooks/useDrawer";
import { observer } from "mobx-react";
import React from "react";
import VideoModal from "app/components/VideoModal/VideoModal";
import styles from './HelpCenter.module.scss';
import downloadQrCode from '../../assets/downloadQrCode.svg'
import appStore from '../../assets/appstore.svg';
import googlePlay from '../../assets/googleplay.svg';

const HelpCenter: React.FC = observer(() => {
    const user = useCurrentUser();
    const videoModal = useDrawer<{
        url: string;
        title: string;
    }>();

    return <>
        <div className={styles.instruction_wrapper}>
            <div className='flex'>
                <CommentOutlined style={{ fontSize: 40, color: '#795548', margin: 10, alignSelf: 'self-start' }} />
                <div>
                    <Typography.Title className="fs-22">Hello, {user.firstName} {user.lastName}</Typography.Title>
                    <Typography.Text className="fs-16">Welcome to VetSkribe! We are excited to have you on board!</Typography.Text>
                    <Typography.Paragraph className="fs-16 mt-20">
                        This is your dashboard, where you manage your hospital VetSkribe account.<br />
                        Let’s start with the left-hand navigation menu.
                        <ol className={combineClasses('mt-20', styles.main_list)}>
                            <li>
                                <span className="fw-500">Recordings:</span>&nbsp;
                                You will find all your submitted recordings.
                                You can edit your transcriptions (or you can do your edits directly in the app).
                                This is where you retrieve the completed notes to copy them in your patient files in your
                                PIM.
                            </li>
                            <li>
                                <span className="fw-500">Templates:</span>&nbsp;
                                You can create your own custom templates. Watch&nbsp;
                                <Button type='link' className='fs-16 p-0' onClick={() => videoModal.open({ title: 'About templates', url: 'https://www.youtube.com/embed/Onu1vgwscZI' })}>
                                    this video.
                                </Button>
                            </li>
                            <li>
                                <span className="fw-500">Hospitals:</span>&nbsp;
                                If you manage more than 1 hospital under the same organization you can add your other hospitals here.
                            </li>
                            <li>
                                <span className="fw-500">Doctors:</span>&nbsp;
                                You can add additional doctors to your hospital account. That is a 2-step process.
                                <ol>
                                    <li>Go in the Doctor section, click “Add new” and fill in the form.</li>
                                    <li>Go to Users section, click “Add new” and fill in the form. In the “Doctor” field, chose from the list the newly created doctor in the previous step.
                                        There’s a field called “Admin Roles”, leave it empty.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="fw-500">Users:</span>&nbsp;
                                To add users who are NOT doctors, for example if you have your own scribes. A scribe will not have access to the app but will have access to the dashboard where they can edit and retrieve the transcriptions for all doctors in your hospital.
                            </li>
                        </ol>
                        <p>
                            Using the VetSkribe App and dashboard is very intuitive.<br />
                            Please watch the following training videos to help you with details and tips.<br />

                            <Button type='link'
                                className='fs-16 p-0'
                                onClick={() => videoModal.open({ title: 'App tutorial video', url: 'https://www.youtube.com/embed/vfwTbQgOAq0?si=k__-2AnboBs4P-9m' })}>
                                Video. 1: App tutorial video.
                            </Button><br />
                            <Button type='link'
                                className='fs-16 p-0'
                                onClick={() => videoModal.open({ title: 'Edit & Approval done in the App', url: 'https://www.youtube.com/embed/N26jRQkLj3w?si=4xXM74F_HY2NarHS' })}>
                                Video 2: Edit &amp; Approval done in the App.
                            </Button><br />
                            <Button type='link'
                                className='fs-16 p-0'
                                onClick={() => videoModal.open({ title: 'Dahsboard - How to use', url: 'https://www.youtube.com/embed/lJA48W0zlsc?si=M_G72PEUwWR_-cE4' })}>
                                Video 3: Dashboard - How to use - video.
                            </Button>
                        </p>
                        <p>
                            For any other questions, please send an email to support@vetskribe.com, we will get back to you
                            shortly.
                        </p>
                        <p>
                            Your free trial will end in 30 days.<br />
                            We will send you an email a few days before with a link to activate your account.
                        </p>
                    </Typography.Paragraph>
                    <div className="flex justify-center flex-col gap-20">
                        <span className="fw-500 fs-18">Download Mobile App</span>

                        <div className='flex align-center gap-20'>
                            <div onClick={() => window.location.href = 'https://apps.apple.com/us/app/vetskribe/id1634975470'} style={{ cursor: 'pointer' }}>
                                <img src={appStore} alt="Download from App Store" />
                            </div>
                            <div onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.monarchy.vetscribe'} style={{ cursor: 'pointer' }}>
                                <img src={googlePlay} alt="Download from Google Play" />
                            </div>
                        </div>
                        <span className="fw-500 fs-18">Or scan the QR Code with your phone</span>
                        <div style={{ width: 150, height: 150 }}>
                            <img src={downloadQrCode} alt="Download the app" className="w-100" />
                        </div>

                    </div>
                </div>
            </div>
        </div >


        <VideoModal open={videoModal.visible}
            width={800}
            onCancel={videoModal.close}
            destroyOnClose
            footer={null}
            title={videoModal.data?.title}
            videoUrl={videoModal.data?.url} />
    </>;
})

export default HelpCenter;




