import { AdminFeedbackMsgDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackMsgDto";
import { Alert, Avatar, Button, Popconfirm } from "antd";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons"


export const OneMessage: React.FC<{ message: AdminFeedbackMsgDto; even: boolean; showDelete: boolean, onDelete: () => {} }> =
	observer(({ message, even, showDelete, onDelete }) => {
		const user = useCurrentUser();
		const isMy = message.fromId == user.id;
		const messageTrimmed = message.text ? message.text.replace(/^\s+|\s+$/g, '') : "";


		return <div style={{
			display: "flex",
			marginTop: 10,
			flexDirection: isMy ? "row-reverse" : "row",
			backgroundColor: even ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
		}}>

			<Avatar style={{ color: isMy ? '#f56a00' : "#fff", backgroundColor: isMy ? '#fde3cf' : "#dedede", marginRight: 10, marginLeft: 10 }} icon={<UserOutlined />} />

			<div style={{ flex: 1 }}>
				<div style={{ color: "#000", fontSize: 16, textAlign: isMy ? "right" : "left" }}>
					{messageTrimmed}
				</div>
				<div style={{ color: "#000", opacity: 0.5, fontSize: 12, textAlign: isMy ? "right" : "left" }}>
					{isMy ? "You" : message.fromName} • {moment(message.createdDate).format("DD MMM hh:mma")}
					{isMy && showDelete && <Popconfirm title="Delete message?" onConfirm={() => onDelete()}>
						<a> • Delete</a>
					</Popconfirm>
					}
				</div>
			</div>


		</div >
	});
