import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Row, Space, Table, TablePaginationConfig, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { EyeTwoTone, DeleteTwoTone, ReloadOutlined } from "@ant-design/icons";
import React from "react";
import { User } from "api/model/User/User";
import { OfficeStore } from "./OfficeStore";
import OfficeEdit from "./OfficeEdit";
import { Office } from "api/model/Offices/Office";


const OfficeList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<OfficeStore>(() => new OfficeStore(api));
	const [editing, setEditing] = React.useState<Office>(null);

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<User> | SorterResult<User>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<Office> = [
		{
			title: 'Name',
			key: 'Name',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Office) => <>
				<a onClick={() => setEditing(x)}>
					{x.name}

				</a>
				<div>SyncVetId: {x.syncVetId || <span className="secondary-text">No sync</span>}</div>
			</>
		},
		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Office) =>
				x.createdDate ?
					<span>{moment(x.createdDate).format("DD MMM yyyy HH:mm:ss")}</span>
					:
					<span className="secondary-text">No data</span>

		},
		{
			title: 'Address',
			render: (x: Office) => {
				return <>
					{x.addressLine1} {x.addressLine2} {x.state} {x.city} {x.zip}
				</>
			}
		},
		{
			title: 'Data',
			render: (x: Office) => {
				return <>

					<div>email: {x.email || <span className="secondary-text">No data</span>} </div>
					<div>phone: {x.phone || <span className="secondary-text">No data</span>} </div>
				</>
			}
		},
		{
			title: 'Actions',
			align: "right",
			render: (x: Office) => <Space key={x.id}>
				{x.softDeleted && <Tag color={"red"}>Removed</Tag>}

				<Button icon={<EyeTwoTone />} onClick={() => setEditing(x)} />

				{!x.softDeleted ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button icon={<DeleteTwoTone />} />
					</Popconfirm>
					:
					<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
				}
			</Space>
		},
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Offices
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<Checkbox
				indeterminate={store.filter.isRemoved == null}
				checked={store.filter.isRemoved === true} onClick={() => store.toggleIsRemoved()}
			>
				Removed
			</Checkbox>

			<Button onClick={() => setEditing({ name: "New Office", id: 0 })}>Add new</Button>

		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}

		<Drawer destroyOnClose={true} visible={editing != null} onClose={() => setEditing(null)} title={"Edit User"}>
			<OfficeEdit office={editing} onCancel={() => setEditing(null)} onSuccess={() => store.load() && setEditing(null)} />
		</Drawer>
	</>
})


export default OfficeList;




