import { User } from "api/model/User/User";
import * as signalR from "@microsoft/signalr";
import CurrentUser from "model/CurrentUser";
import { observable, runInAction } from "mobx";
import { AdminFeedbackUpdateChatDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateChatDto";


export type VSHubClientProps = {
	hubUrl: string;
	user: CurrentUser;
}

export default class VSHubClient {
	connection: signalR.HubConnection;
	user: CurrentUser;
	hubUrl: string;

	@observable connected: boolean;
	connecting: boolean;

	constructor(props: VSHubClientProps) {
		this.user = props.user;
		this.hubUrl = props.hubUrl;
	}

	async init() {
		if (!this.user.isAuthorized) return;
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(this.hubUrl, { accessTokenFactory: () => this.user.accessToken, withCredentials: false })
			.build();

		await this.connect();

		this.connection.onclose(() => this.onDisconnect())
	}

	async onDisconnect() {
		console.log("Disconnected from hub")
		runInAction(() => this.connected = false)
		setTimeout(() => this.connect(), 5000)
	}

	async connect() {
		if (!this.connection) return;
		if (this.connecting) return;
		this.connecting = true;
		try {
			await this.connection.start()
			this.connecting = false;
			this.addBaseListeners();
			runInAction(() => this.connected = true)
		} catch (e) {
			console.error(e);
			runInAction(() => this.connected = false)
			this.connecting = false
			setTimeout(() => this.connect(), 5000)
		}
	}


	subscribe<T>(topic: string) {
		this.connection.send("subscribe", topic)
	}

	unsubscribe<T>(topic: string) {
		this.connection.send("unsubscribe", topic)
	}

	on<T>(event: string, callback: (data: T) => Promise<void> | void) {
		this.connection.on(event, callback)
	}

	off<T>(event: string) {
		this.connection.off(event)
	}

	//client
	listenersAdded: boolean = false;
	private addBaseListeners() {
		if (this.listenersAdded) return;
		this.listenersAdded = true;
		this.subscribe("admin-feedback-chat")
		this.on<string>("update-chat", d => {
			var data = JSON.parse(d) as AdminFeedbackUpdateChatDto;
			if (data.chatAgentAttention == true) {
				if (!this.user.unreadChats.some(x => x == data.id)) {
					this.user.unreadChatAdd(data.id);
				};
			} else {
				this.user.unreadChatRemove(data.id);
			}
			this.localListeners["update-chat"]?.forEach(x => x(d))
		})
	}

	chatIsRead(feedbackId: number) {
		this.connection.send("chatIsRead", feedbackId)
	}

	//local subscribe to a class. ignoring hub
	private localListeners: { [key: string]: ((fn: any) => Promise<void> | void)[] } = {};
	localSubscribe<T>(topic: string, callback: (fn: T) => Promise<void> | void) {
		if (!this.localListeners[topic]) this.localListeners[topic] = [];
		this.localListeners[topic].push(callback);
	}

	localUnsubscribe<T>(topic: string, callback: (fn: T) => Promise<void> | void) {
		if (!this.localListeners[topic]) return;
		this.localListeners[topic] = this.localListeners[topic].filter(x => x != callback);
	}


}