import React, { useCallback } from 'react';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib';

type PrintButtonProps = ButtonProps & {
    printTitle: React.ReactNode | string;
    bodyContent: string;
}

const PrintButton: React.FC<PrintButtonProps> = (props) => {

    const printContent = useCallback(() => {
        const printWindow = window.open('', '_blank') as Window;
        printWindow.document.open();
        printWindow.document.write(`
      <html>
        <head>
          <title>${props.printTitle}</title>
        </head>
        <body>
          <div>${props.bodyContent}</div>
        </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }, [props.printTitle, props.bodyContent]);

    return <Button type='default' {...props} onClick={printContent}>
        <PrinterOutlined />
    </Button>;
};

export default PrintButton;
