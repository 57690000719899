import { AppointmentFeedbackNote } from "../../model/Appointments/AppointmentFeedbackNote";
import { AppointmentFeedbackField } from "../../model/Appointments/enums/AppointmentFeedBackFields";
import { AppointmentFeedbackAudio } from "../../model/Audio/AppointmentFeedbackAudio";
import { AppointmentClientDto } from "./DTO/AppointmentClientDto";
import { AppointmentFeedbackClientDto, FeedbackSource } from "./DTO/AppointmentFeedbackClientDto";
import { AppointmentMessageClientDto } from "./DTO/AppointmentMessageClientDto";
import { AppointmentListRequest } from "./Model/AppointmentListRequest";
import { AppointmentChatClientDto } from "./DTO/AppointmentChatClientDto";
import { PracticeDto } from "./DTO/PracticeDto";
import { AppointmentCreateDto } from "./DTO/AppointmentCreateDto";
import { ClientSimple } from "../../model/Clients/ClientSimple";
import { PatientSimple } from "../../model/Clients/PatientSimple";
import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { NiceResponse } from "api/base/NiceResponse";
import axios from "axios";



export class AppointmentApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/appointment";

	async list(req: AppointmentListRequest) {
		const request = await this.api.client.post<PagingResponse<AppointmentClientDto>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<AppointmentClientDto>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async practiceList() {
		const request = await this.api.client.get<PracticeDto[]>(`${this.baseUrl}/practice-list`);
		return request.data;
	}

	async createAppointment(model: AppointmentCreateDto) {
		const request = await this.api.client.post<NiceResponse<AppointmentClientDto>>(`${this.baseUrl}/create-appointment`, { ...model });
		return request.data;
	}

	async checkClient(id: string, practiceId: number) {
		const request = await this.api.client.post<NiceResponse<ClientSimple>>(`${this.baseUrl}/check-client`, { id: id, practiceId: practiceId });
		return request.data;
	}

	async checkPatient(id: string, practiceId: number) {
		const request = await this.api.client.post<NiceResponse<PatientSimple>>(`${this.baseUrl}/check-patient`, { id: id, practiceId: practiceId });
		return request.data;
	}

	async saveDraft(x: AppointmentFeedbackClientDto) {
		if (!x.appointmentId) return null;
		const request = await this.api.client.post<AppointmentClientDto>(`${this.baseUrl}/save-feedback`, { ...x });
		return request.data;
	}

	async saveField(x: AppointmentFeedbackClientDto, field: AppointmentFeedbackField, text: string) {
		if (!x.appointmentId) return null;
		const request = await this.api.client.post<AppointmentFeedbackClientDto>(`${this.baseUrl}/save-feedback-field`, { feedbackId: x.id, field, text });
		return request.data;
	}

	async completeFeedback(x: AppointmentFeedbackClientDto) {
		if (!x.id) return null;
		const request = await this.api.client.post<AppointmentFeedbackClientDto>(`${this.baseUrl}/complete-feedback/${x.id}`, {});
		return request.data;
	}

	async doneAllChecked(x: AppointmentFeedbackClientDto) {
		if (!x.id) return null;
		const request = await this.api.client.post<AppointmentFeedbackClientDto>(`${this.baseUrl}/done-all-checked-feedback/${x.id}`, {});
		return request.data;
	}

	async startEdit(x: AppointmentFeedbackClientDto) {
		if (!x.id) return null;
		const request = await this.api.client.post<NiceResponse<AppointmentFeedbackClientDto>>(`${this.baseUrl}/start-edit/${x.id}`, {});
		return request.data;
	}

	async saveEdit(x: AppointmentFeedbackClientDto) {
		if (!x.id) return null;
		const request = await this.api.client.post<NiceResponse<AppointmentFeedbackClientDto>>(`${this.baseUrl}/save-edit`, { ...x });
		return request.data;
	}

	async cancelEdit(x: AppointmentFeedbackClientDto) {
		if (!x.id) return null;
		const request = await this.api.client.post<NiceResponse<AppointmentFeedbackClientDto>>(`${this.baseUrl}/cancel-edit/${x.id}`, {});
		return request.data;
	}

	async deleteAppointment(appointment: AppointmentClientDto) {
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/delete-appointment/${appointment.id}`);
		return request.data;
	}

	async goBackEditFeedback(x: AppointmentFeedbackClientDto) {
		const request = await this.api.client.post<AppointmentFeedbackClientDto>(`${this.baseUrl}/go-back-edit-feedback/${x.id}`, {});
		return request.data;
	}

	async addNewNote(appointmentId: number | null) {
		if (!appointmentId) return null;
		const request = await this.api.client.post<AppointmentFeedbackNote>(`${this.baseUrl}/add-note`, { toAppointmentId: appointmentId, ambient: false });
		return request.data;
	}

	async saveNote(note: AppointmentFeedbackNote) {
		const request = await this.api.client.post<AppointmentFeedbackNote>(`${this.baseUrl}/save-note`, { id: note.id, text: note.text });
		return request.data;
	}

	async deleteNote(note: AppointmentFeedbackNote) {
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/delete-note/${note.id}`);
		return request.data;
	}

	async rateFeedBack(feedbackId: number, rating: number) {
		const request = await this.api.client.post<boolean>(`${this.baseUrl}/rate-feedback`, { feedbackId, rating });
		return request.data;
	}


	// get signed link for audio upload
	async uploadAudioLink(audio: AppointmentFeedbackAudio) {
		const filename = `${audio.uri}.webm`
		const request = await this.api.client.post<{ id: number, link: string }>(`${this.baseUrl}/upload-audio-link`, { ...audio, fileName: filename });
		return request.data;
	}

	// finalize the audio upload
	async uploadAudioFinalize(audio: AppointmentFeedbackAudio) {
		const request = await this.api.client.post<AppointmentFeedbackAudio>(`${this.baseUrl}/upload-audio-finalize`, { id: audio.id });
		return request.data;
	}

	// upload audio using signed link
	async uploadAudio(uploadLink: string, audio: AppointmentFeedbackAudio, blob: Blob) {
		if (!audio.uri) return null;
		console.log(blob, 'blob');

		try {
			const filename = `${audio.uri}.webm`

			const uploadResponse = await axios.put(uploadLink, blob, {
				headers: {
					'Content-Type': blob.type,
					'Content-Disposition': `attachment; filename=${filename}`,
				}
			});

			return uploadResponse;
		} catch (error) {
			console.error('Upload to Storage using upload link failed:', error);
			throw error;
		}
	}


	async getAudioUrl(audio: AppointmentFeedbackAudio) {
		if (!audio.id) return null;
		const request = await this.api.client.get<string>(`${this.baseUrl}/audio-url/${audio.id}`);
		return request.data;
	}

	async deleteAudio(audio: AppointmentFeedbackAudio) {
		if (!audio.id) return null;
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/audio-delete/${audio.id}`);
		return request.data;
	}

	//message

	async sendMessage(text: string, feedbackId: number) {
		const request = await this.api.client.post<AppointmentMessageClientDto>(`${this.baseUrl}/send-message`, { text, feedbackId });
		return request.data;
	}

	async getMessages(feedbackId: number) {
		const request = await this.api.client.get<AppointmentMessageClientDto[]>(`${this.baseUrl}/messages/${feedbackId}`);
		return request.data;
	}

	async removeMessage(messageId: number) {
		const request = await this.api.client.delete<boolean>(`${this.baseUrl}/message-remove/${messageId}`);
		return request.data;
	}

	async closeChat(feedbackId: number) {
		const request = await this.api.client.post<boolean>(`${this.baseUrl}/close-chat/${feedbackId}`);
		return request.data;
	}

	async getChatData(feedbackId: number) {
		const request = await this.api.client.get<AppointmentChatClientDto>(`${this.baseUrl}/chat-info/${feedbackId}`);
		return request.data;
	}


}