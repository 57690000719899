export const extractTextWithLineBreaks = (element: HTMLElement): string => {
    const traverse = (node: ChildNode): string => {
        let text = '';
        node.childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
                text += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                if ((child as HTMLElement).tagName === 'BR') {
                    text += '\n';
                } else {
                    text += traverse(child);
                    const blockElements = ['DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'LI'];
                    if (blockElements.includes((child as HTMLElement).tagName)) {
                        text += '\n';
                    }
                }
            }
        });
        return text;
    };
    return traverse(element).trim();
};