
import { PracticeSimple } from "api/model/SyncVet/PracticeSimple";
import ApiClient from "../../base/ApiClient";
import { NiceResponse } from "../../base/NiceResponse";
import { PagingResponse } from "../../base/Paging";
import { Practice } from "../../model/SyncVet/Practice";
import { PracticeListRequest } from "./Model/OfficeListRequest";


export default class AdminPracticeApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/practice";

	async list(req: PracticeListRequest) {
		const request = await this.api.client.post<PagingResponse<Practice>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async listSimple(req: PracticeListRequest) {
		const request = await this.api.client.post<PagingResponse<PracticeSimple>>(`${this.baseUrl}/listSimple`, { ...req });
		return request.data;
	}

	async add(syncVetPracticeId: string, name: string, noSync: boolean) {
		const request = await this.api.client.post<NiceResponse<Practice>>(`${this.baseUrl}/add`,
			{ syncVetPracticeId: syncVetPracticeId, name: name, noSync: noSync });
		return request.data;
	}

	async delete(x: Practice) {
		const request = await this.api.client.delete<NiceResponse<Practice>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}


	async restore(x: Practice) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

	async forceSync(x: Practice) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/forceSync/${x.id}`);
		return request.data;
	}

	async disable(x: Practice) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/disable/${x.id}`);
		return request.data;
	}

	async refreshAccessible() {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/getAll`);
		return request.data;
	}
}