import React, { useEffect } from 'react';
import './style.scss';
import { Route, Routes, BrowserRouter, useLocation, Navigate, useNavigate } from "react-router-dom";
import AdminLayout from './layouts/admin/AdminLayout';
import PublicLayout from './layouts/non-auth/PublicLayout';
import NotFound from 'routes/public/NotFound/NotFound';
import Login from 'routes/auth/Login';
import Register from 'routes/auth/Register';
import ForgotPassword from 'routes/auth/ForgotPassword';
import Logout from 'routes/auth/Logout';
import Access from './layouts/admin/Access';
import UserList from 'routes/user/UserList';
import UserView from 'routes/user/UserView';
import { UserRoleEnum } from 'api/model/User/UserRole';
import AppointmentList from 'routes/appointment/AppointmentList';
import FeedbackList from 'routes/feedback/FeedbackList';
import FeedbackView from 'routes/feedback/FeedbackView';
import PracticeList from 'routes/practices/PracticeList';
import OfficeList from 'routes/office/OfficeList';
import DoctorList from 'routes/doctor/DoctorList';
import PatientList from 'routes/patient/PatientList';
import ClientList from 'routes/client/ClientList';
import VoiceWordList from 'routes/dictionary/VoiceWordList';
import NewPassword from 'routes/auth/NewPassword';
import BillingList from 'routes/billing/BillingList';
import Terms from 'routes/policy/Terms';
import TemplateList from 'routes/template/TemplateList';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import PracticeListSimple from 'routes/practices/PracticeListSimple';
import AccountList from 'routes/accounts/AccountList';
import QuickStart from 'routes/auth/QuickStart/QuickStart';
import VSRecording from 'vs-recording/VSRecording';
import HelpCenter from 'routes/helpCenter/HelpCenter';
import UserReportList from 'routes/analytics/UserReportList';
import TotalsAnalytics from 'routes/analytics/TotalsReport';
import TotalsReport from 'routes/analytics/TotalsReport';

const AppRoutes: React.FC = () => {
	const user = useCurrentUser();

	return <Routes>
		<Route element={<PublicLayout />}>
			<Route path="/auth" >
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="forgot" element={<ForgotPassword />} />
				<Route path="newPassword" element={<NewPassword />} />
				<Route path="logout" element={<Logout />} />
				<Route path="initial-setup" element={<QuickStart />} />
			</Route>
			<Route path="signup" element={<Register />} />
			<Route path="login" element={<Login />} />
			<Route path="initial-setup" element={<QuickStart />} />
			<Route path="terms" element={<Terms />} />
		</Route>

		<Route element={<AdminLayout />} path="/">

			<Route index element={<Navigate to={user.hasRole([UserRoleEnum.PracticeSuperUser]) ? "/dashboard" : "/recordings"} />} />

			<Route element={<Access roles={[]} />}>
				<Route path="help-center" element={<HelpCenter />} />
			</Route>

			<Route element={<Access roles={[]} />} >
				<Route path="/rec" element={<Navigate to="/rec/schedule" replace />} />
				<Route path="/rec/schedule" element={<VSRecording />} />
				<Route path="/rec/appointment/:id" element={<VSRecording />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="users" element={<UserList />} />
				<Route path="users/:id" element={<UserView />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.Admin, UserRoleEnum.PracticeSuperUser, UserRoleEnum.AccountOwner, UserRoleEnum.AccountManager]} />} >
				<Route path="templates" element={<TemplateList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="users" element={<UserList />} />
				<Route path="users/:id" element={<UserView />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="offices" element={<OfficeList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="doctors" element={<DoctorList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="patients" element={<PatientList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="clients" element={<ClientList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="practices" element={
					!user.currentAccountId ? <PracticeList /> : <PracticeListSimple />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin, UserRoleEnum.AccountOwner]} />} >
				<Route path="appointments" element={<AppointmentList />} />
			</Route>

			<Route
				element={<Access roles={[]} />} >
				<Route path="recordings" element={<FeedbackList />} />
				<Route path="recordings/:id" element={<FeedbackView />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin]} />} >
				<Route path="voice_words" element={<VoiceWordList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin]} />} >
				<Route path="analytics/users" element={<UserReportList />} />
				<Route path="analytics/totals" element={<TotalsReport />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin]} />} >
				<Route path="billing" element={<BillingList />} />
			</Route>

			<Route element={<Access roles={[UserRoleEnum.SuperAdmin]} />} >
				<Route path="accounts" element={<AccountList />} />
			</Route>



		</Route>

		<Route path="*" element={<NotFound />} />
	</Routes >
}

export default AppRoutes;


