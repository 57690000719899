
import ApiClient from "../../../base/ApiClient";
import { NiceResponse } from "../../../base/NiceResponse";
import { PagingResponse } from "../../../base/Paging";
import { AdminAppointmentDto } from "./DTO/AdminAppointmentDto";
import { AdminAppointmentUpdateRequest } from "./DTO/AdminAppointmentUpdateRequest";
import { AdminAppointmentListRequest } from "./Model/AdminAppointmentListRequest";

export default class AdminAppointmentApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/appointment";

	async list(req: AdminAppointmentListRequest) {
		const request = await this.api.client.post<PagingResponse<AdminAppointmentDto>>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}

	async get(id: number | null) {
		if (!id) return null;
		const request = await this.api.client.get<AdminAppointmentDto>(`${this.baseUrl}/${id}`);
		return request.data;
	}

	async delete(x: AdminAppointmentDto) {
		const request = await this.api.client.delete<NiceResponse<AdminAppointmentDto>>(`${this.baseUrl}/delete/${x.id}`);
		return request.data;
	}

	async updateCreate(req: AdminAppointmentUpdateRequest) {
		const request = await this.api.client.post<NiceResponse<AdminAppointmentDto>>(`${this.baseUrl}/update-create`, { ...req });
		return request.data;
	}

	async restore(x: AdminAppointmentDto) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/restore/${x.id}`);
		return request.data;
	}

	async deleteFeedback(x: AdminAppointmentDto) {
		const request = await this.api.client.post<NiceResponse<boolean>>(`${this.baseUrl}/delete-feedback/${x.id}`);
		return request.data;
	}


}