import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Row, Space, Table, TablePaginationConfig, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DeleteOutlined, ReloadOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { AdminUserStore } from "./AdminUserStore";
import { User } from "api/model/User/User";
import UserEdit from "./UserEdit";
import { UserRolesList } from "api/model/User/UserRole";
import useCurrentUser from "misc/hooks/useCurrentUser";
import DoctorSelect from "app/components/DoctorSelect";
import { Doctor } from "api/model/Offices/Doctor";
import AccountSelect from "app/components/AccountSelect";
import { log } from "console";

const UserList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<AdminUserStore>(() => new AdminUserStore(api));
	const [editing, setEditing] = React.useState<User>(null);
	const user = useCurrentUser();

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<User> | SorterResult<User>[] | any) => {

		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);

	};

	let columns: ColumnsType<User> = [
		{
			title: 'E-Mail',
			key: 'Email',
			ellipsis: true,
			sorter: true,
			render: (x: User) => <>
				{x.id == user.id && <Tag color="green">You</Tag>}
				<a onClick={() => setEditing(x)} style={{ fontSize: 16 }}>
					{x.email}
				</a>
			</>
		},
		{
			title: 'Name',
			key: 'FirstName',
			ellipsis: true,
			sorter: true,
			render: (x: User) => <>
				<a onClick={() => setEditing(x)} style={{ fontSize: 16 }}>
					{x.firstName || ""} {x.lastName || ""}
				</a>
			</>
		},
		{
			title: 'Doctor',
			key: 'DoctorId',
			render: (x: User) => {
				if (x.doctors.length == 0) return <span className="secondary-text">Not selected</span>
				return x.doctors.map(x => <Tag key={x.id}>{x.firstName} {x.lastName}</Tag>)
			}
		},
		{
			title: 'Accounts',
			key: 'Accounts',
			render: (x: User) => {
				return x.accounts.length > 0 ?
					x.accounts.map(x => <Tag key={x.id} color="blue">{x.name}</Tag>)
					:
					<span className="secondary-text">No account</span>
			}
		},
		{
			title: 'Roles / MISC',
			render: (x: User) => {
				return <>
					{x.roles != null && x.roles.map(x => <Tag color="orange">{UserRolesList.find(r => r.value == x)?.label}</Tag>)}
					{x.isRemoved && <Tag color="magenta">REMOVED</Tag>}
				</>
			}
		},
		{
			title: 'Actions',
			align: "right",
			render: (x: User) => <Space key={x.id}>
				<Button icon={<EditOutlined />} onClick={() => setEditing(x)} />

				{!x.isRemoved ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button danger icon={<DeleteOutlined />} />
					</Popconfirm>
					:
					<Popconfirm title="Are you sure to restore?" onConfirm={() => store.restore(x)}>
						<Button icon={<ReloadOutlined />} />
					</Popconfirm>
				}
			</Space>
		},
	];

	if (user.isSAASUser) {
		columns = columns.filter(x => x.key != "Accounts")
	}

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Users
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<DoctorSelect onChange={(idDoctor: number) => store.setFilter({ idDoctor })} />

			{user.isSuperAdmin && <AccountSelect onChange={idAccount => store.setFilter({ idAccount })} />}


			<Checkbox
				indeterminate={store.filter.isRemoved == null}
				checked={store.filter.isRemoved === true} onClick={() => store.toggleIsRemoved()}
			>
				Removed
			</Checkbox>

			<Checkbox
				indeterminate={store.filter.isAdmin == null}
				checked={store.filter.isAdmin === true} onClick={() => store.toggleIsAdmin()}
			>
				Is Admin
			</Checkbox>


			<Button onClick={() => setEditing({ id: 0 })}>Add new</Button>
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}

		<Drawer destroyOnClose={true} visible={editing != null} onClose={() => setEditing(null)} title={"User Edit"}>
			<UserEdit user={editing} onCancel={() => setEditing(null)} onSuccess={() => store.load() && setEditing(null)} />
		</Drawer>

	</>
})


export default UserList;




