import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './DaysSelector.module.scss';

interface DaysSelectorProps {
    selectedDate: moment.Moment;
    onDayChange: (date: moment.Moment) => void;
    onMonthChange?: (direction: 'previous' | 'next') => void;
}

const DaysSelector: React.FC<DaysSelectorProps> = ({ selectedDate, onDayChange, onMonthChange }) => {
    const [visibleDate, setVisibleDate] = useState(selectedDate);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const daysContainerRef = useRef<HTMLDivElement>(null);
    const dayRefs = useRef<(HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        const checkScrollPosition = () => {
            const { scrollLeft, scrollWidth, clientWidth } = daysContainerRef.current!;
            setIsAtStart(scrollLeft === 0);
            setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
        };

        checkScrollPosition();
        daysContainerRef.current?.addEventListener('scroll', checkScrollPosition);
        return () => daysContainerRef.current?.removeEventListener('scroll', checkScrollPosition);
    }, [visibleDate]);

    useEffect(() => {
        // Scroll to selected day
        const selectedDayRef = dayRefs.current[selectedDate.date() - 1];
        selectedDayRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, [selectedDate]);

    const scrollDays = (direction: 'left' | 'right') => {
        const scrollAmount = direction === 'left' ? -700 : 700;
        daysContainerRef.current?.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    };

    const handleMonthChange = (direction: 'previous' | 'next') => {
        const newDate = direction === 'previous' ? visibleDate.clone().subtract(1, 'month') : visibleDate.clone().add(1, 'month');
        setVisibleDate(newDate);
        onMonthChange?.(direction);
    };

    const daysInMonth = Array.from({ length: visibleDate.daysInMonth() }, (_, i) => {
        return moment(visibleDate).date(i + 1);
    });

    if (dayRefs.current.length !== daysInMonth.length) {
        dayRefs.current = Array(daysInMonth.length).fill(null);
    }

    return <>
        <div className={styles.monthSelector}>
            <Button icon={<LeftOutlined />} size='small' onClick={() => handleMonthChange('previous')} />
            <span className='fs-18 fw-500'>{visibleDate.format('MMMM YYYY')}</span>
            <Button icon={<RightOutlined />} size='small' onClick={() => handleMonthChange('next')} />
        </div>
        {!isAtStart && (
            <Button icon={<LeftOutlined />} className={`${styles.scrollButton} ${styles.leftArrow}`} onClick={() => scrollDays('left')} />
        )}
        <div className={styles.container} ref={daysContainerRef}>
            {daysInMonth.map((date, index) => {
                const isActive = date.isSame(selectedDate, 'day');
                return <div className={styles.dayWrapper} key={index} data-active={isActive}>
                    <button
                        ref={el => dayRefs.current[index] = el}
                        onClick={() => onDayChange(date)}>
                        <span className={styles.dayText}>{date.date()}</span>
                        <span className={styles.weekdayText}>{date.format('ddd')}</span>
                    </button>
                </div>;
            })}
        </div>
        {!isAtEnd && (
            <Button icon={<RightOutlined />} className={`${styles.scrollButton} ${styles.rightArrow}`} onClick={() => scrollDays('right')} />
        )}
    </>;
};

export default DaysSelector;
