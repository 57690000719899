import React from "react";
import { Modal, ModalProps } from "antd";
import useDrawer from "misc/hooks/useDrawer";



const getEmbedUrl = (url: string): string => {
    const youtubeMatch = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    const vimeoMatch = url.match(/vimeo\.com\/(\d+)/);

    if (youtubeMatch) {
        return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (vimeoMatch) {
        return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }
}

type Props = ModalProps & {
    videoUrl?: string;
}

const VideoModal: React.FC<Props> = ({ videoUrl, ...modalProps }) => {

    return <div>
        <Modal {...modalProps}>
            {videoUrl && <VideoModalContent videoUrl={videoUrl} />}
        </Modal>
    </div>;
}

export default VideoModal;

const VideoModalContent: React.FC<{ videoUrl: string }> = ({ videoUrl }) => {
    const embedUrl = getEmbedUrl(videoUrl);

    if (!embedUrl) {
        return <div>Invalid video URL</div>;
    }

    return (
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <iframe
                src={embedUrl}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video"
            />
        </div>
    );
}