import React, { useEffect, useRef, useState } from 'react';
import styles from './MicActivityIndicator.module.scss';

type MicActivityIndicatorProps = {
    type: "horizontal" | "vertical";
    width: string;
    height: string;
    stream: MediaStream | null;
    isRecording?: boolean;
};

const MicActivityIndicator: React.FC<MicActivityIndicatorProps> = ({ type, width, height, stream, isRecording }) => {
    const indicatorRef = useRef<HTMLDivElement>(null);
    const [indicatorSize, setIndicatorSize] = useState(0);
    const [noInput, setNoInput] = useState(false);
    const [showBlinkingText, setShowBlinkingText] = useState(false);
    const max = 50;

    const rounder = (what: number, number: number) => Math.ceil(what / number) * number;

    useEffect(() => {
        if (indicatorRef.current) {
            initAnalyzer();
        }

        return () => {
            stopAnalyzer();
        };
    }, [stream]);

    let analyser: AnalyserNode | null = null;
    let javascriptNode: ScriptProcessorNode | null = null;
    let noInputTimeout: NodeJS.Timeout | null = null;

    // Измените функцию analyzeAudio
    const analyzeAudio = () => {
        if (!analyser) return;
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;
        const length = array.length;
        for (let i = 0; i < length; i++) {
            values += array[i];
        }
        const average = values / length;

        const levelPercent = average / max * 100;

        const currentSize = rounder(levelPercent, 5);
        if (currentSize !== indicatorSize) {
            setIndicatorSize(currentSize);
        }

        if (currentSize === 0 && !noInput) {
            noInputTimeout = setTimeout(() => {
                if (isRecording) {
                    setShowBlinkingText(true);
                }
                setNoInput(true);
            }, 2000);
        } else if (currentSize > 0) {
            clearTimeout(noInputTimeout!);
            setNoInput(false);
            setShowBlinkingText(false);
        }
    };

    const initAnalyzer = () => {
        if (!stream) return;
        const audioContext = new AudioContext();

        analyser = audioContext.createAnalyser();
        analyser.smoothingTimeConstant = 0.3;
        analyser.fftSize = 1024;

        const microphone = audioContext.createMediaStreamSource(stream);
        javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        microphone.connect(analyser);
        analyser.connect(javascriptNode);

        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = analyzeAudio;
    };

    const stopAnalyzer = () => {
        if (!javascriptNode) return;
        javascriptNode.onaudioprocess = null;
    };

    return <div className={`${styles.micActivityIndicator} ${type === "horizontal" ? styles.h : styles.v}`} ref={indicatorRef} style={{ width, height }}>
        {type === "horizontal"
            ? <div className={styles.indicator} style={{ width: 100 - indicatorSize + "%", height: "100%" }}>
                {noInput && !isRecording && <div className={styles.noInput}>No input sound. Please select proper recording device below</div>}
                {noInput && isRecording && <div className={`${styles.noInput} ${showBlinkingText ? styles.blink : ''}`}>No input sound. Please select proper recording device below</div>}
            </div>
            : <div className={styles.indicator} style={{ width: "100%", height: 100 - indicatorSize + "%" }}>
                {noInput && !isRecording && <div className={styles.noInput}>No input sound. Please select proper recording device below</div>}
                {noInput && isRecording && <div className={`${styles.noInput} ${showBlinkingText ? styles.blink : ''}`}>No input sound. Please select proper recording device below</div>}
            </div>}
    </div>;
};

export default MicActivityIndicator;
