import { Spin, StepProps, Steps, Typography } from "antd";
import WizardStatus from "api/model/Account/WizardStatus";
import Logo from "app/components/Logo";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useDevice from "misc/hooks/useDevice";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DoctorsStep from "./DoctorsStep";
import DoneStep from "./DoneStep";
import HospitalsStep from "./HostpitalsStep";
import styles from './QuickStart.module.scss';

const QuickStart: React.FC = observer(() => {
    const api = useApi();
    const user = useCurrentUser();
    const isNewUser = user.accounts.length == 0 && user.isSAASUser;
    const { device } = useDevice();

    const [wizard, setWizard] = useState<WizardStatus | null>();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        loadWizard()
    }, []);

    useEffect(() => {
        if (!wizard) return;
        selectCurrentStep();
    }, [wizard]);

    const loadWizard = useCallback(async () => {
        setLoading(true);
        const res = await api.adminAccounts.quickStartStatus(user.currentAccountId);
        setWizard(res);
        setLoading(false);
    }, []);

    const completeQuickStart = useCallback(async () => {
        await api.adminAccounts.quickStartComplete(user.currentAccountId);
        window.location.href = '/help-center';
    }, []);

    const selectCurrentStep = useCallback(() => {
        let currentStep;
        if (!wizard?.doctors) {
            currentStep = 0;
        } else {
            currentStep = 1;
        }

        setCurrentStep(currentStep);
    }, [wizard]);

    const goBack = useCallback(() => {
        setCurrentStep(prevStep => Math.max(0, prevStep - 1));
    }, [currentStep]);

    const goNext = useCallback(() => {
        setCurrentStep(prevStep => prevStep + 1);
    }, [currentStep]);

    //currentStep == 0 ? 'wait' : 'process'

    const steps = useMemo(() => ([
        // {
        //     title: 'Add Locations',
        //     content: <HospitalsStep onNext={goNext} />,
        //     status: currentStep > 0 ?
        //         'finish' :
        //         currentStep == 0 ? 'process' : 'wait',
        // },
        {
            title: 'Add Doctors',
            content: <DoctorsStep onBack={goBack} onNext={goNext} />,
            status: currentStep > 0 ?
                'finish' :
                currentStep == 0 ? 'process' : 'wait',

        },
        {
            title: 'Download the VetSkribe App',
            content: <DoneStep onBack={goBack} onNext={completeQuickStart} />,
            status: currentStep > 1 ?
                'finish' :
                currentStep == 1 ? 'process' : 'wait',
        },
    ] as (StepProps & { content: React.ReactNode })[]), [currentStep]);

    if (loading) return <div className={'text-center m-20'}><Spin /></div>

    return <div className={styles.initial_setup_wrapper} data-mobile={device.isMobile}>
        <Logo size={100} />

        <div className="flex-1 text-center">
            {/* {
                currentStep == 0 &&
                <>
                    <h2 className="text-center">Let's get started!</h2>
                    <p className="text-center mb-50">In order to use VetSkribe, you need to add at least one hospital.</p>
                </>
            } */}

            {
                currentStep == 0 &&
                <>
                    {/* <h2 className="text-center">Great! You created your hospital(s)!</h2> */}
                    <h2 className="text-center">Great!</h2>
                    <p className="text-center mb-50">Now, let’s add doctors who will use the service.</p>
                </>
            }

            {
                currentStep == 1 &&
                <>
                    <h2 className="text-center">Awesome!</h2>
                    <p className="text-center mb-50">You're ready to start recording.</p>
                </>
            }

        </div>

        {!device.isMobile
            ? <Steps current={currentStep} direction={'horizontal'} style={{ marginBottom: 30 }} items={steps} />
            : <Typography.Title level={3} className='text-center'>{steps[currentStep].title}</Typography.Title>
        }

        <div>{steps[currentStep]?.content}</div>
    </div>
});

export default QuickStart;