export enum UserRoleEnum {
	Admin = 0,
	Scribe = 100,
	Supervisor = 200,
	Vet = 201,
	UserEditor = 300,
	PracticeSuperUser = 400,
	AccountOwner = 500,
	AccountManager = 501,
	AccountScribe = 502,
	VetTech = 600,
	AccountVetTech = 700,
	SuperAdmin = 1000
}

export const SAASUserRoles = [
	UserRoleEnum.AccountOwner,
	UserRoleEnum.AccountManager,
	UserRoleEnum.AccountScribe,
	UserRoleEnum.AccountVetTech
]

export const UserRolesList = [
	{ value: UserRoleEnum.PracticeSuperUser, label: "Practice Super User" },
	{ value: UserRoleEnum.Admin, label: "Admin" },
	{ value: UserRoleEnum.Scribe, label: "Scriber" },
	{ value: UserRoleEnum.Supervisor, label: "Supervisor" },
	{ value: UserRoleEnum.Vet, label: "Vet" },
	{ value: UserRoleEnum.SuperAdmin, label: "Super Admin" },
	{ value: UserRoleEnum.UserEditor, label: "User Editor" },
	{ value: UserRoleEnum.VetTech, label: "Vet Tech" },
	{
		value: UserRoleEnum.AccountOwner,
		label: "Account Owner",
		description: "Person that initially created the account. Invoices for all doctors in the hospital using VetSkribe will be sent to the “Account Owner” attention. The account owner can add new hospitals, doctors and users to the account."
	},
	{
		value: UserRoleEnum.AccountManager,
		label: "Account Manager",
		description: "The account manager can add new hospitals, doctors and users to the account. The account manager will not have access to the account invoicing."
	},
	{
		value: UserRoleEnum.AccountScribe,
		label: "Account Scribe",
		description: "An individual responsible for editing and formatting the notes then, importing the transcriptions to the practice management software. Scribes don’t have access to the app and they don’t do any recordings."
	},
	{
		value: UserRoleEnum.AccountVetTech,
		label: "Account Vet Tech",
		description: "Vet tech can create appointments, make recordings, but not submit them."
	}
]

export const SAASUserRolesList = [
	{
		value: UserRoleEnum.AccountOwner,
		label: "Account Owner",
		description: "Person that initially created the account. Invoices for all doctors in the hospital using VetSkribe will be sent to the “Account Owner” attention. The account owner can add new hospitals, doctors and users to the account."
	},
	{
		value: UserRoleEnum.AccountManager,
		label: "Account Manager",
		description: "The account manager can add new hospitals, doctors and users to the account. The account manager will not have access to the account invoicing."
	},
	{
		value: UserRoleEnum.AccountScribe,
		label: "Account Scribe",
		description: "An individual responsible for editing and formatting the notes then, importing the transcriptions to the practice management software. Scribes don’t have access to the app and they don’t do any recordings."
	},
	{
		value: UserRoleEnum.AccountVetTech,
		label: "Account Vet Tech",
		description: "Vet tech can create appointments, make recordings, but not submit them."
	}
]

export const UserRoleNoSaaSList = UserRolesList.filter(x => !SAASUserRoles.includes(x.value))
