import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { makeAutoObservable } from "mobx";
import { BillingFilter, BillingGroupingPeriod } from "api/routes/Billing/Model/BillingFilter";
import moment from "moment";
import { BillingDto } from "api/routes/Billing/Model/BillingDto";
import { AccountListRequest } from "api/routes/Accounts/Model/AccountListRequest";
import { Account } from "api/model/Account/Account";

export class AccountStore {
	loading: boolean = false;
	error: string | null = null;

	filter: AccountListRequest = {
		page: 1,
		pageSize: 25,
		search: null,
		sortField: "CreatedDate",
		sortDirection: "desc"
	}

	pagedData: PagingResponse<Account> | null = null;
	notificationApi?: Notification;

	constructor(private api: ApiClient, p?: Partial<AccountStore>) {
		if (p) {
			Object.assign(this, p);
		}
		makeAutoObservable(this);
		this.load();
	}

	load = async () => {
		this.setState({ error: null, loading: true });

		try {
			const r = await this.api.adminAccounts.allAccounts(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter,
				}
			});
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	};

	delete = async (x: Account) => {
		this.setState({ error: null, loading: true });

		try {
			await this.api.adminAccounts.deleteAccount(x.id);
			this.load();
		} catch (e: any) {
			this.setState({ error: e.error || "Error occured" });
		}
		this.setState({ loading: false });
	}


	setState = (p: Partial<AccountStore>) => {
		Object.assign(this, p);
	};

	setFilter = (p: Partial<AccountListRequest>, shouldResetPage: boolean = true) => {
		Object.assign(this.filter, p);
		this.load();
	};

}
