// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BWdSjVjfWO3_FWupEpho .N2gL7gjI8LBwONdPgXEE>li{margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/routes/helpCenter/HelpCenter.module.scss"],"names":[],"mappings":"AAEI,+CACI,kBAAA","sourcesContent":[".instruction_wrapper {\r\n\r\n    .main_list>li {\r\n        margin-bottom: 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instruction_wrapper": "BWdSjVjfWO3_FWupEpho",
	"main_list": "N2gL7gjI8LBwONdPgXEE"
};
export default ___CSS_LOADER_EXPORT___;
