import React from "react";
import { Alert, Breadcrumb, Button, Divider, Drawer, Input, notification, Popconfirm, Select, Space, Table, TablePaginationConfig, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link, useParams, useRoutes, useSearchParams } from "react-router-dom";
import { DeleteOutlined, ReloadOutlined, ApiOutlined, StopOutlined } from "@ant-design/icons";
import { Appointment } from "api/model/Appointments/Appointment";
import { PracticeStore } from "./PracticeStore";
import { Practice } from "api/model/SyncVet/Practice";
import { SyncVetPracticeStatus, SyncVetPracticeStatusList } from "api/model/SyncVet/SyncVetPracticeStatus";
import AddPractice from "./AddPractice";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { UserRoleEnum } from "api/model/User/UserRole";


const PracticeList: React.FC = observer(() => {
	const api = useApi();
	const user = useCurrentUser();
	const store = useLocalObservable<PracticeStore>(() => new PracticeStore(api));
	const [creatingPractice, setCreatingPractice] = React.useState(false);
	const [lockButton, setLockButton] = React.useState(false);
	const currentUser = useCurrentUser();


	const startAdd = () => {
		setCreatingPractice(true);
	}

	const finishAdd = () => {
		setCreatingPractice(false);
	}

	const onAdded = () => {
		finishAdd();
		store.load();
	}


	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Appointment> | SorterResult<Appointment>[] | any) => {

		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};


	const columns: ColumnsType<Practice> = [
		{
			title: 'CreatedDate',
			key: 'CreatedDate',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Practice) => <>
				{moment(x.createdDate).format("LLL")}
			</>
		},
		{
			title: 'Name',
			key: 'name',
			ellipsis: true,
			render: (x: Practice) => <>
				<span style={{ fontSize: 16 }}>
					{x.name}
				</span>
			</>
		},
		{
			title: 'Account',
			key: 'account',
			ellipsis: true,
			render: (x: Practice) => <>
				{!x.account ?
					<span className="secondary-text">No account</span>
					:
					<span >
						{x.account?.name}
					</span>
				}
			</>
		},
		{
			title: 'Sync Vet Status',
			key: 'Status',
			ellipsis: true,
			render: (x: Practice) => <>
				<div style={{ fontSize: 16 }}>
					{x.status == SyncVetPracticeStatus.Error ?
						<Tooltip title={x.exception}>
							<Tag color="red">Error</Tag>
						</Tooltip>
						:
						<Tag color="green"> {SyncVetPracticeStatusList.find(y => y.value == x.status)?.label}</Tag>
					}

					{x.softDeleted ? <Tag color="red">Deleted</Tag> : null}
				</div>

				{x.status != SyncVetPracticeStatus.Disabled &&
					<>
						<div style={{ fontSize: 12 }}>
							Last sync: {" "}
							{x.lastSyncDateTime ? moment(x.lastSyncDateTime).format("LLL") : <span className="secondary-text">No data</span>}
						</div>

						<div style={{ fontSize: 12 }}>
							Data sync: {" "}
							{x.dataDownloadedFromDate ? moment(x.dataDownloadedFromDate).format("LLL") : <span className="secondary-text">No data</span>}
						</div>
					</>
				}
			</>
		},
		{
			title: 'Actions',
			render: (x: Practice) => {
				var isStuck = x.updatedDate ? Math.abs(moment(x.updatedDate).diff(moment(), "minutes")) > 5 : true;
				return <Space>
					{isStuck &&
						<Tooltip title={"Force Sync with Sync Vet"}>
							<Button icon={<ApiOutlined />} onClick={async () => {
								if (lockButton) return;
								setLockButton(true);
								await api.adminPractice.forceSync(x);
								await store.load();
								setLockButton(false);
							}} />
						</Tooltip>}

					{x.status != SyncVetPracticeStatus.Disabled &&
						<Tooltip title={"Disable Sync Vet"}>
							<Button icon={<StopOutlined />} onClick={async () => {
								await api.adminPractice.disable(x);
								await store.load();
							}} />
						</Tooltip>}


					{!x.softDeleted ?
						<Popconfirm onConfirm={() => store.delete(x)} title="Delete this Hospital?">
							<Button icon={<DeleteOutlined />} />
						</Popconfirm>
						:
						<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
					}
				</Space>
			}
		}
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Hospitals
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />

		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			{currentUser.isSuperAdmin &&
				<Select style={{ width: 200 }}
					defaultValue={store.filter.status}
					onChange={(value: SyncVetPracticeStatus | null) => store.setFilter({ status: value })}>
					<Select.Option value={null}>All statuses</Select.Option>
					{SyncVetPracticeStatusList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
				</Select>
			}


			<Button onClick={startAdd}>Add new Hospital</Button>

			{currentUser.isSuperAdmin &&
				<Button loading={store.loading} onClick={() => store.refreshAccessible()}>Refresh accessible Hospitals</Button>
			}

			<Drawer open={creatingPractice} onClose={finishAdd} title="Create new Hospital">
				<AddPractice onSuccess={onAdded} onCancel={finishAdd} />
			</Drawer>
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				store.pagedData?.data?.length == 0 ?
					<Alert message="You have not added any Hospital" type="info" />
					:
					<Table
						onChange={handleTableChange}
						loading={store.loading}
						rowKey={x => x.id ? x.id : ""}
						columns={columns}
						dataSource={store.pagedData?.data}
						pagination={{
							hideOnSinglePage: true,
							current: store.pagedData?.page,
							total: store.pagedData?.total,
							pageSize: store.pagedData?.pageSize,
							showSizeChanger: true,
							showTotal: (total: number) => `Total: ${total}`
						}}
					/>
		}
	</>
})


export default PracticeList;
