import useCurrentUser from "misc/hooks/useCurrentUser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Logout: React.FC = () => {
    const nav = useNavigate();
    const user = useCurrentUser();

    useEffect(() => {
        user.logout();
        nav("/");
    }, []);

    return null;
}

export default Logout;