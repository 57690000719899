import ApiClient from "api/base/ApiClient";
import { UserRoleEnum } from "api/model/User/UserRole";
import Cookies from "js-cookie";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { AppSettings } from "../appSettings";
import { AccountSimple } from "api/model/Account/AccountSimple";
import { LanguageEnum } from "api/model/User/Language";

export default class CurrentUser {
	@observable isAuthorized: boolean = false;
	@observable id?: number;
	@observable firstName?: string;
	@observable email?: string;
	@observable phone?: string;
	@observable lastName?: string;
	@observable avatarUrl?: string;
	@observable isAdmin?: boolean;
	@observable roles: UserRoleEnum[] = [];
	@observable currentUserTestId?: number;
	@observable acceptTerms?: boolean;
	@observable recordingsTotal?: number;
	@observable recordingsSupervisor?: number;
	@observable recordingsVet?: number;
	@observable unreadChats: number[] = [];
	@observable accounts: AccountSimple[] = [];
	@observable currentAccountId: number | null = null;
	@observable doctorIds: number[] = [];
	@observable preferredLanguage?: LanguageEnum;
	@observable practiceIds: number[] = [];

	constructor(private apiClient: ApiClient) {
		makeObservable(this);

		reaction(() => this.currentAccountId, async (accountId) => {
			this.apiClient.currentAccountId = accountId;
		});
	}

	get isSaasDoctor() {
		return this.accounts && this.accounts?.length > 0;
	}

	get isVetTech() {
		return this.isInRole([UserRoleEnum.VetTech]) || this.isInRole([UserRoleEnum.AccountVetTech]);
	}

	get isSaasVetTech() {
		return this.isVetTech && this.isSaasDoctor;
	}

	async init(apiClient: ApiClient) {
		this.apiClient = apiClient;

		if (this.refreshToken && !this.accessToken) {
			var accessToken = await this.apiClient.user.refresh(this.refreshToken);
			this.auth(accessToken);
		}

		if (!this.accessToken) return;

		const response = await this.apiClient.user.getCurrentUser();
		if (response) {
			this.setData({ ...response, isAuthorized: true });
			this.autoSelectAccount();
		} else {
			this.setData({ isAuthorized: false });
		}
	}

	@action
	private autoSelectAccount() {
		var savedAccount = localStorage.getItem("selectedAccount");
		if (savedAccount) {
			if (this.accounts.map(x => x.id).includes(Number(savedAccount))) {
				this.currentAccountId = Number(savedAccount);
				return
			} else {
				localStorage.removeItem("selectedAccount");
			}
		}

		if (this.accounts.length > 0) {
			this.currentAccountId = this.accounts[0].id;
			localStorage.setItem("selectedAccount", this.currentAccountId.toString());
		}
	}

	@action
	selectAccount(accountId: number) {
		if (!this.accounts.map(x => x.id).includes(accountId)) return;
		this.currentAccountId = accountId;
		localStorage.setItem("selectedAccount", accountId.toString());
	}

	get displayName() {
		return `${this.firstName} ${this.lastName}`
	}

	update(data?: Partial<CurrentUser>) {
		if (data && Object.keys(data).length > 0) this.setData(data);
	}

	isInRole(requiredRoles: UserRoleEnum[]) {
		if (requiredRoles.length == 0) return true;
		return this.hasRole(requiredRoles);
	}

	get isSuperAdmin() {
		return this.roles.some(x => x === UserRoleEnum.SuperAdmin);
	}

	get isSAASUser() {
		return this.isSuperAdmin == false && this.accounts.length > 0;
	}

	get isRestrictedUser() {
		if (this.roles.length == 0) return true;
	}

	@computed get quickStartCompleted() {
		if (this.isSAASUser == false) return true;
		//check if current account needs to complete quick start
		var account = this.accounts.find(x => x.id == this.currentAccountId);
		if (!account) return false;
		return account.quickStartCompleted;
	}

	hasRole(requiredRoles: UserRoleEnum[]) {
		return this.roles.some(existingRole => requiredRoles.includes(existingRole))
	}

	isOnlyScribe() {
		var liftedPrivileges = [UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Vet];
		if (this.roles.some(x => liftedPrivileges.includes(x))) return false;
		return this.roles.some(x => x == UserRoleEnum.Scribe)
	}


	@action
	unreadChatRemove(id: number) {
		this.unreadChats = this.unreadChats.filter(x => x !== id);
	}

	@action
	unreadChatAdd(id: number) {
		this.unreadChats.push(id);
	}

	@action
	setData(data?: Partial<CurrentUser>) {
		if (data) Object.assign(this, data);
	}

	logout() {
		this.isAuthorized = false;
		Cookies.remove(AppSettings.auth.access.name);
		Cookies.remove(AppSettings.auth.refresh.name);
	}

	get accessToken() {
		return Cookies.get(AppSettings.auth.access.name);
	}

	get refreshToken() {
		return Cookies.get(AppSettings.auth.refresh.name);
	}

	@action
	async auth(access?: string, refresh?: string) {
		this.setData({ isAuthorized: true });
		if (access)
			Cookies.set(AppSettings.auth.access.name, access, { expires: AppSettings.auth.access.durationDays });
		if (refresh)
			Cookies.set(AppSettings.auth.refresh.name, refresh, { expires: AppSettings.auth.refresh.durationDays });

		await this.init(this.apiClient);
	}
}

