import { Alert, Input, Button, Form, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "app/components/Logo";
import useVSHub from "misc/hooks/useVSHub";
import styles from './Auth.module.scss';
import combineClasses from "misc/helpers/combineClasses";


const ForgotPassword: React.FC = () => {
    const [state, setState] = useState<{ codeSent: boolean, email: string | null }>({ codeSent: false, email: null });

    return <div className={combineClasses(styles.login_wrapper, "fadeInLeft")}>
        <div style={{ textAlign: "center" }}>
            <Logo size={200} />
            <Typography.Title>Forgot Password</Typography.Title>
            <Typography.Paragraph className='color-white fs-18'>Back to <Link to="/auth/login" className="color-brown">Login</Link></Typography.Paragraph>
        </div>
        {!state.codeSent ?
            <ForgotForm onSubmit={(email) => setState({ codeSent: true, email: email })} />
            :
            <ForgotCodeForm email={state.email} />
        }
    </div>
}


const ForgotForm: React.FC<{ onSubmit: (email: string) => void }> = ({ onSubmit }) => {
    const api = useApi();
    const [form] = useForm();
    const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: "", loading: false });

    const submit = async (values: Store) => {
        setState({ ...state, loading: true, error: "" })
        const result = await api.user.restorePassword(values.email);
        if (result.error || !result.success) {
            setState({ ...state, loading: false, error: result.error || "" })
            return;
        }
        setState({ ...state, loading: false, error: null })
        onSubmit(values.email)
    }

    return <Form form={form} onFinish={submit} layout={"vertical"} style={{ maxWidth: "450px", margin: "0 auto" }}  >
        {state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

        <Form.Item className="body-form-item" label="E-mail" name="email" rules={[{ required: true, message: 'Enter valid email' }, { type: 'email' }]}>
            <Input placeholder={"Enter your email"} type="email" autoComplete="e-mail" />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
            <Button className="bc-btn" size={"large"} type="primary" htmlType="submit" loading={state.loading}>Submit</Button>
        </Form.Item>
    </Form>
}


const ForgotCodeForm: React.FC<{ email: string | null }> = ({ email }) => {
    const api = useApi();
    const [form] = useForm();
    const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: "", loading: false });
    const navigate = useNavigate();
    const user = useCurrentUser()
    const hub = useVSHub();

    const submit = async (values: Store) => {
        setState({ ...state, loading: true, error: "" })
        values.email = email;
        try {
            const result = await api.user.restorePasswordCode(values.email, values.code);
            if (result.error || !result.success) {
                setState({ ...state, loading: false, error: result.error || "" })
                return;
            }

            if (result.accessToken && result.refreshToken) {
                await user.auth(result.accessToken, result.refreshToken);
                await hub.init();
            }
            setState({ ...state, loading: false, error: null })
            navigate("/auth/newPassword")
            return;
        } catch (e) {
            setState({ ...state, loading: false, error: null })
        }
    }

    return <Form form={form} onFinish={submit} layout="vertical" style={{ maxWidth: "450px", margin: "0 auto" }} >
        {!state.error &&
            <Alert style={{ marginBottom: 30 }} type="success" description={<>We sent you the code to email <b>{email}</b></>} />
        }

        {state.error &&
            <Alert style={{ marginBottom: 30 }} type="warning" message={state.error} />
        }

        <Form.Item className="body-form-item" label={"Code"} name="code" rules={[{ required: true }]}>
            <Input placeholder={"Enter code"} aria-autocomplete="none" />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
            <Button className="bc-btn primary-blue" size={"large"} type="primary" htmlType="submit" loading={state.loading}>Submit</Button>
        </Form.Item>
    </Form>
}

export default ForgotPassword;

