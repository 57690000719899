import { Button, Divider, notification, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AppointmentFeedbackStatus } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { TemplateFieldType } from "api/model/Template/TemplateField";
import { UserRoleEnum } from "api/model/User/UserRole";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AdminFeedbackNoteDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackNoteDto";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useVSHub from "misc/hooks/useVSHub";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import FeedbackAudioPlayer from "./FeedbackAudioPlayer";
import FeedbackComments from "./FeedbackComments";


const FeedbackInformation: React.FC<{ feedbackId: number, onClose: () => void }> =
	observer(({ feedbackId, onClose }) => {

		const [currentFeedback, setCurrentFeedback] = useState<AdminFeedbackDto | null>(null);
		const [isLoading, setIsLoading] = useState(false);
		const api = useApi();
		const user = useCurrentUser();

		const hub = useVSHub();
		const currentUser = useCurrentUser();

		useEffect(() => {
			loadFeedback();
		}, [feedbackId]);

		useEffect(() => {
			if (!hub.connected) return;
			hub.on<string>(`update-feedback-${feedbackId}`, updateFeedback)
		}, [hub.connected]);

		const templateNotes = useMemo(() => currentFeedback?.notes.filter(x => x.templateFieldId != null), [currentFeedback?.notes]);
		const customNotes = useMemo(() => currentFeedback?.notes.filter(x => x.templateFieldId == null), [currentFeedback?.notes]);

		const updateFeedback = async (fb: string) => {
			setCurrentFeedback(prev => {
				var res = JSON.parse(fb) as AdminFeedbackDto;
				return { ...prev, ...res };
			});
		}

		const loadFeedback = async () => {
			setIsLoading(true);
			var res = await api.adminFeedback.get(feedbackId);
			if (res.success) {
				setCurrentFeedback(res.data)
			} else {
				notification.error({ message: res.error });
				onClose();
			}
			setIsLoading(false);
		}

		const saveEdited = async (fb: AdminFeedbackDto) => {
			setIsLoading(true);
			try {
				var res = await api.adminFeedback.markupFeedback(fb)
				setCurrentFeedback(res);
			} catch (e: any) {
				notification.error({ message: e.error });
			}
			setIsLoading(false);
			onClose();
		}

		const updateNote = (id: number, text: string) => {
			setCurrentFeedback(prev => {
				var note = prev.notes.find(n => n.id == id);
				if (!note) return;
				note.text = text;
				return { ...prev };
			})
		}

		if (isLoading) return <div><Spin size="large" /></div>;
		if (!currentFeedback) return null;

		let disabled =
			!currentFeedback.isEditing || currentFeedback.status != AppointmentFeedbackStatus.Markup ||
			(currentFeedback.isEditing && !currentUser.isInRole([UserRoleEnum.Supervisor, UserRoleEnum.SuperAdmin]) && currentUser.id != currentFeedback.editorId);

		if (currentFeedback.isEditing && (currentFeedback.status == AppointmentFeedbackStatus.Completed || currentFeedback.status == AppointmentFeedbackStatus.MarkupDone) &&
			currentUser.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor]) &&
			currentFeedback.editorId == currentUser.id
		) {
			disabled = false;
		}

		const editNoteRender = (x: AdminFeedbackNoteDto, index: number) => {
			const name = x.templateFieldId != null ? x.name : "Note #" + (index + 1)

			if (x.type == TemplateFieldType.Separator) {
				return <div key={x.id} style={{ marginBottom: 10, fontWeight: "bold" }}>{x.name}</div>
			}

			return <div key={x.id} className="feedback-item-wrapper">
				<FeedbackEditItem key={x.id}
					label={name}
					disabled={disabled}
					value={x.text}
					onChange={(t) => updateNote(x.id, t)}
				/>

				{!currentFeedback.appointment.accountId &&
					<FeedbackComments props={{
						comments: currentFeedback.comments.filter(f => f.appointmentFeedbackNoteId == x.id),
						appointmentFeedBackField: null,
						appointmentFeedbackId: currentFeedback.id,
						appointmentFeedbackNoteId: x.id
					}} />
				}

				{x.appointmentFeedbackAudio
					.map(x => <FeedbackAudioPlayer key={x.id} audio={x} feedbackId={currentFeedback.id} />)}
			</div>
		}

		const allowEditing = currentFeedback.status == AppointmentFeedbackStatus.Markup ||
			currentFeedback.status == AppointmentFeedbackStatus.MarkupDone ||
			(user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor]) && currentFeedback.status == AppointmentFeedbackStatus.Completed);


		return <div style={{ padding: 10 }}>


			{templateNotes.length > 0 ?
				<>
					<Divider orientation="left" type="horizontal">TEMPLATE NOTES</Divider>
					{templateNotes.map(editNoteRender)}
				</>
				:
				<span className="secondary-text">No custom notes</span>
			}


			{customNotes.length > 0 &&
				<>
					<Divider orientation="left" type="horizontal">CUSTOM NOTES</Divider>
					{customNotes.map(editNoteRender)}
				</>
			}

			<Divider />
			<div style={{ display: "flex", position: "sticky", bottom: 0, justifyContent: "space-between", background: "#fff", gap: 20, padding: 10 }}>
				<div>
					{allowEditing
						? <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
							{currentFeedback.isEditing && <>Now is editing by <b>{currentFeedback.editorName}</b></>}
						</div>
						: <div style={{ display: "flex", justifyContent: "space-between" }}>
							This feedback already completed.
						</div>

					}
				</div>
				<div style={{ display: "flex", gap: 20 }}>
					<Button size="large" type="default" onClick={onClose}>Cancel</Button>
					<Button size="large" type="primary" disabled={disabled} onClick={() => saveEdited(currentFeedback)}>Save</Button>
				</div>

			</div>

		</div>
	})


export default FeedbackInformation;

const FeedbackEditItem: React.FC<{ label: string, value: string, onChange: (t: string) => void, disabled?: boolean }> =
	observer(({ label, value, onChange, disabled }) => {
		return <div style={{ marginBottom: 5, marginTop: 5 }}>
			<div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>{label}</div>

			<TextArea
				disabled={disabled == true}
				rows={3}
				autoSize={true}
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>

		</div>
	})

