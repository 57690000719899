import ApiClient from "api/base/ApiClient";
import { PagingResponse } from "api/base/Paging";
import { autorun, makeAutoObservable, runInAction, toJS } from "mobx";
import { debounce } from "lodash";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AdminAppointmentFeedbackListRequest } from "api/routes/Appointments/Feedback/Model/AdminAppointmentFeedbackListRequest";
import { AppointmentFeedbackStatus } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { NotificationInstance } from "antd/es/notification/interface";
import { AppointmentFeedbackReviewTier, AppointmentFeedbackReviewTierList } from "api/model/Appointments/enums/AppointmentFeedbackReviewTier";
import { AdminFeedbackUpdateDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateDto";
import { AdminFeedbackUpdateChatDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateChatDto";
import CurrentUser from "model/CurrentUser";
import moment from "moment";

export class FeedbackListStore {
	constructor(private api: ApiClient, private user: CurrentUser) {
		makeAutoObservable(this);
		this.load(true);
	}

	loading: boolean = false;
	error: string | null = null;

	filter: AdminAppointmentFeedbackListRequest = {
		search: null,
		officeId: null,
		doctorId: null,
		clientId: null,
		patientId: null,
		status: AppointmentFeedbackStatus.All,
		reviewTier: AppointmentFeedbackReviewTier.All,
		dateFrom: null,
		dateTo: null,
		page: 1,
		pageSize: 25,
		sortField: "SubmittedDate",
		sortDirection: "desc",
		editorId: null,
		addedToPims: null,
		practiceId: null,
		accountId: null,
	}

	pagedData: PagingResponse<AdminFeedbackDto> | null = null;
	notificationApi?: NotificationInstance;

	setState = (p: Partial<FeedbackListStore>) => {
		Object.assign(this, p);
	};

	load = async (initial?: boolean) => {
		this.setState({ error: null, loading: true });
		if (initial) {
			this.setState({
				filter: {
					...this.filter,
					reviewTier: this.user.isOnlyScribe() ?
						AppointmentFeedbackReviewTier.Scribe :
						AppointmentFeedbackReviewTier.All,
				}
			})
		}

		try {
			const r = await this.api.adminFeedback.list(this.filter);
			this.setState({
				pagedData: r,
				filter: {
					...this.filter, sortField: r.sortField, sortDirection: r.sortDirection, page: r.page
				}
			});
		} catch (e: any) {
			this.setState({ error: e.message });
		}
		this.setState({ loading: false });
	};

	isFeedbackImportant = (feedback: AdminFeedbackDto) => {
		if (!feedback.submittedDate) return false;
		var deadline = moment(feedback.submittedDate).add(18, "hour");
		var current = moment();
		return current.isAfter(deadline);
	}


	setFilter = (p: Partial<AdminAppointmentFeedbackListRequest>) => {
		if (this.filter.status != p.status &&
			p.status == AppointmentFeedbackStatus.MarkupDone &&
			this.filter.addedToPims != false) {
			p.addedToPims = false;
		}
		this.filter.page = 1;
		Object.assign(this.filter, p);
		this.load();
	};

	toggleAddedToPims = () => {
		if (this.filter.addedToPims == null) this.setFilter({ addedToPims: true })
		else if (this.filter.addedToPims == true) this.setFilter({ addedToPims: false })
		else if (this.filter.addedToPims == false) this.setFilter({ addedToPims: null })
	}

	setSortingPaging = (sortField?: string, sortOrder?: "ascend" | "descend", page?: number, pageSize?: number) => {
		var sortDirection: "asc" | "desc" | undefined = !sortOrder ? undefined : sortOrder == "ascend" ? "asc" : "desc";
		var shouldResetPage = this.filter.sortDirection != sortDirection || this.filter.sortField != sortField;

		this.setFilter({
			sortField: sortField,
			sortDirection: sortDirection,
			page: shouldResetPage ? 1 : page,
			pageSize: pageSize
		})
	}


	setSearch = debounce((search: string | null) => {
		this.setFilter({ search: search })
	}, 350);

	clearSearch = () => {
		this.setFilter({ search: null })
	}

	updateFeedback = async (data: AdminFeedbackUpdateDto) => {
		var found = this.pagedData?.data.find(x => x.id == data.id);
		if (found) {
			runInAction(() => {
				Object.assign(found, data)
				this.pagedData.data = toJS(this.pagedData.data);;
			})
		}
	}

	updateFeedbackChat = async (data: AdminFeedbackUpdateChatDto) => {
		var found = this.pagedData?.data.find(x => x.id == data.id);
		if (found) {
			runInAction(() => {
				Object.assign(found, data)
				this.pagedData.data = toJS(this.pagedData.data);;
			})
		}
	}


}
