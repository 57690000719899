import { Alert, Input, Button, Form, Divider, notification, Row, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { UserRoleEnum } from "api/model/User/UserRole";
import AccountSelect from "app/components/AccountSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";


const AddPractice: React.FC<{ onSuccess: () => void, onCancel: () => void }> = ({ onSuccess, onCancel }) => {
	const api = useApi();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });
	const user = useCurrentUser();
	const isSuperAdmin = user.isInRole([UserRoleEnum.SuperAdmin]);

	const submit = async (values: { syncVetPracticeId: string, name: string, noSync: boolean, idAccount?: number }) => {
		setState({ ...state, loading: true, error: "" })

		try {
			const res = await api.adminPractice.add(values.syncVetPracticeId, values.name, values.noSync);
			if (res.success == true) {
				notification.success({ message: "Created" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occurred" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={{}}  >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"Name"} name="name">
			<Input type="text" placeholder="Enter hospital name" />
		</Form.Item>

		{isSuperAdmin &&
			<>
				<Form.Item label={"Account"} name="accountId">
					<AccountSelect onChange={(x: number) => form.setFieldsValue({ idAccount: x })} />
				</Form.Item>

				<Form.Item label={"Hospital Sync Vet ID"} name="syncVetPracticeId">
					<Input type="text" />
				</Form.Item>

				<Form.Item label={"No Sync with SyncVet"} name="noSync">
					<Switch />
				</Form.Item>

				<Divider />
			</>
		}

		<Row justify="space-between">
			<Button onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Create"}</Button>
		</Row>
	</Form>
}

export default AddPractice;
