import ApiClient from 'api/base/ApiClient';
import { makeAutoObservable, runInAction } from 'mobx';
import { FeedbackMessageClientDto } from "api/routes/Appointments/Feedback/DTO/FeedbackMessageClientDto";


export default class MessageListStore {

	apiClient: ApiClient;

	constructor(apiClient: any, feedbackId: number) {
		this.apiClient = apiClient;
		this.feedbackId = feedbackId;
		makeAutoObservable(this);
	}

	messages: FeedbackMessageClientDto[] = [];
	feedbackId: number = 0;



	async loadMessages() {
		var m = await this.apiClient.adminFeedback.getMessages(this.feedbackId);
		runInAction(() => {
			this.messages = m;
		});
	}

	async sendMessage(text: string) {
		await this.apiClient.adminFeedback.sendMessage(text, this.feedbackId);
	}

	async addMessageToList(m: FeedbackMessageClientDto) {
		runInAction(() => {
			this.messages.push(m);
		});
	}

	async removeMessage(mId: number) {
		var m = await this.apiClient.adminFeedback.removeMessage(mId);
	}

	async removeMessageFromList(mId: number) {
		runInAction(() => {
			this.messages = this.messages.filter(m => m.id != mId);
		});
	}


}
