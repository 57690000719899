import ApiClient from "../../base/ApiClient";
import { BillingDto } from "./Model/BillingDto";
import { BillingFilter } from "./Model/BillingFilter";


export default class AdminBillingApi {
	constructor(private api: ApiClient) { }
	private baseUrl: string = "/admin/billing";

	async report(req: BillingFilter) {
		const request = await this.api.client.post<BillingDto[]>(`${this.baseUrl}/list`, { ...req });
		return request.data;
	}
}